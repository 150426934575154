import {meetingNotesActions, MeetingNotesActionType} from "./meetingNotesActions";
import {getType} from "typesafe-actions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	meetingNotesActions.openForm,
	meetingNotesActions.closeForm,

	meetingNotesActions.saveMeetingNotes.request,
	meetingNotesActions.saveMeetingNotes.success,
	meetingNotesActions.saveMeetingNotes.failure,
].map(getType);

const properties = (action: MeetingNotesActionType) => {
	switch (action.type) {
		case getType(meetingNotesActions.saveMeetingNotes.request):
			return action.payload;
		default:
			return null;
	}
};

export const meetingNotesEvents = analyticsService.trackReduxActions(trackedActions, properties);
