import {ThoughtRecordsSensorEventView} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const thoughtRecordActions = {
	//
	// thought record fetch actions
	//
	fetchThoughtRecord: createAsyncAction(
		"thoughtRecordActions/fetchThoughtRecord",
		"thoughtRecordActions/fetchThoughtRecordSuccess",
		"thoughtRecordActions/fetchThoughtRecordFail",
	)<{userId: number; eventViewId: string}, {thoughtRecordData: ThoughtRecordsSensorEventView}, Error>(),
};

export type ThoughtRecordActionType = ActionType<typeof thoughtRecordActions>;
