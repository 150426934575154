import {Path} from "app/Path";
import {goBack} from "connected-react-router";
import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {history} from "../../helpers/routerHistory";
import {toastActions} from "../../toaster/redux";
import * as twoFASDKHelpers from "../helpers/twoFASDKHelpers";
import * as twoFAHelpers from "../helpers/twoFAHelpers";
import {twoFAActions} from "./twoFAActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import {TwoFAInvalidOTPError, TwoFANotFoundError} from "@sense-os/goalie-js/dist/twoFA/types";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("twoFASaga", SentryTags.TwoFA);

function* loadSetupKey() {
	try {
		const token = getSessionId();
		const result = yield call(twoFASDKHelpers.fetchSetupKey, token);

		const qrCodeUrl = `data:image/png;base64,${result.qr}`;
		const setupKey = result.key;

		yield put(twoFAActions.loadSetupKey.success({setupKey, qrCodeUrl}));
	} catch (error) {
		yield put(twoFAActions.loadSetupKey.failure({error}));
		log.captureException(error);
	}
}

function* submitVerificationKey(action: ActionType<typeof twoFAActions.submitVerificationCode.request>) {
	const {verificationKey, changeDevice} = action.payload;
	try {
		let recoveryCodes = [];
		if (changeDevice) {
			recoveryCodes = yield call(twoFASDKHelpers.changeDeviceConfirm, getSessionId(), verificationKey);
		} else {
			recoveryCodes = yield call(twoFASDKHelpers.postVerificationKey, getSessionId(), verificationKey);
		}

		yield put(twoFAActions.submitVerificationCode.success({recoveryCodes}));
		yield put(twoFAActions.openSaveRecoveryCodeDialog(recoveryCodes));
	} catch (error) {
		const message = twoFAHelpers.getIncorrectCodeErrorMessage();

		if (error instanceof TwoFAInvalidOTPError) {
			yield put(twoFAActions.submitVerificationCode.failure({error: new Error(message)}));
			return;
		}

		log.captureException(error);
		yield put(twoFAActions.submitVerificationCode.failure({error}));
	}
}

function* closeSaveRecoveryCodeDialog(action: ActionType<typeof twoFAActions.closeSaveRecoveryCodeDialog>) {
	const {stay} = action.payload;
	if (!stay) {
		yield put(goBack());
		yield put(
			toastActions.addToast({
				type: "success",
				message: localization.formatMessage(strTranslation.TWO_FA.setup.success.toast),
			}),
		);
	}
}

function* closeGeneratedRecoveryCodeDialog(action: ActionType<typeof twoFAActions.closeGenerateRecoveryCodeDialog>) {
	const {success} = action.payload;
	if (success) {
		yield put(
			toastActions.addToast({
				type: "success",
				message: localization.formatMessage(strTranslation.TWO_FA.recovery_codes.success_notification),
			}),
		);
	}
}

function* submitDisable2FACode(action: ActionType<typeof twoFAActions.submitDisable2FACode.request>) {
	const {code, useRecoveryCode} = action.payload;
	try {
		// @ts-ignore
		yield call(twoFASDKHelpers.disable2FA, getSessionId(), {otp: code, type: useRecoveryCode ? "static" : "totp"});

		yield put(twoFAActions.disableTwoFactorAuth());
		yield put(twoFAActions.submitDisable2FACode.success());
		yield put(twoFAActions.closeDisable2FAFormDialog());

		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.TWO_FA.disable.success.toast),
				type: "success",
			}),
		);
	} catch (error) {
		if (error instanceof TwoFANotFoundError) {
			// Mark as success because 2FA has been disabled somehow on other instance
			yield put(twoFAActions.submitDisable2FACode.success());
			yield put(twoFAActions.closeDisable2FAFormDialog());
			return;
		}

		if (error instanceof TwoFAInvalidOTPError) {
			const message = twoFAHelpers.getIncorrectCodeErrorMessage();
			yield put(twoFAActions.submitDisable2FACode.failure({error: new Error(message)}));
			return;
		}

		log.captureException(error);
		yield put(twoFAActions.submitDisable2FACode.failure({error}));
	}
}

function* submitChange2FADeviceCode(action: ActionType<typeof twoFAActions.submitChange2FADeviceCode.request>) {
	const {code, useRecoveryCode} = action.payload;
	try {
		// @ts-ignore
		const result = yield call(twoFASDKHelpers.changeDeviceOTP, getSessionId(), {
			otp: code,
			type: useRecoveryCode ? "static" : "totp",
		});
		yield put(
			twoFAActions.loadSetupKey.success({
				setupKey: result.key,
				qrCodeUrl: `data:image/png;base64,${result.qr}`,
			}),
		);

		yield put(twoFAActions.changeTwoFactorAuthDevice());
		yield put(twoFAActions.submitChange2FADeviceCode.success());
		yield put(twoFAActions.closeChange2FADeviceDialog());

		// Set state in the history instance. The state will persist upon refreshing
		history.push(Path.APP_TWO_FA, {changeDevice: true});
	} catch (error) {
		if (error instanceof TwoFANotFoundError) {
			// Mark as success because 2FA has been disabled somehow on other instance
			yield put(twoFAActions.submitChange2FADeviceCode.success());
			yield put(twoFAActions.closeChange2FADeviceDialog());

			// Disable 2FA to make sure the user won't be able to change 2FA device anymore
			yield put(twoFAActions.submitDisable2FACode.success());

			return;
		}

		if (error instanceof TwoFAInvalidOTPError) {
			const message = twoFAHelpers.getIncorrectCodeErrorMessage();
			yield put(twoFAActions.submitChange2FADeviceCode.failure({error: new Error(message)}));
			return;
		}

		log.captureException(error);
		yield put(twoFAActions.submitChange2FADeviceCode.failure({error}));
	}
}

function* generateNewRecoveryCodes(action: ActionType<typeof twoFAActions.generateNewRecoveryCodes.request>) {
	try {
		const result = yield call(twoFASDKHelpers.generateNewRecoveryCodes, getSessionId(), action.payload.password);

		yield put(
			twoFAActions.generateNewRecoveryCodes.success({
				recoveryCodes: result,
			}),
		);
		yield put(twoFAActions.openSaveRecoveryCodeDialog([]));
	} catch (error) {
		log.captureException(error);
		yield put(twoFAActions.generateNewRecoveryCodes.failure({error}));
	}
}

function* loadStatus() {
	try {
		const status = yield call(twoFASDKHelpers.getStatus, getSessionId());
		yield put(twoFAActions.loadStatus.success(status));
	} catch (err) {
		yield put(twoFAActions.loadStatus.failure(err));
	}
}

export default function* twoFASaga() {
	yield takeEvery(getType(twoFAActions.loadStatus.request), loadStatus);
	yield takeEvery(getType(twoFAActions.loadSetupKey.request), loadSetupKey);
	yield takeEvery(getType(twoFAActions.submitVerificationCode.request), submitVerificationKey);
	yield takeEvery(getType(twoFAActions.submitDisable2FACode.request), submitDisable2FACode);
	yield takeEvery(getType(twoFAActions.submitChange2FADeviceCode.request), submitChange2FADeviceCode);
	yield takeEvery(getType(twoFAActions.closeSaveRecoveryCodeDialog), closeSaveRecoveryCodeDialog);
	yield takeEvery(getType(twoFAActions.generateNewRecoveryCodes.request), generateNewRecoveryCodes);
	yield takeEvery(getType(twoFAActions.closeGenerateRecoveryCodeDialog), closeGeneratedRecoveryCodeDialog);

	yield put(twoFAActions.loadStatus.request());
}
