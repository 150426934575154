import {LoadingState} from "constants/redux";
import {ResolvedSensorData} from "redux/tracking/TrackingTypes";
import {SensorDatum} from "redux/tracking/TrackingTypes";

export interface TrackerCategory {
	id: string;
	category: string;
	trackers: Tracker[];
}

export interface Tracker {
	id: number;
	trackerName: string;
	description?: string;
	iconName: string;
	sensorName: string;
	scheduleType: string;
	category: string;
	measures: TrackerMeasure[];
	tags: string[];
}

export interface TrackerMeasure {
	iconName: string;
	name: string;
	description: string;
}

export interface UserTracker {
	isEnabled: boolean;
	tracker: Tracker;
}

export enum RangeOption {
	HOURLY = "hourly",
	DAILY = "daily",
	WEEKLY = "weekly",
	FOUR_WEEKS = "4_weeks",
	RAW_DATA = "raw_data",
}

export interface CustomTrackerState {
	/***
	 * States for SetupTrackerDialog
	 */
	setupTracker: SetupTrackerState;
	/**
	 * Custom Trackers data mapped by userId
	 */
	userTrackerMap: UserTrackerMap;
	/***
	 * States for ManageTrackersDialog
	 */
	manageTrackers: ManageTrackersState;
	/***
	 * States for customTracker Dialog
	 */
	trackerDialog: CustomTrackerDialogState;
}

export interface UserTrackerMap {
	[userId: number]: UserTrackerState;
}

export interface ManageTrackersState {
	isManageTrackersDialogOpened: boolean;
	savingState: LoadingState;
}

export interface SetupTrackerState {
	isSetupTrackerDialogOpened: boolean;
	loadingState: LoadingState;
	savingState: LoadingState;
	trackerCategories: TrackerCategory[];
	newAddedTracker?: Tracker;
}

export interface UserTrackerState {
	isLoadingTrackers: boolean;
	loadTrackerError?: Error;
	trackers: UserTracker[];
	trackerDataMap: TrackerDataMap;
}

export type TrackerDataMap = {
	[sensorName: string]: TrackerDatum[];
};

/**
 * Simplified custom tracker sensor data.
 * Custom tracker data form is very complicated. This interface should improve
 * readability of custom tracker data.
 */
export interface TrackerDatum {
	/**
	 * Tracker sensor id
	 */
	sensorId: string;
	/**
	 * Basically same with sensorName
	 */
	trackerName: string;
	/**
	 * Date of when the registration occurs
	 */
	date: Date;
	/**
	 * Tracker measure values
	 */
	values: MeasureValuesMap;
}

/**
 * Every tracker
 */
export interface MeasureValuesMap {
	[measureSensorName: string]: MeasureValues;
}

export interface MeasureValues {
	duration?: number;
	intensity?: number;
	quantity?: number;
	boolean?: boolean;
}

/**
 * Generic form of every custom tracker sensor data.
 *
 * ```js
 * {
 * 	sensorName: "tracker_something",
 * 	value: {
 * 		measures: {
 * 			[measureName]: {
 * 				sensorData: {
 * 					value: {
 * 						[quantity | duration | intensity | boolean]: value
 * 					}
 * 				}
 * 			},
 * 			[otherMeasure]: {....}
 * 		}
 * 	}
 * }
 * ```
 */
export interface CustomTrackerSensor {
	measures: Record<string, ResolvedSensorData<MeasureValues>>;
}

export const DefaultTrackerId = {
	Activities: -1,
	Mood: -2,
	Feelings: -3,
	StepCount: -4,
};
export const DEFAULT_TRACKER_ORDER = Object.values(DefaultTrackerId);

/**
 * Custom Tracker Dialog State
 */
export interface CustomTrackerDialogState {
	// Custom Tracker fetch status
	customTrackerFetchState: LoadingState;
	// Custom Tracker fetch Data
	customTrackerData: SensorDatum<CustomTrackerSensor>;
}
