import moment from "moment";
import {
	ChartType,
	MultiLineChartConfig,
	SingleLineChartDatum,
	ChartConfigOptions,
	ChartConfigAggregationStrategy,
} from "../../customTrackerChartTypes";
import {RangeOption, MeasureValuesMap, TrackerDatum} from "../../customTrackerTypes";
import {TrackerSensorName} from "../../customTrackerSensorTypes";
import {numberToString, getDurationInMinute} from "../customTrackerHelpers";
import {
	durationAverageTextFn,
	durationAverageTooltipTextFn,
	durationTextFn,
	durationTooltipTextFn,
	intensityAverageTextFn,
	intensityAverageTooltipTextFn,
	intensityTextFn,
	intensityTooltipTextFn,
	timeTextFn,
	totalRegistrationTextFn,
	physicalSensationTooltipTextFn,
	convertPanicAttacksWithPhysicalSensationMeasuresIntoText,
	getTrackerName,
} from "../trackerLocalizations";
import localization from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";

/**
 * Returns chart configuration for average intensity and duration of a tracker.
 */
export function createIntensityDurationChartConfig(
	measureName: string,
	options: ChartConfigOptions = {aggregateStrategy: ChartConfigAggregationStrategy.AVERAGE, skipEmptyDomain: true},
): MultiLineChartConfig {
	return {
		type: ChartType.MultiLineChart,
		leftTitleFn: (rangeOption) => {
			if (rangeOption === RangeOption.RAW_DATA) {
				return intensityTextFn();
			}
			return intensityAverageTextFn();
		},
		rightTitleFn: (rangeOption) => {
			if (rangeOption === RangeOption.RAW_DATA) {
				return durationTextFn();
			}
			return durationAverageTextFn();
		},
		trackerDatumToChartDatumFn: (datum) => {
			const measureValue = datum.values[measureName];

			if (!measureValue) {
				// If measure not found, this could means that there's a typo in `measureName`.
				// OR it's a special case for `tracker_aggression` measures: `measure_physical_aggression` and `measure_verbal_aggression`
				// because `tracker_aggression` can have two measures but one of them may be optional/empty
				return null;
			}

			return {
				source: datum,
				x: datum.date.valueOf(),
				y1: measureValue.intensity,
				y2: getDurationInMinute(measureValue.duration),
			};
		},
		customAggregatorFn: (date, selectedData) => {
			const y1Data = selectedData.filter((datum) => Boolean(datum.y1));
			const y2Data = selectedData.filter((datum) => Boolean(datum.y2));

			// Return null so that the chart won't draw the data point
			if (options.skipEmptyDomain && y1Data.length === 0 && y2Data.length === 0) {
				return null;
			}

			const sum1 = y1Data.reduce((result, datum) => {
				return result + datum.y1;
			}, 0);
			const sum2 = y2Data.reduce((result, datum) => {
				return result + datum.y2;
			}, 0);
			const average = (sum: number, totalData: number) => {
				if (sum === 0) {
					return 0;
				}
				return sum / totalData;
			};
			return {
				x: date.valueOf(),
				y1:
					options.aggregateStrategy === ChartConfigAggregationStrategy.AVERAGE
						? average(sum1, y1Data.length)
						: sum1,
				y2:
					options.aggregateStrategy === ChartConfigAggregationStrategy.AVERAGE
						? average(sum2, y2Data.length)
						: sum2,
				source: selectedData,
			};
		},
		tooltipContent: (datum, rangeOption) => {
			let results: string[] = [];
			if (rangeOption === RangeOption.RAW_DATA) {
				results.push(timeTextFn(moment(datum.x).format("HH:mm")));
				datum.y1 > 0 && results.push(intensityTooltipTextFn(numberToString(datum.y1)));
				datum.y2 > 0 && results.push(durationTooltipTextFn(numberToString(datum.y2)));
			} else {
				let source: SingleLineChartDatum[] = datum.source;
				results.push(totalRegistrationTextFn(source.length));
				datum.y1 > 0 && results.push(intensityAverageTooltipTextFn(numberToString(datum.y1)));
				datum.y2 > 0 && results.push(durationAverageTooltipTextFn(numberToString(datum.y2)));
			}

			return results.join("\n");
		},
	};
}

export function createPanicAttackWithPhysicalSensationConfig(): MultiLineChartConfig {
	/**
	 * Flatten measures array and SUM each measures
	 *
	 * ```json
	 * {
	 * 	"measureSweating": 2,
	 * 	"measurePalpitations": 1,
	 *  ...
	 * }
	 * ```
	 */
	const aggregatePhysicalSensations = (measures: MeasureValuesMap[]) => {
		return measures.reduce((aggMap, measure) => {
			Object.entries(measure).forEach(([key, value]) => {
				if (value.boolean === true) {
					aggMap[key] = (aggMap[key] || 0) + 1;
				}
			});
			return aggMap;
		}, {} as Record<string, number>);
	};

	return {
		...createIntensityDurationChartConfig("measurePanicAttacks"),
		tooltipContent: (datum, rangeOption) => {
			let results: string[] = [];
			if (rangeOption === RangeOption.RAW_DATA) {
				results.push(timeTextFn(moment(datum.x).format("HH:mm")));
				datum.y1 > 0 && results.push(intensityTooltipTextFn(numberToString(datum.y1)));
				datum.y2 > 0 && results.push(durationTooltipTextFn(numberToString(datum.y2)));
				const measures: MeasureValuesMap = datum.source.values;
				const measureNames = convertPanicAttacksWithPhysicalSensationMeasuresIntoText(measures);
				results.push(physicalSensationTooltipTextFn(measureNames));
			} else {
				let source: SingleLineChartDatum[] = datum.source;
				results.push(totalRegistrationTextFn(source.length));
				datum.y1 > 0 && results.push(intensityAverageTooltipTextFn(numberToString(datum.y1)));
				datum.y2 > 0 && results.push(durationAverageTooltipTextFn(numberToString(datum.y2)));

				//
				// Physical sensations text
				//

				// Get all measures from datapoint
				const physicalSensationMeasures = source.map((datumSource) => {
					const trackerDatum = datumSource.source as TrackerDatum;
					return trackerDatum.values;
				});
				// Aggregates measures into one flat object
				const aggregatedPhysicalSensations = aggregatePhysicalSensations(physicalSensationMeasures);

				// Generate texts
				results.push("\n" + physicalSensationTooltipTextFn(""));
				Object.entries(aggregatedPhysicalSensations)
					.sort((a, b) => b[1] - a[1])
					.forEach(([key, value]) => {
						results.push(getTrackerName(TrackerSensorName[key]) + ": " + value);
					});
			}

			return results.join("\n");
		},
	};
}

/**
 * Tracker aggression's physical aggression measure
 */
export function createMeasurePhysicalAggressionConfig(): MultiLineChartConfig {
	return {
		...createIntensityDurationChartConfig("measurePhysicalAggression"),
		leftTitleFn: (rangeOption) => {
			if (rangeOption === RangeOption.RAW_DATA) {
				return localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.physical_aggression.intensity);
			}
			return localization.formatMessage(
				strTranslation.CUSTOM_TRACKER.chart.physical_aggression.average_intensity,
			);
		},
		rightTitleFn: (rangeOption) => {
			if (rangeOption === RangeOption.RAW_DATA) {
				return localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.physical_aggression.duration);
			}
			return localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.physical_aggression.average_duration);
		},
	};
}
/**
 * Tracker aggression's verbal aggression measure
 */
export function createMeasureVerbalAggressionConfig(): MultiLineChartConfig {
	return {
		...createIntensityDurationChartConfig("measureVerbalAggression"),
		leftTitleFn: (rangeOption) => {
			if (rangeOption === RangeOption.RAW_DATA) {
				return localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.intensity);
			}
			return localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.average_intensity);
		},
		rightTitleFn: (rangeOption) => {
			if (rangeOption === RangeOption.RAW_DATA) {
				return localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.duration);
			}
			return localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.average_duration);
		},
	};
}
