import {
	SingleLineChartConfig,
	MultiLineChartConfig,
	MultiBarChartConfig,
	BooleanChartConfig as BooleanChartConfigType,
} from "../customTrackerChartTypes";
import {TrackerSensorName} from "../customTrackerSensorTypes";
import createIntensityChartConfig from "./chartConfig/intensityChartConfig";
import createDurationChartConfig from "./chartConfig/durationChartConfig";
import createTotalRegistrationChartConfig from "./chartConfig/totalRegistrationChartConfig";
import {
	createQuantityChartConfig,
	createWeightChartConfig,
	createCocaineCannabisChartConfig,
} from "./chartConfig/quantityChartConfig";
import {
	createIntensityDurationChartConfig,
	createPanicAttackWithPhysicalSensationConfig,
	createMeasurePhysicalAggressionConfig,
	createMeasureVerbalAggressionConfig,
} from "./chartConfig/intensityDurationChartConfig";
import createResearchEmotionsChartConfig from "./chartConfig/researchEmotionsChartConfig";
import {createBooleanChartConfig} from "./chartConfig/booleanChartConfig";

/**
 * Every Tracker might have one or many measures.
 * Each measure might have one or many chart configurations.
 * see https://github.com/senseobservationsystems/web-getgoalie/pull/2147
 */
type QuantityChartConfig = [SingleLineChartConfig];
type IntensityChartConfig = [SingleLineChartConfig];
type DurationChartConfig = [SingleLineChartConfig];
type IntensityDurationChartConfig = [SingleLineChartConfig, MultiLineChartConfig];
type MultiIntensityChartConfig = [MultiBarChartConfig];
type BooleanChartConfig = [BooleanChartConfigType];
type BooleanWithDurationChartConfig = [BooleanChartConfigType, SingleLineChartConfig];

/**
 * Tracker chart configuration types.
 * Each Tracker has different measurement.
 * To add new chart configuration, see what kind of measures do the Tracker have.
 * For now, we only have these:
 * - Intensity
 * - Duration
 * - Intensity and Duration
 * - Quantity
 * - Registration count
 * - Multi Intensity (Special case for 3D Research Emotions)
 */
interface ChartConfig {
	[TrackerSensorName.researchEmotions]: MultiIntensityChartConfig;
	[TrackerSensorName.afraid]: IntensityChartConfig;
	[TrackerSensorName.aggression]: [SingleLineChartConfig, MultiLineChartConfig, MultiLineChartConfig];
	[TrackerSensorName.alcohol]: QuantityChartConfig;
	[TrackerSensorName.angry]: IntensityChartConfig;
	[TrackerSensorName.ashamed]: IntensityChartConfig;
	[TrackerSensorName.askForHelp]: BooleanChartConfig;
	[TrackerSensorName.assertiveness]: BooleanChartConfig;
	[TrackerSensorName.automaticPilot]: IntensityDurationChartConfig;
	[TrackerSensorName.avoidance]: BooleanWithDurationChartConfig;
	[TrackerSensorName.backache]: IntensityDurationChartConfig;
	[TrackerSensorName.binge]: [SingleLineChartConfig, SingleLineChartConfig];
	[TrackerSensorName.bodycheck]: DurationChartConfig;
	[TrackerSensorName.cannabis]: QuantityChartConfig;
	[TrackerSensorName.cocaine]: QuantityChartConfig;
	[TrackerSensorName.compulsion]: IntensityDurationChartConfig;
	[TrackerSensorName.cramped]: IntensityDurationChartConfig;
	[TrackerSensorName.craving]: IntensityDurationChartConfig;
	[TrackerSensorName.curious]: IntensityChartConfig;
	[TrackerSensorName.depersonalization]: IntensityDurationChartConfig;
	[TrackerSensorName.depressed]: IntensityDurationChartConfig;
	[TrackerSensorName.difficulty]: IntensityChartConfig;
	[TrackerSensorName.disoriented]: IntensityDurationChartConfig;
	[TrackerSensorName.distracted]: IntensityDurationChartConfig;
	[TrackerSensorName.energy]: IntensityChartConfig;
	[TrackerSensorName.excited]: IntensityChartConfig;
	[TrackerSensorName.exercise]: DurationChartConfig;
	[TrackerSensorName.flashbacks]: BooleanWithDurationChartConfig;
	[TrackerSensorName.fatigue]: IntensityChartConfig;
	[TrackerSensorName.gratitude]: IntensityDurationChartConfig;
	[TrackerSensorName.guilty]: IntensityChartConfig;
	[TrackerSensorName.happy]: IntensityChartConfig;
	[TrackerSensorName.headache]: IntensityDurationChartConfig;
	[TrackerSensorName.hyperAlert]: IntensityDurationChartConfig;
	[TrackerSensorName.hyperAroused]: IntensityDurationChartConfig;
	[TrackerSensorName.hyperAssertiveness]: IntensityChartConfig;
	[TrackerSensorName.inDanger]: IntensityDurationChartConfig;
	[TrackerSensorName.insecure]: IntensityChartConfig;
	[TrackerSensorName.loneliness]: IntensityDurationChartConfig;
	[TrackerSensorName.memoryLoss]: BooleanWithDurationChartConfig;
	[TrackerSensorName.mindfulness]: DurationChartConfig;
	[TrackerSensorName.nightmares]: BooleanWithDurationChartConfig;
	[TrackerSensorName.panicAttack]: IntensityDurationChartConfig;
	[TrackerSensorName.panicAttackWithPhysicalSensation]: IntensityDurationChartConfig;
	[TrackerSensorName.pain]: IntensityDurationChartConfig;
	[TrackerSensorName.physicalWeakness]: IntensityDurationChartConfig;
	[TrackerSensorName.physicallyFrozen]: IntensityDurationChartConfig;
	[TrackerSensorName.positiveThings]: QuantityChartConfig;
	[TrackerSensorName.restAndRelax]: DurationChartConfig;
	[TrackerSensorName.sad]: IntensityChartConfig;
	[TrackerSensorName.safetyBehaviour]: BooleanWithDurationChartConfig;
	[TrackerSensorName.sideEffectsMedication]: IntensityChartConfig;
	[TrackerSensorName.smoking]: QuantityChartConfig;
	[TrackerSensorName.socialAvoidance]: IntensityChartConfig;
	[TrackerSensorName.socialDependent]: IntensityChartConfig;
	[TrackerSensorName.subAssertiveness]: IntensityChartConfig;
	[TrackerSensorName.tension]: IntensityChartConfig;
	[TrackerSensorName.weight]: QuantityChartConfig;
	[TrackerSensorName.withdrawal]: IntensityDurationChartConfig;
	[TrackerSensorName.worry]: DurationChartConfig;
}

export const trackerChartConfigurations: ChartConfig = {
	tracker_3D_research_emotions: [createResearchEmotionsChartConfig()],
	tracker_afraid: [createIntensityChartConfig("measureAfraid")],
	tracker_aggression: [
		createTotalRegistrationChartConfig(),
		createMeasurePhysicalAggressionConfig(),
		createMeasureVerbalAggressionConfig(),
	],
	tracker_alcohol: [createQuantityChartConfig("measureUnitOfAlcohol")],
	tracker_angry: [createIntensityChartConfig("measureAngry")],
	tracker_ashamed: [createIntensityChartConfig("measureAshamed")],
	tracker_ask_for_help: [createBooleanChartConfig("measureAskForHelp")],
	tracker_assertiveness: [createBooleanChartConfig("measureAssertiveness")],
	tracker_automatic_pilot: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measureAutomaticPilot"),
	],
	tracker_avoidance: [createBooleanChartConfig("measureAvoidance"), createDurationChartConfig("measureAvoidance")],
	tracker_backache: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureBackache")],
	tracker_binge: [createQuantityChartConfig("measureBinge"), createDurationChartConfig("measureBinge")],
	tracker_bodycheck: [createDurationChartConfig("measureBodycheck")],
	tracker_cannabis: [createCocaineCannabisChartConfig("measureCannabis")],
	tracker_cocaine: [createCocaineCannabisChartConfig("measureCocaine")],
	tracker_compulsion: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureCompulsion")],
	tracker_cramped: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureCramped")],
	tracker_craving: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureCraving")],
	tracker_curious: [createIntensityChartConfig("measureCuriosity")],
	tracker_depersonalization: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measureDepersonalization"),
	],
	tracker_depressed: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureDepressed")],
	tracker_difficulty: [createIntensityChartConfig("measureDifficulty")],
	tracker_disoriented: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measureDisoriented"),
	],
	tracker_distracted: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureDistracted")],
	tracker_energy: [createIntensityChartConfig("measureEnergy")],
	tracker_excited: [createIntensityChartConfig("measureExcitement")],
	tracker_exercise: [createDurationChartConfig("measureExercise")],
	tracker_fatigue: [createIntensityChartConfig("measureFatigue")],
	tracker_flashbacks: [createBooleanChartConfig("measureFlashbacks"), createDurationChartConfig("measureFlashbacks")],
	tracker_gratitude: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureGratitude")],
	tracker_guilty: [createIntensityChartConfig("measureGuilty")],
	tracker_happy: [createIntensityChartConfig("measureHappy")],
	tracker_headache: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureHeadache")],
	tracker_hyper_alert: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measureHyperAlert"),
	],
	tracker_hyper_aroused: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measureHyperAroused"),
	],
	tracker_hyper_assertiveness: [createIntensityChartConfig("measureHyperAssertiveness")],
	tracker_in_danger: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureInDanger")],
	tracker_insecure: [createIntensityChartConfig("measureInsecure")],
	tracker_loneliness: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measureLoneliness")],
	tracker_memory_loss: [
		createBooleanChartConfig("measureMemoryLoss"),
		createDurationChartConfig("measureMemoryLoss"),
	],
	tracker_mindfulness: [createDurationChartConfig("measureMindfulness")],
	tracker_nightmares: [createBooleanChartConfig("measureNightmares"), createDurationChartConfig("measureNightmares")],
	tracker_panic_attacks: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measurePanicAttacks"),
	],
	tracker_panic_attacks_with_physical_sensation: [
		createTotalRegistrationChartConfig(),
		createPanicAttackWithPhysicalSensationConfig(),
	],
	tracker_pain: [createTotalRegistrationChartConfig(), createIntensityDurationChartConfig("measurePain")],
	tracker_physical_weakness: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measurePhysicalWeakness"),
	],
	tracker_physically_frozen: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measurePhysicallyFrozen"),
	],
	tracker_positive_things: [createQuantityChartConfig("measurePositiveThings")],
	tracker_rest_and_relax: [createDurationChartConfig("measureRelaxation")],
	tracker_sad: [createIntensityChartConfig("measureSad")],
	tracker_safety_behaviour: [
		createBooleanChartConfig("measureSafetyBehaviour"),
		createDurationChartConfig("measureSafetyBehaviour"),
	],
	tracker_side_effects_medication: [createIntensityChartConfig("measureSideEffectsMedication")],
	tracker_smoking: [createQuantityChartConfig("measureCigarettes")],
	tracker_social_avoidance: [createIntensityChartConfig("measureSocialAvoidance")],
	tracker_social_dependent: [createIntensityChartConfig("measureSocialDependent")],
	tracker_sub_assertiveness: [createIntensityChartConfig("measureSubAssertiveness")],
	tracker_tension: [createIntensityChartConfig("measureTension")],
	tracker_weight: [createWeightChartConfig("measureWeight")],
	tracker_withdrawal_symptoms: [
		createTotalRegistrationChartConfig(),
		createIntensityDurationChartConfig("measureWithdrawalSymptoms"),
	],
	tracker_worry: [createDurationChartConfig("measureWorry")],
};

/**
 * Returns true if Tracker is supported by the web portal.
 * Unsupported preset trackers are trackers which the portal don't have the configuration yet.
 */
export function isTrackerSupported(sensorName: string): boolean {
	return !!trackerChartConfigurations[sensorName];
}
