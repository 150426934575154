import {fork, put} from "redux-saga/effects";

import {whenLoggedIn} from "../../auth/sagas/helper";
import featureFlags from "../../featureFlags/FeatureFlags";
import {organizationActions} from "../../organizations/redux/organizationActions";

import createOrUpdateTreatmentSaga from "./createOrUpdateTreatmentSaga";
import endTreatmentSaga from "./endTreatmentSaga";
import loadTreatmentFormOptionsSaga from "./loadTreatmentFormOptionsSaga";
import loadTreatmentsSaga from "./loadTreatmentsSaga";

function* treatmentStatusSaga() {
	yield fork(createOrUpdateTreatmentSaga);
	yield fork(endTreatmentSaga);
	yield fork(loadTreatmentFormOptionsSaga);
	yield fork(loadTreatmentsSaga);
}

export default function* () {
	// As per implementation of treatment status, we need to have a list of organizations
	// to be shown in the "Create Treatment Form". Thus, we load organizations from the start.
	// Also, we don't wrap in feature flags as this data is needed in other places.
	yield fork(
		whenLoggedIn(function* () {
			yield put(organizationActions.loadOrganizations.request());
		}),
	);

	if (featureFlags.treatmentStatus) {
		yield fork(whenLoggedIn(treatmentStatusSaga));
	}
}
