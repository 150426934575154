import {UserRole} from "@sense-os/goalie-js";
import moment from "moment";
import {createSelector} from "reselect";
import {getAuthUser} from "../../auth/redux";
import {ContactsMap} from "../../contacts/contactTypes";

import {getContactsMap, getPatientsContacts} from "../../contacts/redux/contactSelectors";
import featureFlags from "../../featureFlags/FeatureFlags";
import {getClientPathname} from "../../sidebarNav/helpers/sidebarNavHelpers";
import {getSortedContactsByRole} from "../../sidebarNav/redux/sidebarNavSelectors";
import {ContactTabPath} from "../../ts/app/Path";
import {LoadingState} from "../../ts/constants/redux";
import {AppState} from "../../ts/redux/AppState";
import {NotificationItem, NotificationTypes} from "../../ts/redux/notificationCenter/NotificationItem";
import {getDayDiff} from "../../ts/utils/time";
import {getClientListByMergingContactsAndTreatments} from "../treatmentStatusHelpers";

import {Treatment} from "../treatmentStatusTypes";

export const getAllTreatmentTypes = (state: AppState) => state.treatmentStatus.treatmentTypesOptions;
export const getAllClientComplaints = (state: AppState) => state.treatmentStatus.clientComplaintsOptions;
export const getEndTreatmentReasons = (state: AppState) => state.treatmentStatus.endTreatmentReasons;
export const getClientStatus = (id: number) => (state: AppState) => state.treatmentStatus.clientTreatmentsMap[id];
export const getAllDetails = (state: AppState) => state.treatmentStatus.clientTreatmentsMap;
export const getIsTreatmentFormOpen = (state: AppState) => state.treatmentStatus.isTreatmentFormOpen;
export const getIsEndTreatmentFormOpen = (state: AppState) => state.treatmentStatus.isEndTreatmentFormOpen;
export const getIsClientDetailsFormOpen = (state: AppState) => state.treatmentStatus.isClientDetailsFormOpen;
export const getEditedTreatment = (state: AppState) => state.treatmentStatus.editedTreatment;
export const getIsTreatmentMutationInProgress = (state: AppState) =>
	state.treatmentStatus.mutatingTreatmentLoadingState === LoadingState.LOADING;

export const getIsLoadingOfFetchTreatment = (clientId: number) => (state: AppState) =>
	state.treatmentStatus.fetchingTreatmentMap[clientId] === LoadingState.LOADING;

export const getIsLoadingOfFetchEndTreatmentBlocker = (state: AppState) =>
	state.treatmentStatus.fetchingEndTreatmentBlocker === LoadingState.LOADING;

export const getTimeEntriesOwner = (treatmentId: number) => (state: AppState) =>
	state.treatmentStatus.timeEntriesOwnerMap[treatmentId];

export const getIsEndTreatmentBlocked = (treatmentId: number) => (state: AppState) => {
	const owners = state.treatmentStatus.timeEntriesOwnerMap[treatmentId] || [];
	return owners.some((owner) => !owner.confirmedAt);
};

export const getFetchingAllTreatmentPersonnelState = (state: AppState) =>
	state.treatmentStatus.fetchingAllTreatmentPersonnelLoadingState;

const getAllTreatments = createSelector(getAllDetails, (treatmentMaps) => {
	const userIds = Object.keys(treatmentMaps).map((id) => parseInt(id));
	const allTreatments = userIds.map((userId) => {
		const t: Treatment = treatmentMaps[userId];
		return {...t, userId};
	});

	return allTreatments;
});

const getTreatmentIdToClientIdFn = createSelector(getAllTreatments, (treatments) => {
	return (treatmentId: number) => {
		const userTreatment = treatments.find((treatment) => treatment.id === treatmentId);
		return userTreatment?.userId;
	};
});

/**
 * Different to the usual selector, this selector will return a function
 * instead of the value directly. This one is used primarily in the
 * ClientDropdown in the TimeTracking where there's a need to translate
 * a group of treatmentIds to client names.
 */
export const getTreatmentIdToClientNameFn = createSelector(
	getSortedContactsByRole(UserRole.PATIENT),
	getTreatmentIdToClientIdFn,
	getAllDetails,
	(state: AppState) => state.contacts.contactsMap,
	(contacts, treatmentIdToClientIdFn, treatmentsMap, contactsMap) => {
		return (treatmentId: number) => {
			if (treatmentId > 0) {
				const clientId = treatmentIdToClientIdFn(treatmentId);
				return contactsMap[clientId]?.fullName || treatmentsMap[clientId]?.patientName;
			}

			return contacts.find((c) => c.id === -1 * treatmentId).fullName;
		};
	},
);

export const getAllClientsWithTreatment = createSelector(getAllDetails, (treatmentMaps) => {
	const userIds = Object.keys(treatmentMaps).map((id) => parseInt(id));
	return userIds;
});

/**
 * Per its name, selector to get all treatments that involves the authenticated user.
 */
const getAllTreatmentsOfUser = createSelector(getAllTreatments, getAuthUser, (treatments, authUser) => {
	return (
		treatments
			// Filter out treatment that auth user is not involved in
			.filter((treatment) => treatment.personnels.some((personnel) => personnel.id === authUser.id))
	);
});

/**
 * Per its name, selector to get all treatments that aren't completed yet,
 * and involves the authenticated user.
 */
const getOngoingTreatmentsOfUser = createSelector(getAllTreatmentsOfUser, (treatments) => {
	return (
		treatments
			// Filter out treatment that is already completed.
			.filter((treatment) => !treatment.endTime)
	);
});

/**
 * This selector will return all treatmentIds as a collection of string
 * that is already sorted by its client name alphabetically.
 *
 * This selector will be used for time tracking purpose,
 * and for that, it need to filter out treatment that is already completed.
 */
export const getAllTreatmentIdsForTimeTracking = createSelector(
	getOngoingTreatmentsOfUser,
	getPatientsContacts,
	(treatments, contacts) => {
		const mergedListOfClients = getClientListByMergingContactsAndTreatments(treatments, contacts);

		/**
		 * If we disable automaticTreatmentForTimeTracking flag,
		 * then this function should only return a list of clients
		 * with existing treatments.
		 */
		const relevantClients = featureFlags.automaticTreatmentForTimeTracking
			? mergedListOfClients
			: mergedListOfClients.filter((client) => !!client.treatment);

		/**
		 * This creation of clients array below is necessary,
		 * as we will do a sorting in place.
		 */
		const clients = relevantClients.map((client) => ({
			id: client.treatment?.id || client.userId * -1,
			name: client.name?.toLowerCase(),
		}));
		clients.sort((firstTreatment, secondTreatment) => {
			const firstClientName = firstTreatment.name;
			const secondClientName = secondTreatment.name;

			if (firstClientName < secondClientName) return -1;
			if (firstClientName > secondClientName) return 1;
			return 0;
		});

		return clients.map((client) => client.id.toString());
	},
);

const getAllTreatmentsWithDueReminder = createSelector(getAllTreatments, (treatments) => {
	const now = moment();
	const treatmentsWithDueReminder = treatments.filter((t) => {
		return !!t.disconnectedAt && getDayDiff(now, moment(t.disconnectedAt)) >= 0;
	});

	return treatmentsWithDueReminder;
});

export const getDisconnectNotificationItems = createSelector<
	AppState,
	ContactsMap,
	Array<Treatment & {userId: number}>,
	NotificationItem[]
>(getContactsMap, getAllTreatmentsWithDueReminder, (contacts, treatments) => {
	return (
		treatments
			// filter out treatments from client that is not connected
			// or client that is connected after the treatment is already completed
			.filter((t) => !!contacts[t.userId] && contacts[t.userId].connectedSince <= t.endTime)
			.map((t) => {
				const contact = contacts[t.userId];
				const {fullName: name, firstName, lastName} = contact;
				const linkPath = getClientPathname(contact.hashId, ContactTabPath.PROFILE);

				return {
					id: `disconnect_reminder_${t.userId}`,
					isRead: false, // disconnect notification can never be read
					name,
					fullName: {firstName, lastName},
					notificationType: NotificationTypes.DisconnectReminder,
					timestamp: t.disconnectedAt.getTime() / 1000, // notification item's timestamp is in seconds
					text: "", // notification text is not used at all anywhere
					userPicUrl: contact.image,
					linkPath,
				};
			})
	);
});
