import {LoginResponse} from "@sense-os/goalie-js";
import {call, delay, fork, put, select, takeEvery, takeLeading} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {authActions, getLoginTwoFACodeAttemptCount, getLoginTwoFARecoveryCodeAttemptCount} from "../redux";
import {authSDK, shouldReportToSentry} from "../helpers";
import {StorageKeys} from "services/system/storage/StorageKeys";
import Storage from "services/system/storage/Storage";
import {AuthUser, AuthUserRole} from "../authTypes";
import {UserProfileAction} from "../../userProfile/redux/UserProfileAction";
import localization from "../../localization/Localization";
import {getAuthUserData} from "../helpers/getAuthUserData";
import {bootstrapActions} from "../../bootstrap/redux";
import {isTempOrganizationMember} from "../../organizations/helpers/organizationHelper";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {history} from "../../helpers/routerHistory";
import {blockLogin, isLoginBlocked, unblockLogin} from "../helpers/authStorage";
import {twoFAActions} from "../../twoFactorAuthentication/redux/twoFAActions";
import * as twoFASDKHelpers from "../../twoFactorAuthentication/helpers/twoFASDKHelpers";
import * as twoFAHelpers from "../../twoFactorAuthentication/helpers/twoFAHelpers";
import Cookies from "js-cookie";
import {
	AccountBlockedError,
	TwoFAInvalidOTPError,
	TwoFANotFoundError,
	TwoFASessionExpired,
} from "@sense-os/goalie-js/dist/twoFA/types";
import strTranslation from "../../assets/lang/strings";
import {toastActions} from "../../toaster/redux";

const log = createLogger("loginSaga", SentryTags.Authentication);

function* handleLogin(action: ActionType<typeof authActions.login.request>) {
	const {email, password} = action.payload;
	// trim email to remove space in the email before POST to backend
	const emailTrimmed = email.trim();
	try {
		const loginResponse = yield call(authSDK.loginWithRedirect, emailTrimmed, password);
		// This is when user has 2FA enabled.
		if (loginResponse.type === "redirect") {
			yield put(twoFAActions.loadStatus.request());
			yield put(authActions.resetLoginLoadingState());
			history.push("/auth/login/otp");
		} else {
			yield call(initUserData, loginResponse as LoginResponse, LoginOrigin.LOGIN_PAGE);
		}
	} catch (error) {
		if (shouldReportToSentry(error)) {
			log.captureException(error);
		}

		yield put(authActions.login.failure({error}));
	}
}

enum LoginOrigin {
	LOGIN_PAGE = "loginPage",
	CODE_2FA = "code2FA",
	RECOVERY_CODE_2FA = "recoveryCode2FA",
}

// TODO : need to fix after API return external_id
function* initUserData(loginResponse: LoginResponse, loginOrigin: LoginOrigin) {
	const authUser: AuthUser = yield call(getAuthUserData, loginResponse.token, loginResponse.user.id);

	yield fork(Storage.write, StorageKeys.SESSION_ID, loginResponse.token);
	yield fork(Storage.write, StorageKeys.EMAIL, loginResponse.user.email);
	yield fork(Storage.write, StorageKeys.HASH_ID, loginResponse.user.hashId);
	yield fork(Storage.write, StorageKeys.DATE_JOINED, new Date(loginResponse.user.dateJoined).valueOf().toString());
	yield fork(Storage.write, StorageKeys.USER_ID, loginResponse.user.id.toString());
	yield fork(Storage.write, StorageKeys.EXTERNAL_ID, authUser.externalId);
	yield fork(Storage.write, StorageKeys.USER_ROLE, loginResponse.user.role);
	yield fork(Storage.write, StorageKeys.TOKEN_EXPIRY, new Date(loginResponse.expiry).toISOString());
	yield fork(Storage.write, StorageKeys.LAST_LOGIN, new Date().toISOString());

	if (authUser.role === AuthUserRole.CLIENT) {
		yield put(bootstrapActions.waitForSortedContacts());
	}

	// Check is logged in user member of temporary corona scale-up organization
	// TODO: Remove this when the migration is done.
	yield call(isTempOrganizationMember, loginResponse.token, loginResponse.user.id);

	if (loginOrigin === LoginOrigin.LOGIN_PAGE) {
		yield put(authActions.login.success({user: authUser}));
	} else if (loginOrigin === LoginOrigin.CODE_2FA) {
		yield put(authActions.login2FACode.success({user: authUser}));
	} else if (loginOrigin === LoginOrigin.RECOVERY_CODE_2FA) {
		yield put(authActions.login2FARecoveryCode.success({user: authUser}));
	}
	yield put(
		toastActions.addToast({
			message: strTranslation.AUTH.login.success_message,
			type: "success",
		}),
	);
	yield put(UserProfileAction.saveUserLanguagePreference.request({language: localization.getLocale()}));
}

const MAX_ATTEMPT_COUNT = 3;
function* handleSubmitTwoFACodeForLogin(action: ActionType<typeof authActions.login2FACode.request>) {
	const {code} = action.payload;

	try {
		const csrfToken = Cookies.get("csrftoken");

		if (!csrfToken) {
			throw new TwoFASessionExpired();
		}

		const loginResponse: LoginResponse = yield call(twoFASDKHelpers.sendOTPForLogin, csrfToken, code, "totp");

		yield call(initUserData, loginResponse, LoginOrigin.CODE_2FA);
	} catch (error) {
		if (error instanceof TwoFAInvalidOTPError) {
			const attemptCount = yield select(getLoginTwoFACodeAttemptCount);
			const attemptCount2 = yield select(getLoginTwoFARecoveryCodeAttemptCount);

			if (attemptCount + attemptCount2 < MAX_ATTEMPT_COUNT) {
				const attemptLeft = MAX_ATTEMPT_COUNT - attemptCount - attemptCount2;
				const message = twoFAHelpers.getInvalidAttemptErrorMessage(attemptLeft);

				yield put(
					authActions.login2FACode.failure({
						error: new Error(message),
					}),
				);
			} else {
				yield put(twoFAActions.loginIsBlockedForFailing2FA());
				// Set data in localstorage to make sure portal still blocked upon refreshing
				yield call(blockLogin, "2FA_login");
				// Show blocked page
				yield put(authActions.blockLogin());
			}
			return;
		}

		if (error instanceof AccountBlockedError) {
			yield put(twoFAActions.loginIsBlockedForFailing2FA());
			// Set data in localstorage to make sure portal still blocked upon refreshing
			yield call(blockLogin, "2FA_login");
			// Show blocked page
			yield put(authActions.blockLogin());

			yield put(authActions.login2FACode.failure({error: null}));
			return;
		}

		if (error instanceof TwoFASessionExpired) {
			// Redirect user to login page
			history.push("/auth/login");

			yield put(authActions.login2FACode.failure({error: null}));
			return;
		}

		log.captureException(error);
		yield put(authActions.login2FACode.failure({error}));
	}
}

function* handleSubmitTwoFARecoveryCodeForLogin(action: ActionType<typeof authActions.login2FARecoveryCode.request>) {
	const {code} = action.payload;

	try {
		const csrfToken = Cookies.get("csrftoken");

		if (!csrfToken) {
			throw new TwoFASessionExpired();
		}

		const loginResponse: LoginResponse = yield call(twoFASDKHelpers.sendOTPForLogin, csrfToken, code, "static");

		yield call(initUserData, loginResponse, LoginOrigin.RECOVERY_CODE_2FA);
		// Inform user about the remaining recovery codes left
		yield put(twoFAActions.openUsedRecoveryCodeDialog());
	} catch (error) {
		if (error instanceof TwoFAInvalidOTPError) {
			const attemptCount = yield select(getLoginTwoFACodeAttemptCount);
			const attemptCount2 = yield select(getLoginTwoFARecoveryCodeAttemptCount);

			if (attemptCount + attemptCount2 < MAX_ATTEMPT_COUNT) {
				const attemptLeft = MAX_ATTEMPT_COUNT - attemptCount - attemptCount2;
				const message = twoFAHelpers.getInvalidAttemptErrorMessage(attemptLeft);

				yield put(
					authActions.login2FARecoveryCode.failure({
						error: new Error(message),
					}),
				);
			} else {
				yield put(twoFAActions.loginIsBlockedForFailing2FA());
				// Set data in localstorage to make sure portal still blocked upon refreshing
				yield call(blockLogin, "2FA_login");
				// Show blocked page
				yield put(authActions.blockLogin());
			}
			return;
		}

		if (error instanceof AccountBlockedError) {
			yield put(twoFAActions.loginIsBlockedForFailing2FA());
			// Set data in localstorage to make sure portal still blocked upon refreshing
			yield call(blockLogin, "2FA_login");
			// Show blocked page
			yield put(authActions.blockLogin());

			yield put(authActions.login2FARecoveryCode.failure({error: null}));
			return;
		}

		if (error instanceof TwoFASessionExpired || error instanceof TwoFANotFoundError) {
			// Redirect user to login page
			history.push("/auth/login");

			yield put(authActions.login2FARecoveryCode.failure({error: null}));
			return;
		}
		log.captureException(error);
		yield put(authActions.login2FARecoveryCode.failure({error}));
	}
}

/**
 * Runs a timer to unblock user from logging in after multiple invalid attempts
 * on filling in 2FA code.
 */
function* unblockLoginTimer() {
	if (!isLoginBlocked()) {
		// Set data in localstorage to make sure portal is not blocking the user from logging in anymore
		yield call(unblockLogin);
		return;
	}

	while (true) {
		// Check if user is still blocked from logging in every 5 seconds
		yield delay(5000);
		if (!isLoginBlocked()) {
			// Set data in localstorage to make sure portal is not blocking the user from logging in anymore
			yield call(unblockLogin);
			// Redirect to login
			history.push("/auth/login");
			// Change redux state
			yield put(authActions.unblockLogin());

			break;
		}
	}
}

export default function* () {
	// Run a timer to unblock user from logging in
	yield fork(unblockLoginTimer);

	yield takeEvery(getType(authActions.login.request), handleLogin);
	yield takeEvery(getType(authActions.login2FACode.request), handleSubmitTwoFACodeForLogin);
	yield takeEvery(getType(authActions.login2FARecoveryCode.request), handleSubmitTwoFARecoveryCodeForLogin);
	yield takeLeading(
		[
			getType(authActions.blockLogin),
			getType(authActions.login2FACode.success),
			getType(authActions.resetPassword2FACode.success),
			getType(authActions.login2FARecoveryCode.success),
			getType(authActions.resetPassword2FARecoveryCode.success),
		],
		unblockLoginTimer,
	);
}
