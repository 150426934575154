import moment from "moment";
import {
	ChartConfigOptions,
	ChartType,
	BooleanChartConfig,
	MultiLineChartDatum,
	ChartConfigAggregationStrategy,
} from "../../customTrackerChartTypes";
import {RangeOption} from "../../customTrackerTypes";
import {
	timeTextFn,
	booleanTrueLabelTextFn,
	booleanFalseLabelTextFn,
	booleanTrueTooltipTextFn,
	booleanFalseTooltipTextFn,
} from "../trackerLocalizations";
import {totalRegistrationTextFn} from "../trackerLocalizations";

export function createBooleanChartConfig(
	measureName: string,
	options: ChartConfigOptions = {aggregateStrategy: ChartConfigAggregationStrategy.SUM, skipEmptyDomain: false},
): BooleanChartConfig {
	return {
		type: ChartType.BooleanChart,
		trueLabelFn: (data) => {
			const count = data.reduce((total, {y1}) => total + y1, 0);
			return booleanTrueLabelTextFn(count);
		},
		trueColor: "#2476b6",
		falseLabelFn: (data) => {
			const count = data.reduce((total, {y2}) => total + y2, 0);
			return booleanFalseLabelTextFn(count);
		},
		falseColor: "#7a9c9f",
		trackerDatumToChartDatumFn: (datum) => {
			return {
				source: datum,
				x: datum.date.valueOf(),
				y1: datum.values[measureName].boolean === true ? 1 : null,
				y2: datum.values[measureName].boolean === false ? 1 : null,
			};
		},
		customAggregatorFn: (date, selectedData) => {
			const y1Data = selectedData.filter((datum) => Boolean(datum.y1));
			const y2Data = selectedData.filter((datum) => Boolean(datum.y2));

			// Return null so that the chart won't draw the data point
			if (options.skipEmptyDomain && y1Data.length === 0 && y2Data.length === 0) {
				return null;
			}

			const sum1 = y1Data.reduce((result, datum) => {
				return result + datum.y1;
			}, 0);
			const sum2 = y2Data.reduce((result, datum) => {
				return result + datum.y2;
			}, 0);
			const average = (sum: number, totalData: number) => {
				if (sum === 0) {
					return 0;
				}
				return sum / totalData;
			};
			return {
				x: date.valueOf(),
				y1:
					options.aggregateStrategy === ChartConfigAggregationStrategy.AVERAGE
						? average(sum1, y1Data.length)
						: sum1,
				y2:
					options.aggregateStrategy === ChartConfigAggregationStrategy.AVERAGE
						? average(sum2, y2Data.length)
						: sum2,
				source: selectedData,
			};
		},
		tooltipContent: (datum, rangeOption) => {
			let results: string[] = [];
			if (rangeOption === RangeOption.RAW_DATA) {
				results.push(timeTextFn(moment(datum.x).format("HH:mm")));
			} else {
				let source: MultiLineChartDatum[] = datum.source;
				results.push(totalRegistrationTextFn(source.length));
			}
			datum.y1 > 0 && results.push(booleanTrueTooltipTextFn(datum.y1));
			datum.y2 > 0 && results.push(booleanFalseTooltipTextFn(datum.y2));

			return results.join("\n");
		},
	};
}
