import {
	ClientsFeedLabel,
	HourRange,
	FIRST_PAGE_NUMBER,
	UserFeed,
	feedEventViewTypeMap,
	FeedType,
} from "../clientsFeedTypes";
import Localization, {ILocalization} from "../../localization/Localization";
import {TIME} from "constants/time";
import {createEventViewId} from "redux/tracking/TrackingHelper";
import strTranslation from "../../assets/lang/strings";

/**
 * Get first item number of clients feed page
 *
 * e.g {firstItemNumber} - 8 of 30
 *
 * @param {number} currentPage
 * @param {maxItemPerPage} maxItemPerPage
 */
export function getFirstItemNumber(currentPage: number, maxItemPerPage: number): number {
	return (currentPage - FIRST_PAGE_NUMBER) * maxItemPerPage + FIRST_PAGE_NUMBER;
}

/**
 * Get last item number of clients feed page
 *
 * e.g 1 - {lastItemNumber} of 30
 *
 * @param {number} currentPage
 * @param {number} maxItemPerPage
 * @param {number} totalItems
 */
export function getLastItemNumber(currentPage: number, maxItemPerPage: number, totalItems?: number): number {
	const lastItemNumber: number = getFirstItemNumber(currentPage, maxItemPerPage) + maxItemPerPage - FIRST_PAGE_NUMBER;
	if (totalItems && totalItems < lastItemNumber) {
		return totalItems;
	}
	return lastItemNumber;
}

/**
 * Transform `ClientFeedLabel` to human readable text and localise it
 *
 * @param {ClientFeedLabel} label
 */
export function getLabelTexts(label: ClientsFeedLabel): string {
	const loc: ILocalization = Localization;
	return {
		[ClientsFeedLabel.PAST_ACTIVITY]: loc.formatMessage(strTranslation.CLIENT_FEED.label.past_activity),
		[ClientsFeedLabel.UPCOMING_ACTIVITY]: loc.formatMessage(strTranslation.CLIENT_FEED.label.upcoming_activity),
	}[label];
}

/**
 * Return text for time range dropdown based on hours value
 */
export function getHourRangeText(hourRange: number): string {
	const loc: ILocalization = Localization;
	switch (hourRange) {
		case HourRange.ONE_DAY:
		case HourRange.TWO_DAYS:
		case HourRange.THREE_DAYS:
			return loc.formatMessage(strTranslation.CLIENT_FEED.time_range.hours, {
				hours: hourRange,
			});
		case HourRange.ONE_WEEK:
			return loc.formatMessage(strTranslation.CLIENT_FEED.time_range.weeks, {
				weeks: hourRange / TIME.HOURS_IN_DAY / TIME.DAYS_IN_WEEK,
			});
		default:
			return loc.formatMessage(strTranslation.CLIENT_FEED.time_range.months, {
				months: hourRange / TIME.HOURS_IN_DAY / TIME.DAYS_IN_MONTH_30,
			});
	}
}

/**
 * Given `UserFeed`, this function will return a portal generated `eventViewId`
 */
export function getUserFeedEventViewId(feed: UserFeed) {
	const eventType = feedEventViewTypeMap[feed.feedType];

	if (!eventType) {
		return null;
	}

	/**
	 * this function is used to set the attribute that used as an occurrenceTime
	 * @param feedType
	 * - Use `orderDate` for psycho education feed since it doesn't have `occurrenceStartTime`
	 * - Use `createdAt` for behavior experiment feed since it doesn't have `createdAt`
	 */
	const occurrenceTime = (feedType: string) => {
		switch (feedType) {
			case FeedType.BEHAVIOR_EXPERIMENT:
				return feed.createdAt;
			case FeedType.PSYCHO_EDUCATION:
				return feed.orderDate;
			default:
				return feed.occurrenceStartTime;
		}
	};

	return createEventViewId(eventType, feed.sourceId, occurrenceTime(feed.feedType));
}

/**
 * Given `UserFeed`, this function will return true IF `UserFeed` is a planned event (Recurring or sensor data)
 */
export function isPlannedEventFeed(feed: UserFeed) {
	return [
		FeedType.RECURRING_PLANNED_EVENT,
		FeedType.PLANNED_EVENT,
		FeedType.SMQ,
		FeedType.OMQ,
		FeedType.THERAPY_SESSION,
	].includes(feed.feedType as FeedType);
}
