import {ProductFruits} from "react-product-fruits";
import {useSelector} from "react-redux";

import {AppConfig} from "app/AppConfig";
import loc from "../localization/Localization";
import {isContactsLoaded} from "../contacts/redux/contactSelectors";
import {useProductFruits} from "./useProductFruits";

export const NDProductFruits = () => {
	const {isProductFruitsAccessible, userInfo} = useProductFruits();
	const contactsIsLoaded = useSelector(isContactsLoaded);

	if (isProductFruitsAccessible && contactsIsLoaded) {
		return (
			<ProductFruits
				workspaceCode={AppConfig.PRODUCTFRUITS_WORKSPACE_KEY}
				language={loc.getLocale()}
				user={userInfo}
				dontDestroy
			/>
		);
	}

	return <></>;
};
