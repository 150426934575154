import {LoadingState} from "constants/redux";
import {NumberMap} from "services/utils/Maps";
import {PsychoEducationItem, Article} from "@sense-os/goalie-js";

/** Psycho education state */
export interface PsychoEducationState {
	// Psycho education form isOpen state
	isFormOpened: boolean;
	// Psycho education saving state
	savingState: LoadingState;
	// Psycho education delete state
	deletingState: LoadingState;
	// Psycho education articles saving state
	articleSavingState: LoadingState;
	// Psycho education articles deleting state
	articleDeletingState: LoadingState;
	// Psycho education form values map
	savedFormValues: NumberMap<PsychoEducationFormValues>;
	// Psycho education event view Id
	eventViewId?: string;
	// psychoEducation fetch state
	psychoEducationFetchState?: LoadingState;
	// psychoEducation Data
	psychoEducationData?: PsychoEducationItem;
	// Article ids to be removed
	removedArticleIds?: number[];
	// user id
	userId: number;
}

/** Time units for reminder */
export enum ReminderTimeUnit {
	HOURS = "hours",
	MINUTES = "minutes",
}

/** Psycho education article type */
export interface PsychoEducationArticle extends Omit<Article, "isCompleted"> {}

/** Psycho education form values */
export interface PsychoEducationFormValues {
	// TODO Remove this field when psycho education v2 is fully implemented in production
	// Psycho education url field
	url?: string;
	// Psycho education article field array, see https://react-hook-form.com/api/usefieldarray
	articles?: PsychoEducationArticle[];
	// Psycho education title
	title: string;
	// Psycho education description
	description?: string;
	// Due date of planned psycho education
	dueDate: Date;
	// Psycho education reminder toggle
	reminderToggle: boolean;
	// Psycho education reminder time unit
	reminderTimeUnit?: ReminderTimeUnit;
	// Psycho education reminder "before" duration
	reminderDuration?: number;
}

/** Psycho education initial form values */
export const initialFormValues: PsychoEducationFormValues = {
	url: "",
	articles: [{url: ""}],
	title: "",
	description: "",
	dueDate: new Date(),
	reminderToggle: true,
	reminderTimeUnit: ReminderTimeUnit.HOURS,
	reminderDuration: 1,
};

/** Max characters for psycho education form title */
export const TITLE_MAX_LENGTH: number = 60;

export enum PsychoEducationStatus {
	COMPLETE = "completed",
	UNCOMPLETE = "uncompleted",
}
