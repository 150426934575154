import {call, put, takeEvery, select, all} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import customTrackerSDK from "../helpers/customTrackerSDK";
import {getSessionId} from "../../auth/helpers/authStorage";
import {UserTracker as SDKUserTracker} from "@sense-os/goalie-js";
import {
	transformSDKTracker,
	convertCustomTrackerSensorToTrackerData,
	getCustomTrackerVersion,
} from "../helpers/customTrackerHelpers";
import {UserTracker, TrackerDataMap} from "../customTrackerTypes";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {DISC} from "IoC/DISC";
import {getCurrentInterval} from "redux/tracking/TrackingSelector";
import {Interval, Sensors, SensorData} from "redux/tracking/TrackingTypes";
import {isTrackerSupported} from "../helpers/trackerChartConfig";

const log = createLogger("userTrackerSaga", SentryTags.Tracking);

function* handleLoadUserTrackers(action: ActionType<typeof customTrackerActions.loadUserTrackers.request>) {
	try {
		const token = yield call(getSessionId);
		const trackerVersion = yield call(getCustomTrackerVersion);
		const userTrackers: SDKUserTracker[] = yield call(
			customTrackerSDK.getUserTrackers,
			token,
			action.payload.userId,
			trackerVersion,
		);

		// Make it sort by tracker id because it will sort by userTracker id by default
		const filteredUserTrackers = userTrackers
			.sort((a, b) => a.tracker.id - b.tracker.id)
			.filter(({tracker}) => isTrackerSupported(tracker.sensorName));

		const enabledTrackers = filteredUserTrackers
			.filter((tracker) => tracker.isEnabled)
			.map(({tracker}) => transformSDKTracker(tracker));
		const disabledTrackers = filteredUserTrackers
			.filter((tracker) => !tracker.isEnabled)
			.map(({tracker}) => transformSDKTracker(tracker));
		const trackers: UserTracker[] = filteredUserTrackers.map((tracker) => {
			return {
				isEnabled: tracker.isEnabled,
				tracker: transformSDKTracker(tracker.tracker),
			};
		});
		yield put(
			customTrackerActions.loadUserTrackers.success({
				userId: action.payload.userId,
				enabledTrackers,
				disabledTrackers,
				trackers,
			}),
		);
		yield put(
			customTrackerActions.loadTrackerData.request({
				userId: action.payload.userId,
				sensorNames: enabledTrackers.map((tracker) => tracker.sensorName),
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Unable to load user trackers"});
		yield put(customTrackerActions.loadUserTrackers.failure({error: err, userId: action.payload.userId}));
	}
}

function* handleLoadTrackerData(action: ActionType<typeof customTrackerActions.loadTrackerData.request>) {
	try {
		// Cast to `Sensors[]` because `getSensorResolved` only accepts `Sensors`
		// TODO: Improve the typings
		const sensorNames = action.payload.sensorNames as Sensors[];
		const interval: Interval = yield select(getCurrentInterval);

		const results: SensorData<any>[] = yield all(
			sensorNames.map((sensorName) =>
				call(
					DISC.getTrackingService().getSensorResolved,
					[sensorName],
					interval.start.toISOString(),
					interval.end.toISOString(),
					action.payload.userId,
				),
			),
		);

		const trackerDataMap = action.payload.sensorNames.reduce((map: TrackerDataMap, sensorName, index) => {
			const sensorData = results[index] || [];
			map[sensorName] = sensorData
				.sort((a, b) => a.startTime.valueOf() - b.startTime.valueOf())
				.map(convertCustomTrackerSensorToTrackerData);
			return map;
		}, {});

		yield put(
			customTrackerActions.loadTrackerData.success({
				userId: action.payload.userId,
				trackerDataMap,
			}),
		);
	} catch (err) {
		yield put(customTrackerActions.loadTrackerData.failure({userId: action.payload.userId, error: err}));
	}
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.loadUserTrackers.request), handleLoadUserTrackers);
	yield takeEvery(getType(customTrackerActions.loadTrackerData.request), handleLoadTrackerData);
}
