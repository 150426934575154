import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {getSensorResolved} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {extractEventViewId} from "redux/tracking/TrackingHelper";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {CustomTrackerSensor} from "../customTrackerTypes";

const log = createLogger("customTrackerDialogSaga", SentryTags.CustomTracker);

export function* fetchCustomTrackerDialog(
	action: ActionType<typeof customTrackerActions.fetchCustomTrackerDialog.request>,
) {
	const {userId, eventViewId} = action.payload;
	const {id} = extractEventViewId(eventViewId);
	const token: string = yield call(getSessionId);
	try {
		const response: SensorDatum<CustomTrackerSensor>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			undefined,
			undefined,
			undefined,
			[id],
		);
		yield put(customTrackerActions.fetchCustomTrackerDialog.success({customTrackerData: response?.[0]}));
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.fetchCustomTrackerDialog.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.fetchCustomTrackerDialog.request), fetchCustomTrackerDialog);
}
