import {InterventionCustomTrackerListItem, InterventionCustomTrackerParams} from "@sense-os/goalie-js";
import {DiaryEntry, EventViewData, SensorDatum, Sensors} from "redux/tracking/TrackingTypes";
import {transformTrackerDatumToTrackerEventView} from "../../../clientActivity/helpers/customTrackerDataHelpers";
import {transformSensorDatumIntoClientActivity} from "../../../clientActivity/helpers/sensorDataActivityHelpers";
import {CustomTrackerSensor, TrackerDatum} from "../../../customTracker/customTrackerTypes";
import {
	convertCustomTrackerSensorToTrackerData,
	localiseTextFromBE,
} from "../../../customTracker/helpers/customTrackerHelpers";
import featureFlags from "../../../featureFlags/FeatureFlags";
import {TrackerDatumWithEventViewData} from "../interventionCustomTrackerType";
import interventionCustomTrackerSDK from "./interventionCustomTrackerSDK";

/** total supported custom tracker
 * since the response is mixed default trackers & custom trackers, the page size request is updated to 100 */
export const PAGE_SIZE = 100;

/** current supported custom tracker version */
const VERSION = 2;

/**
 * Get Custom Tracker Summary List
 * @param token
 * @param params
 * @returns InterventionCustomTrackerListItem[]
 */
export const getCustomTrackerSummaryLists = async (token: string, params: InterventionCustomTrackerParams = {}) => {
	const customTrackerSummaryResponse = await interventionCustomTrackerSDK.getCustomTrackerSummaryList(token, {
		...params,
		pageSize: PAGE_SIZE,
		version: VERSION,
	});

	return customTrackerSummaryResponse;
};

/**
 * Transform Custom Tracker Summary
 * @param tracker
 * @returns InterventionCustomTrackerListItem
 */
export const transformCustomTrackerSummary = (
	tracker: InterventionCustomTrackerListItem,
): InterventionCustomTrackerListItem => {
	return {
		...tracker,
		iconName: tracker.sensorName,
		trackerName: localiseTextFromBE(tracker.trackerName),
	};
};

/**
 * Sorting the custom tracker datum by the latest date
 * @param customTrackerSensors
 * @returns TrackerDatumWithEventViewData[]
 */
export const sortCustomTrackerSensorByLatestDate = (
	customTrackerSensors: TrackerDatumWithEventViewData[],
): TrackerDatumWithEventViewData[] => {
	return customTrackerSensors.sort((a, b) => {
		// sort by the latest date
		return b.date.valueOf() - a.date.valueOf();
	});
};

type MergeCustomTrackersByTrackerIdParam = {
	trackerId: number;
	isEnabled: boolean;
	customTrackers: InterventionCustomTrackerListItem[];
};

/**
 * Merge Existing Custom Trackers to enable/disable custom trackers by selected tracker id
 * @param param
 * @returns InterventionCustomTrackerListItem[]
 */
export const mergeCustomTrackersByTrackerId = (
	param: MergeCustomTrackersByTrackerIdParam,
): InterventionCustomTrackerListItem[] => {
	const {trackerId, isEnabled, customTrackers} = param;

	return customTrackers.map((customTracker) => {
		const isEnabledCustomTracker = customTracker.id === trackerId ? isEnabled : customTracker.isEnabled;
		return {
			...customTracker,
			isEnabled: isEnabledCustomTracker,
		};
	});
};

type MergeCustomTrackerAndDiaryEntryParam = {
	sensorList: SensorDatum<DiaryEntry | CustomTrackerSensor>[];
	selectedSensor: Sensors;
	userId: number;
};

/**
 * Merge Custom Tracker sensor with diary entry sensor
 * since the `description` attribute is a part of diary entry,
 * needed to merge custom tracker sensor and diary entry sensor
 * @param param
 * @returns `TrackerDatumWithEventViewData[]`
 */
export const mergeCustomTrackerAndDiaryEntry = (
	param: MergeCustomTrackerAndDiaryEntryParam,
): TrackerDatumWithEventViewData[] => {
	const {selectedSensor, sensorList, userId} = param;

	/** diary entry sensor list */
	const diaryEntryList: SensorDatum<DiaryEntry>[] = getSensorListBySensorName({
		sensorList,
		selectedSensor: Sensors.DIARY,
	});
	/** sensor list by selected sensor */
	const customTrackerList = getSensorListBySensorName({
		sensorList,
		selectedSensor,
	});

	// filtering the diary entry sensor which is related to custom trackers
	const diaryEntryCustomTrackers = diaryEntryList.filter((diaryEntry) => {
		return diaryEntry?.value?.trackers;
	});

	const mergedCustomTrackerDiaryEntry: TrackerDatumWithEventViewData[] = customTrackerList.map((customTracker) => {
		const customTrackerId: string = customTracker.id;

		const selectedDiaryEntryByCustomTracker: SensorDatum<DiaryEntry> = findDiaryEntryByCustomTrackerId({
			customTrackerId,
			diaryEntries: diaryEntryCustomTrackers,
		});

		const customTrackerDatum: TrackerDatum = convertCustomTrackerSensorToTrackerData(customTracker);
		const isDiaryEntryExist: boolean = !!selectedDiaryEntryByCustomTracker;

		const eventView: EventViewData = isDiaryEntryExist
			? transformSensorDatumIntoClientActivity(selectedDiaryEntryByCustomTracker)
			: transformTrackerDatumToTrackerEventView(customTrackerDatum, userId);

		const trackerNote: string = selectedDiaryEntryByCustomTracker?.value?.description || null;

		return {
			...customTrackerDatum,
			eventView,
			note: trackerNote,
		};
	});

	return mergedCustomTrackerDiaryEntry;
};

type GetSensorListBySensorNameParam = {
	sensorList: SensorDatum<DiaryEntry | CustomTrackerSensor>[];
	selectedSensor: Sensors;
};

/** filtering `SensorDatum<any>[]` by sensor name (enum Sensors) */
const getSensorListBySensorName = (param: GetSensorListBySensorNameParam): SensorDatum<any>[] => {
	const {sensorList, selectedSensor} = param;

	return sensorList.filter((sensorItem) => {
		return sensorItem.sensorName === selectedSensor;
	});
};

type FindDiaryEntryByCustomTrackerIdParam = {
	diaryEntries: SensorDatum<DiaryEntry>[];
	customTrackerId: string;
};

/** find diary entry who has a tracker by id */
const findDiaryEntryByCustomTrackerId = (param: FindDiaryEntryByCustomTrackerIdParam) => {
	const {diaryEntries, customTrackerId} = param;

	return diaryEntries.find((diaryEntry) => {
		return diaryEntry.value.trackers[0].sensorData.id === customTrackerId;
	});
};

/** Intervention Custom Tracker Reminder button relies on `loggerReminder` &  `customTrackerReminder` feature flag */
export const isShowReminderButton = (): boolean => {
	return featureFlags.loggerReminder && featureFlags.customTrackerReminder;
};
