import {call, put, select, all, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import customTrackerSDK from "../helpers/customTrackerSDK";
import {getSessionId} from "../../auth/helpers/authStorage";
import {UserTracker as SDKTracker} from "@sense-os/goalie-js";
import {transformSDKTracker} from "../helpers/customTrackerHelpers";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {getLoggerConfigsByScheduleType} from "../../ts/redux/loggerConfig/LoggerConfigSelector";
import {LoggerConfig} from "../../ts/redux/loggerConfig/LoggerConfigTypes";
import {DISC} from "../../ts/IoC/DISC";
import {LoggerConfigAction} from "../../ts/redux/loggerConfig/LoggerConfigAction";
import {getSelectedContactId} from "../../contacts/redux/contactSelectors";

const log = createLogger("manageTrackersSaga", SentryTags.Tracking);

function* handleSaveManageTrackers(action: ActionType<typeof customTrackerActions.saveManageTrackers.request>) {
	try {
		const token = yield call(getSessionId);
		const userId = yield select(getSelectedContactId);

		// Remove tracker reminders
		const disabledTrackerScheduleTypes = action.payload.trackers
			.filter((tracker) => !tracker.isEnabled)
			.map((tracker) => tracker.tracker.scheduleType);
		yield call(removeTrackerReminders, disabledTrackerScheduleTypes);

		const response: SDKTracker[] = yield call(
			customTrackerSDK.postUserTrackerStatus,
			token,
			userId,
			action.payload.trackers.map((tracker) => {
				return {
					trackerId: tracker.tracker.id,
					isEnabled: tracker.isEnabled,
				};
			}),
		);

		const newTrackers = response.map((tracker) => {
			return transformSDKTracker(tracker.tracker);
		});

		yield put(customTrackerActions.saveManageTrackers.success({userId: userId, trackers: newTrackers}));
		// Refetch all enabled trackers for this user
		yield put(customTrackerActions.loadUserTrackers.request({userId}));
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.saveManageTrackers.failure(err));
	}
}

export function* removeTrackerReminders(scheduleTypes: string[]) {
	yield all(scheduleTypes.map((scheduleType) => call(removeTrackerReminder, scheduleType)));
}

function* removeTrackerReminder(scheduleType: string) {
	const reminders: LoggerConfig[] = yield select(getLoggerConfigsByScheduleType(scheduleType as any));
	const requests = reminders.map((reminder) => call(removeReminder, reminder));
	yield all(requests);
}

export function* removeReminder(loggerReminder: LoggerConfig) {
	const selectedClientId = yield select(getSelectedContactId);
	const {rawRecurringExpression, id} = loggerReminder;
	yield call(DISC.getRecurringSchedule().removeRecurringSchedule, id, rawRecurringExpression);
	yield put(LoggerConfigAction.loggerConfigRemoved(selectedClientId, loggerReminder.scheduleType, id));
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.saveManageTrackers.request), handleSaveManageTrackers);
}
