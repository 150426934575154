import {CustomTrackers} from "@sense-os/goalie-js";

const customTrackerSDK = new CustomTrackers();

customTrackerSDK.getTrackerCategories = customTrackerSDK.getTrackerCategories.bind(customTrackerSDK);
customTrackerSDK.getTrackerById = customTrackerSDK.getTrackerById.bind(customTrackerSDK);
customTrackerSDK.getUserTrackers = customTrackerSDK.getUserTrackers.bind(customTrackerSDK);
customTrackerSDK.postUserTrackerStatus = customTrackerSDK.postUserTrackerStatus.bind(customTrackerSDK);

export default customTrackerSDK;
