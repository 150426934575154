import moment from "moment";
import {TimeUtils} from "constants/time";
import {UserRole as SDKUserRole} from "@sense-os/goalie-js";
import Localization from "../../localization/Localization";
import {Contact} from "../../contacts/contactTypes";
import {NumberMap} from "services/utils/Maps";
import {AuthUser, AuthUserRole} from "../../auth/authTypes";
import {LoadingState} from "constants/redux";
import {UserlistTabs} from "../sidebarNavTypes";
import {ContactTabPath, Path} from "app/Path";
import {getClientTabMenuRoute, getTherapistTabMenuRoute} from "../../helpers/routeGenerator";
import strTranslation from "../../assets/lang/strings";

/**
 * Check logged in user (except client) is able to access Freshdesk or not.
 * @param {AuthUser} authUser
 * @param {LoadingState} tooltipLoadingState
 * @param {boolean} isUserAllowed
 * @returns {boolean}
 */
export const isFreshdeskAccessible = (
	authUser: AuthUser,
	userSettingsLoadingState: LoadingState,
	isUserAllowed: boolean,
) => {
	const hasLocalUserJoinedToOrg = authUser.role !== AuthUserRole.CLIENT && authUser.organization?.isAccepted;
	const isUserSettingsLoaded = userSettingsLoadingState === LoadingState.LOADED;
	return hasLocalUserJoinedToOrg && isUserSettingsLoaded && isUserAllowed;
};

/**
 * Convert timestamp to string time.
 * @param {number} timestamp
 * @returns {string}
 */
export const toStringTime = (timestamp: number): string => {
	if (!timestamp) {
		return "";
	}

	const now: moment.Moment = moment(),
		msgTime: moment.Moment = moment(timestamp);
	const timeString: string = msgTime.format("HH:mm");

	let dateString: string = msgTime.format("DD-MM-YY");

	const dayDiff: number = TimeUtils.getDayDiff(now, msgTime);

	if (dayDiff < 1) {
		dateString = Localization.formatMessage(strTranslation.TIME.today);
	} else if (dayDiff === 1) {
		dateString = Localization.formatMessage(strTranslation.TIME.yesterday);
	}
	return dateString + ", " + timeString;
};

/**
 * Calculate unread messages of client / colleagues
 * @param {Contact} contacts
 * @param {NumberMap<number>} unreadMessages
 * @returns {number}
 */
export const calculateUnreadMessages = (contacts: Contact[], unreadMessages: NumberMap<number>): number => {
	return unreadMessages
		? contacts.map(({id}) => unreadMessages[id]).reduce((total, count) => (count ? total + count : total), 0)
		: 0;
};

/**
 * Default selected contact list tab based on user role
 */
const defaultSelectedTabMap = {
	[AuthUserRole.THERAPIST]: UserlistTabs.CLIENTS,
	[AuthUserRole.RESEARCHER]: UserlistTabs.CLIENTS,
	[AuthUserRole.CLIENT]: UserlistTabs.COLLEAGUES,
};

/**
 * Get selected tab according to auth user role.
 * @param {AuthUserRole} authRole
 * @returns {UserlistTabs}
 */
export const getDefaultSelectedTab = (authRole: AuthUserRole): UserlistTabs => {
	return defaultSelectedTabMap[authRole] || UserlistTabs.COLLEAGUES;
};

/**
 * Get contact pathname according to contact's role
 * @param {Contact} contact
 * @returns {string}
 */
export const getContactPathname = (contact: Contact, pathName: string): string => {
	const hashId: string = contact.hashId;
	return contact.role === SDKUserRole.PATIENT
		? getClientPathname(hashId, null, pathName)
		: getTherapistTabMenuRoute(hashId, ContactTabPath.PROFILE);
};

/**
 * Get pathname for client.
 * @param {string} hashId
 * @return {string}
 */
export const getClientPathname = (hashId: string, tab?: string, pathname?: string): string => {
	if (!tab) tab = getTabForClientPathname(pathname);
	return getClientTabMenuRoute(hashId, tab);
};

/**
 * When navigating to a client page without specifying specific tab, this function will
 * pick the appropriate tab to navigate into.
 *
 * If portal navigate from another client's page, select the same tab.
 * If not, pick `data` tab as default.
 */
const getTabForClientPathname = (pathname: string): string => {
	const currentPath = pathname;
	// True if pathname is `/app/client`
	const isInClientPage = currentPath.includes(Path.APP_CLIENT);
	if (isInClientPage) {
		const splitPath = currentPath.split("/");
		// remove app/client/${userHashId}
		const slicedPath = splitPath.slice(4, splitPath.length);
		// merge path string to set active tab to be the same with given pathname
		const finalPath = slicedPath.reduce((prev, curr) => {
			if (prev) return `${prev}/${curr}`;
			return curr;
		}, "");
		return finalPath;
	}

	return ContactTabPath.DATA;
};
