import moment from "moment";
import {ChartType, SingleLineChartConfig, SingleLineChartDatum} from "../../customTrackerChartTypes";
import {RangeOption} from "../../customTrackerTypes";
import {timeTextFn, totalCountTextFn, totalRegistrationTextFn} from "../trackerLocalizations";

/**
 * Returns chart configuration for counting total registrations of a tracker.
 * One sensordata counts as one datapoint.
 */
export default function createTotalRegistrationChartConfig(): SingleLineChartConfig {
	return {
		type: ChartType.SingleLineChart,
		titleFn: totalCountTextFn,
		trackerDatumToChartDatumFn: (datum) => {
			return {
				source: datum,
				x: datum.date.valueOf(),
				y: 1,
			};
		},
		customAggregatorFn: (date, selectedData) => {
			const yData = selectedData.filter((datum) => Boolean(datum.y));
			const total = yData.reduce((result, datum) => {
				return result + datum.y;
			}, 0);
			return {
				x: date.valueOf(),
				y: total,
				source: selectedData,
			};
		},
		tooltipContent: (datum, rangeOption) => {
			let results: string[] = [];

			if (rangeOption === RangeOption.RAW_DATA) {
				const timeValue = moment(datum.x).format("HH:mm");
				results.push(timeTextFn(timeValue));
			} else if (rangeOption === RangeOption.HOURLY) {
				results.push(totalRegistrationTextFn(datum.y));

				const source: SingleLineChartDatum[] = datum.source;
				source.forEach((sensor) => {
					const timeValue = moment(sensor.source.startTime).format("HH:mm");
					results.push(timeTextFn(timeValue));
				});
			} else {
				results.push(totalRegistrationTextFn(datum.y));
			}

			return results.join("\n");
		},
	};
}
