import {EventViewData} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {UpdateEventOption} from "visual/feature/clientProfile/trackingData/events/UpdateEventOptionDialog";
import {PlanActivityFormValues} from "./planActivityTypes";

export const planActivityActions = {
	/**
	 * Planned activity form dialog
	 */
	openForm: createAction(
		"planActivityActions/openForm",
		(userId: number, eventViewId?: string, isReplan: boolean = false) => ({
			userId,
			eventViewId,
			isReplan,
		}),
	)(),
	closeForm: createAction("planActivityActions/closeForm")(),

	/**
	 * Confirmation dialog
	 */
	openConfirmationDialog: createAction("planActivityActions/openConfirmationDialog", (userId: number) => ({
		userId,
	}))(),
	closeConfirmationDialog: createAction("planActivityActions/closeConfirmationDialog")(),

	/**
	 * Save form values actions
	 */
	saveFormValues: createAction(
		"planActivityActions/saveFormValues",
		(userId: number, formValues?: PlanActivityFormValues) => ({userId, formValues}),
	)(),

	/**
	 * Save planned activity actions
	 */
	saveActivity: createAsyncAction(
		"planActivityActions/savePlanActivity",
		"planActivityActions/savePlanActivitySuccess",
		"planActivityActions/savePlanActivityFail",
	)<{userId: number; formValues: PlanActivityFormValues}, {userId: number}, Error>(),

	/**
	 * Fetch planned activity actions
	 */
	fetchPlannedActivity: createAsyncAction(
		"planActivityActions/fetchPlannedActivity",
		"planActivityActions/fetchPlannedActivitySuccess",
		"planActivityActions/fetchPlannedActivityFail",
	)<{userId: number; eventViewId: string}, {userId: number; data: EventViewData}, Error>(),

	/**
	 * Delete planned activity actions
	 */
	deletePlannedActivity: createAsyncAction(
		"planActivityActions/deletePlannedActivity",
		"planActivityActions/deletePlannedActivitySuccess",
		"planActivityActions/deletePlannedActivityFail",
	)<{eventViewId: string; deleteOption?: UpdateEventOption}, void, Error>(),
};

export type PlanActivitysActionType = ActionType<typeof planActivityActions>;
