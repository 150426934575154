import {call, takeEvery, put, select, fork, delay} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {SensorData as BaseSensorData} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

import {
	ActivityTypes,
	SensorDatum,
	PlannedEventEntry,
	EventViewType,
	TherapySessionEventView,
} from "redux/tracking/TrackingTypes";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {userSettingsAction} from "../../userSettings/redux/userSettingsActions";
import {DISC} from "../../ts/IoC/DISC";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {toastActions} from "../../toaster/redux";
import {SESSION_EVENT_ID_PREFIX} from "../../calendar/calendarTypes";
import {saveSessionEventTask as upsertSessionEvent} from "../../calendar/sagas/sessionEvent/saveSessionEventSaga";

import {therapySessionActions} from "../redux/therapySessionActions";
import {
	createPlannedOmqSmq,
	updatePlannedOmqSmq,
	transformSessionFormValuesToPlannedEventSensor,
	getSavedSessionToastMessage,
} from "../helpers/therapySessionHelpers";
import {getUndoButton} from "../views/UndoActionButton";
import {TherapySessionFormValues, sessionScheduleMap} from "../types";
import {getTherapySessionData, getTherapySessionEventViewId} from "../redux/therapySessionSelectors";
import {extractEventViewId} from "redux/tracking/TrackingHelper";

const log = createLogger("saveTherapySessionSaga", SentryTags.TherapySession);
const UNDO_KEY_PREFIX = "SESSION_save_";
const UNDO_ACTION_TIMEOUT_MS = 4000;

function* saveSessionTask(action: ActionType<typeof therapySessionActions.saveTherapySession.request>) {
	const {userId, formValues} = action.payload;
	const clientId = userId || formValues.selectedClient.id;
	const isCalendarExist = !!formValues.selectedCalendar;

	if (isCalendarExist) {
		// There is a need if saving session with attached calendar event
		// user should be able to undo the action.
		const undoId = UNDO_KEY_PREFIX + clientId;

		yield put(
			toastActions.addToast({
				type: "info",
				message: loc.formatMessage(strTranslation.CALENDAR.session.saved.toast.text),
				persist: false,
				key: undoId,
				action: getUndoButton(undoId, clientId),
			}),
		);

		const saveSessionSagaTask = yield fork(saveSessionEventTask, clientId, formValues);
		sessionScheduleMap.set(undoId, saveSessionSagaTask);
	} else {
		yield call(handleSaveTherapySession, clientId, formValues);
	}
}

function* saveSessionEventTask(clientId: number, formValues: TherapySessionFormValues) {
	yield delay(UNDO_ACTION_TIMEOUT_MS);
	yield call(handleSaveTherapySession, clientId, formValues);
}

function* handleSaveTherapySession(clientId: number, formValues: TherapySessionFormValues) {
	const isOmqEnabled = formValues.omqToogle;
	const isSmqEnabled = formValues.smqToogle;

	try {
		// Get event view data
		const eventViewId: string = yield select(getTherapySessionEventViewId);
		const extractedEventView = eventViewId && extractEventViewId(eventViewId);

		// Get planned event id
		const plannedEventId: string = extractedEventView && extractedEventView.id;

		// Transformed therapy session form values
		const plannedTherapySession = yield apiCallSaga(
			transformSessionFormValuesToPlannedEventSensor,
			formValues,
			plannedEventId,
		);

		if (eventViewId) {
			const therapySessionData: TherapySessionEventView = yield select(getTherapySessionData);

			// Check if `eventViewData` is exist it's means update the therapy session
			yield call(
				saveSessionByUpdateOmqSmq,
				clientId,
				formValues,
				plannedTherapySession,
				therapySessionData,
				plannedEventId,
			);
		} else {
			yield call(saveSessionByCreateNewOmqSmq, clientId, formValues, plannedTherapySession);
		}

		// Save the OMQ SMQ configuration to user settings.
		yield put(
			userSettingsAction.saveSettings.request({
				settings: {omqSmq: {isOmqEnabled, isSmqEnabled}},
			}),
		);
		yield put(therapySessionActions.saveTherapySession.success({userId: clientId}));
		yield put(therapySessionActions.clearFetchedSession());

		// Show success toast message
		const successToastText = yield call(getSavedSessionToastMessage, formValues);
		yield put(toastActions.addToast({message: successToastText, type: "info"}));
	} catch (err) {
		log.captureException(err);
		yield put(therapySessionActions.saveTherapySession.failure(err));

		// Show error toast message
		yield put(
			toastActions.addToast({message: loc.formatMessage(strTranslation.GRAPHS.toast.save.fail), type: "error"}),
		);
	}
}

/**
 * This function will create a new planned omq and smq before create planned therapy session
 */
function* saveSessionByCreateNewOmqSmq(
	userId: number,
	formValues: TherapySessionFormValues,
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
) {
	const isOmqEnabled = formValues.omqToogle;
	const isSmqEnabled = formValues.smqToogle;
	let plannedOmqUri: string = "";
	let plannedSmqUri: string = "";

	try {
		if (isOmqEnabled) {
			const plannedOmq = yield apiCallSaga(
				createPlannedOmqSmq,
				userId,
				plannedTherapySession,
				ActivityTypes.FILL_OMQ,
			);
			if (plannedOmq && plannedOmq.uri) {
				plannedOmqUri = plannedOmq.uri;
			}
		}

		if (isSmqEnabled) {
			const plannedSmq = yield apiCallSaga(
				createPlannedOmqSmq,
				userId,
				plannedTherapySession,
				ActivityTypes.FILL_SMQ,
			);
			if (plannedSmq && plannedSmq.uri) {
				plannedSmqUri = plannedSmq.uri;
			}
		}

		yield call(
			saveTherapySessionWithOmqSmqUri,
			userId,
			formValues,
			undefined,
			plannedOmqUri,
			plannedSmqUri,
			plannedTherapySession,
		);
	} catch (err) {
		log.captureException(err);
	}
}

/**
 * This function will update planned omq and smq before updating existing planned therapy session
 */
function* saveSessionByUpdateOmqSmq(
	userId: number,
	formValues: TherapySessionFormValues,
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
	therapySessionData: TherapySessionEventView,
	planedEventId: string,
) {
	const isOmqEnabled = formValues.omqToogle;
	const isSmqEnabled = formValues.smqToogle;
	let sensor: SensorDatum<PlannedEventEntry>;

	if (therapySessionData && therapySessionData.type === EventViewType.THERAPY_SESSION_SENSOR) {
		sensor = therapySessionData.source;
	}
	const {plannedOmq, plannedSmq} = sensor.value;

	try {
		// Here we want to save or delete Planned OMQ
		const plannedOmqUri = yield apiCallSaga(
			updatePlannedOmqSmq,
			userId,
			plannedTherapySession,
			ActivityTypes.FILL_OMQ,
			isOmqEnabled,
			plannedOmq && plannedOmq.sensorData,
		);
		// Here we want to save or delete Planned SMQ
		const plannedSmqUri = yield apiCallSaga(
			updatePlannedOmqSmq,
			userId,
			plannedTherapySession,
			ActivityTypes.FILL_SMQ,
			isSmqEnabled,
			plannedSmq && plannedSmq.sensorData,
		);

		yield call(
			saveTherapySessionWithOmqSmqUri,
			userId,
			formValues,
			planedEventId,
			plannedOmqUri,
			plannedSmqUri,
			plannedTherapySession,
		);
	} catch (err) {
		log.captureException(err);
	}
}

/**
 * This function will attached `plannedOmq` and `plannedSmq` uri
 * into `plannedTherapySession` body before submit it to backend
 */
function* saveTherapySessionWithOmqSmqUri(
	userId: number,
	formValues: TherapySessionFormValues,
	plannedEventId: string,
	plannedOmqUri: string,
	plannedSmqUri: string,
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
) {
	const {title, description, startTime, endTime, selectedCalendar} = formValues;
	try {
		// Try to attach Planned OMQ Uri from backend into `plannedTherapySession`
		if (plannedOmqUri) {
			plannedTherapySession.value.plannedOmq = {
				"%uri": plannedOmqUri,
			};
		} else {
			// If the uri does not exist, delete the property from the object.
			delete plannedTherapySession.value.plannedOmq;
		}

		// Try to attach Planned SMQ Uri from backend into `plannedTherapySession`
		if (plannedSmqUri) {
			plannedTherapySession.value.plannedSmq = {
				"%uri": plannedSmqUri,
			};
		} else {
			// If the uri does not exist, delete the property from the object.
			delete plannedTherapySession.value.plannedSmq;
		}

		const sensorDataResponse = yield apiCallSaga(
			DISC.getTrackingService().saveSensorData,
			plannedTherapySession,
			userId,
			plannedEventId,
		);

		// Create / save calendar session event if `selectedCalendar` is exist
		if (selectedCalendar) {
			// Attach `session#` prefix for `eventId` according to BE requirements here:
			// https://niceday.slab.com/posts/pt-3-calendar-plan-session-6iypsymd
			const sessionEventId = SESSION_EVENT_ID_PREFIX + sensorDataResponse.id;
			yield call(
				upsertSessionEvent,
				selectedCalendar.calendarId,
				{
					eventId: sessionEventId,
					summary: title,
					description,
					start: startTime,
					end: endTime,
				},
				false,
			);
		}
	} catch (err) {
		log.captureException(err);
	}
}

export default function* () {
	yield takeEvery(getType(therapySessionActions.saveTherapySession.request), saveSessionTask);
}
