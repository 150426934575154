import {put, fork, debounce, call} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {moodChartActions} from "../redux/moodChartAction";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {Sensors} from "redux/tracking/TrackingTypes";
import {getSensorResolved} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {getSessionId} from "../../auth/helpers/authStorage";

const log = createLogger("moodChartSaga", SentryTags.MoodChart);

function* loadMoodChart(action: ActionType<typeof moodChartActions.loadMoodChart.request>) {
	try {
		const {userId, interval} = action.payload;
		const token: string = yield call(getSessionId);

		const sensorResolved = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			[Sensors.MOOD, Sensors.CHECK_IN_FEELING],
			interval.start,
			interval.end,
		);
		yield put(moodChartActions.loadMoodChart.success({userId: userId, data: sensorResolved}));
	} catch (err) {
		log.captureException(err);
		yield put(moodChartActions.loadMoodChart.failure({userId: action.payload.userId, err: err}));
	}
}

function* MoodChartSaga() {
	yield debounce(1000, getType(moodChartActions.loadMoodChart.request), loadMoodChart);
}

export default function* () {
	yield fork(MoodChartSaga);
}
