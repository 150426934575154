import {fork} from "redux-saga/effects";
import saveTherapySessionSaga from "./saveTherapySessionSaga";
import deleteTherapySessionSaga from "./deleteTherapySessionSaga";
import undoActionSaga from "./undoActionSaga";
import fetchTherapySessionSaga from "./fetchTherapySessionSaga";
import fetchOMQSMQSaga from "./fetchOMQSMQSaga";

export default function* () {
	yield fork(saveTherapySessionSaga);
	yield fork(deleteTherapySessionSaga);
	yield fork(undoActionSaga);
	yield fork(fetchTherapySessionSaga);
	yield fork(fetchOMQSMQSaga);
}
