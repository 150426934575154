import {PsychoEducationStatus} from "@sense-os/goalie-js";
import psychoEducationSDK from "../../../psychoEducation/helpers/psychoEducationSDK";

/**
 * Get psycho education list with `PsychoEducationItemList` type.
 */
export async function getPsychoEducationList(
	token: string,
	hashId: string,
	page: number,
	pageSize: number,
	psychoEducationStatus: PsychoEducationStatus,
) {
	return await psychoEducationSDK.getPsychoEducationList(
		token,
		undefined,
		undefined,
		hashId,
		page,
		pageSize,
		psychoEducationStatus,
	);
}
