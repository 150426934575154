import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";
import {MoodChartState} from "../moodChartTypes";
import {MoodChartActionType, moodChartActions} from "./moodChartAction";

const initialState: MoodChartState = {
	moodData: null,
	loadingState: LoadingState.EMPTY,
};

function moodChartReducer(state: MoodChartState = initialState, action: MoodChartActionType): MoodChartState {
	switch (action.type) {
		case getType(moodChartActions.loadMoodChart.request):
			return {
				...state,
				moodData: [],
				loadingState: LoadingState.LOADING,
				error: null,
			};
		case getType(moodChartActions.loadMoodChart.success):
			return {
				...state,
				moodData: action.payload.data,
				loadingState: LoadingState.LOADED,
				error: null,
			};
		case getType(moodChartActions.loadMoodChart.failure):
			return {
				...state,
				moodData: [],
				loadingState: LoadingState.ERROR,
				error: action.payload.err,
			};

		default:
			return state;
	}
}

export const moodChartMapReducer = produce((state: MoodChartState, action: MoodChartActionType) => {
	const actionTypes = [
		getType(moodChartActions.loadMoodChart.request),
		getType(moodChartActions.loadMoodChart.success),
		getType(moodChartActions.loadMoodChart.failure),
	];

	if (actionTypes.includes(action.type)) {
		state[action.payload.userId] = moodChartReducer(state[action.payload.userId], action);
	}
}, {});
