import {LoadingState} from "constants/redux";
import {AppState} from "redux/AppState";
import {createSelector} from "reselect";
import {getFirstItemNumber, getLastItemNumber} from "../../clientsFeed/helpers/clientsFeedHelpers";
import {MAX_ITEMS_PER_PAGE, FIRST_PAGE_NUMBER, SearchItemOption} from "../NDBlogSearchTypes";
import {isFormOpened as isMeetingNotesFormOpened} from "../../meetingNotes/redux/meetingNotesSelector";
import {isFormOpened as isPsychoEducationFormOpened} from "../../psychoEducation/redux/psychoEducationSelector";
import {showPasteActionInCurrentPath} from "../helpers/NDBlogSearchHelpers";

const getNDBlogSearchState = (state: AppState) => state.nicedayBlogSearch;

export const getSearchResultItems = createSelector(getNDBlogSearchState, (state) => state?.searchResultItems);

export const getLoadingState = createSelector(getNDBlogSearchState, (state) => state.loadingState);

export const isLoading = createSelector(getLoadingState, (state) => state === LoadingState.LOADING);

export const isLoaded = createSelector(getLoadingState, (state) => state === LoadingState.LOADED);

export const getSearchKeyword = createSelector(getNDBlogSearchState, (state) => state.searchKeywords);

export const getPasteLink = createSelector(getNDBlogSearchState, (state) => state.pasteLink);

export const isChatBoxOpen = (state: AppState) => state.UI.chatBoxState.isChatBoxOpen;

export const getSearchItemsTotal = createSelector(getNDBlogSearchState, (state) => state.searchResultItems?.length);

export const getCurrentPage = createSelector(getNDBlogSearchState, (state) => state.currentPage);

export const isDialogOpened = createSelector(getNDBlogSearchState, (state) => state.isOpened);

// Slice search items for support pagination
export const getItemsPerPage = createSelector(getSearchResultItems, getCurrentPage, (searchItems, currentPage) => {
	const firstItemNumber: number = getFirstItemNumber(currentPage, MAX_ITEMS_PER_PAGE);
	const lastItemNumber: number = getLastItemNumber(currentPage, MAX_ITEMS_PER_PAGE);
	return searchItems?.slice(firstItemNumber - FIRST_PAGE_NUMBER, lastItemNumber) || [];
});

/**
 * Since Niceday blog search button also available in other features form, we need to check which supported feature form is opened.
 * Supported features form: meeting notes, psycho-education.
 */
export const isSupportedFeaturesFormOpened = (state: AppState) => {
	return isMeetingNotesFormOpened(state) || isPsychoEducationFormOpened(state);
};

export const getSearchItemOption = (state: AppState) => {
	const isSupportedFormOpened = isSupportedFeaturesFormOpened(state);
	const isChatOpen = isChatBoxOpen(state);

	// We want the selected item to be pasted automatically in some forms and chat input
	if (isSupportedFormOpened || (isChatOpen && showPasteActionInCurrentPath(window.location.pathname))) {
		return SearchItemOption.PASTE_ITEM;
	}
	// Other than the forms above, we want the selected item to be copied into the clipboard
	return SearchItemOption.COPY_ITEM;
};
