import {Path} from "app/Path";
import {PortalFeatures} from "../featureFlags/userFeatureAccess";
import Localization from "../localization/Localization";
import strTranslation from "../assets/lang/strings";

export interface SidebarNavState {
	isSidebarCollapse: boolean;
}

export interface MenuEntity {
	id: string;
	name: string;
	iconName: string;
	pathname: string;
	feature: PortalFeatures;
}

export const availableMenus: MenuEntity[] = [
	{
		id: "main_menu_dash",
		name: Localization.formatMessage(strTranslation.SIDEBAR.dashboard.menu.item),
		iconName: "home",
		pathname: Path.APP,
		feature: PortalFeatures.dashboard,
	},
	{
		id: "main_menu_calendar",
		name: Localization.formatMessage(strTranslation.SIDEBAR.calendar.menu.item),
		iconName: "calendar",
		pathname: Path.APP_CALENDARS,
		feature: PortalFeatures.calendar,
	},
	{
		id: "main_menu_lib",
		name: Localization.formatMessage(strTranslation.SIDEBAR.library.menu.item),
		iconName: "book",
		pathname: Path.APP_LIBRARY,
		feature: PortalFeatures.library,
	},
	{
		id: "main_menu_administration",
		name: Localization.formatMessage(strTranslation.SIDEBAR.administration.menu.item),
		iconName: "work",
		pathname: Path.APP_TIME_TRACKING,
		feature: PortalFeatures.timeTracking,
	},
	{
		id: "main_menu_caseload",
		name: Localization.formatMessage(strTranslation.CASELOAD.sidebar.title),
		iconName: "people",
		pathname: Path.APP_THERAPIST_CASELOAD,
		feature: PortalFeatures.therapistCaseload,
	},
];

export enum UserlistTabs {
	CLIENTS = "clients",
	COLLEAGUES = "colleagues",
}

/**
 * This type will adapt to any number of tabs specified in UserlistTabs :)
 */
export interface TabbedUserListTranslations {
	searchPlaceholders: {[key in UserlistTabs]: string};
	tabHeaders: {[key in UserlistTabs]: string};
}

/**
 * Max unread messages for contact list, user tab ad chat box
 */
export enum MAX_UNREAD_CHAT {
	CONTACT_LIST = 99,
	USER_TAB = 999,
	CHAT_BOX = 99,
}
