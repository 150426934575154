import {TreatmentPersonnelRole} from "@sense-os/goalie-js";
import {call, select} from "redux-saga/effects";
import {getSessionId} from "../../auth/helpers/authStorage";

import {getAuthUser} from "../../auth/redux";
import {getContactByIdFn} from "../../contacts/redux/contactSelectors";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";

import {getClientStatus} from "../redux/treatmentStatusSelectors";
import treatmentStatusSdk from "../treatmentStatusSdk";

import {loadTreatmentPersonnel, postProcessFetchedTreatments} from "./loadTreatmentsSaga";

export function* addCurrentUserToTreatment(treatmentId: number) {
	const token: string = getSessionId();
	const userData = yield select(getAuthUser);

	const personnels = yield call(loadTreatmentPersonnel, treatmentId);
	if (!personnels || personnels.findIndex((p) => p.id === userData.id) === -1) {
		yield apiCallSaga(
			treatmentStatusSdk.addTreatmentPersonnelToTreatment,
			token,
			treatmentId,
			userData.hashId,
			TreatmentPersonnelRole.THERAPIST,
		);
	}
}

export function* automaticTreatmentCreationSaga(clientId: number) {
	const clientContact = yield select(getContactByIdFn(clientId));

	const token: string = getSessionId();
	let treatment = yield select(getClientStatus(clientId));

	if (!treatment || !!treatment.endTime) {
		// If no treatment in redux, first fetch from BE.
		// And if there's still no treatment, create a new one.
		const currentTreatments = yield apiCallSaga(
			treatmentStatusSdk.getTreatmentsOfAClient,
			token,
			clientContact.hashId,
		);

		const treatmentMaps = postProcessFetchedTreatments(currentTreatments);

		treatment =
			!!treatmentMaps[clientId] && !treatmentMaps[clientId].endTime
				? treatmentMaps[clientId]
				: yield apiCallSaga(treatmentStatusSdk.createTreatment, token, {
						patient: clientId,
						startTime: new Date(),
				  });
	}

	addCurrentUserToTreatment(treatment.id);

	return treatment.id;
}
