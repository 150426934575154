import {put, select, call} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import moment from "moment";
import {Contact, NotFoundError, PsychoEducationItem} from "@sense-os/goalie-js";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {toastActions} from "../../toaster/redux/toastAction";
import loc from "../../localization/Localization";
import {getSessionId} from "../../auth/helpers/authStorage";
import {UIAction} from "redux/UI/UIAction";
import {getPsychoEducationItems} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {extractEventViewId} from "redux/tracking/TrackingHelper";
import {getContactById} from "../../contacts/redux/contactSelectors";
import strTranslation from "../../assets/lang/strings";
import featureFlags from "../../featureFlags/FeatureFlags";

import {psychoEducationActions} from "../redux/psychoEducationActions";
import psychoEducationSDK from "../helpers/psychoEducationSDK";
import {getReminderDateTime} from "../helpers/psychoEducationHelpers";
import {
	getPsychoEducationData,
	getRemovedArticleIds,
	getPsychoEducationEventId,
} from "../redux/psychoEducationSelector";

const log = createLogger("psychoEducationSaga", SentryTags.PsychoEducation);

export function* savePsychoEducation(action: ActionType<typeof psychoEducationActions.savePsychoEducation.request>) {
	const userId = action.payload.userId || null;
	const formValues = action.payload.formValues;
	const eventViewId: string = yield select(getPsychoEducationEventId);
	const eventViewData: PsychoEducationItem = yield select(getPsychoEducationData);
	const isEditing: boolean = !!eventViewId;
	try {
		const token: string = yield call(getSessionId);
		// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
		const reminderDateTime: Date = yield call(getReminderDateTime, formValues.reminderToggle && formValues);
		// Show warning toast when `reminderDateTime` is before creation date
		if (moment(reminderDateTime).isBefore()) {
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.VALIDATION.reminder_must_be_in_future),
					type: "warning",
				}),
			);
			yield put(psychoEducationActions.savePsychoEducation.failure(new Error()));
			return;
		}
		if (isEditing) {
			// Update psycho education if `eventViewData` is not null
			yield apiCallSaga(psychoEducationSDK.updatePsychoEducation, token, {
				id: eventViewData.id,
				patient: eventViewData.patient,
				url: formValues.url, // TODO Remove this when psycho education v2 is fully implemented in production
				title: formValues.title,
				description: formValues.description,
				expiresAt: formValues.dueDate,
				remindAt: reminderDateTime,
			});
			if (featureFlags.psychoEducationV2) {
				// TODO Remove feature flag after psycho education v2 is fully implemented
				const removedArticleIds: number[] = yield select(getRemovedArticleIds);

				if (removedArticleIds.length > 0) {
					// Remove psycho education articles
					yield put(
						psychoEducationActions.removeArticles.request({
							psychoEducationId: eventViewData.id,
							articleIds: removedArticleIds,
						}),
					);
				} else {
					// Update psycho education articles
					yield put(
						psychoEducationActions.updateArticles.request({
							psychoEducationId: eventViewData.id,
							articles: formValues.articles,
						}),
					);
				}
			}
		} else {
			// Create new psycho education
			const response: PsychoEducationItem = yield apiCallSaga(psychoEducationSDK.createPsychoEducation, token, {
				patient: userId,
				url: formValues.url, // TODO Remove this when psycho education v2 is fully implemented in production
				title: formValues.title,
				description: formValues.description,
				expiresAt: formValues.dueDate,
				remindAt: reminderDateTime,
			});

			if (featureFlags.psychoEducationV2) {
				// TODO Remove feature flag after psycho education v2 is fully implemented
				// Create new psycho education articles
				yield put(
					psychoEducationActions.saveArticles.request({
						psychoEducationId: response.id,
						articlesBody: formValues.articles,
					}),
				);
			}
		}

		yield put(psychoEducationActions.savePsychoEducation.success({userId}));
		yield put(psychoEducationActions.closeForm());

		yield put(
			toastActions.addToast({
				message: loc.formatMessage(
					isEditing
						? strTranslation.GRAPHS.new_event.psycho_education.toast.update.success
						: strTranslation.GRAPHS.new_event.psycho_education.toast.create.success,
				),
				type: "info",
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(psychoEducationActions.savePsychoEducation.failure(err));

		if (err instanceof NotFoundError) {
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.GRAPHS.event_detail.psycho_education.toast.not_found),
					type: "error",
				}),
			);
		} else {
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.GRAPHS.new_event.psycho_education.toast.error),
					type: "error",
				}),
			);
		}
	}
}

export function* handleOpenForm() {
	// Close chat box whenever Psycho education form is opened
	yield put(UIAction.closeChatBox());
}

export function* fetchPsychoEducation(action: ActionType<typeof psychoEducationActions.fetchPsychoEducation.request>) {
	const {userId, eventViewId} = action.payload;
	const {occurrenceTime, id} = extractEventViewId(eventViewId);
	const contact: Contact = yield select((state) => getContactById(state, userId));

	const contactHashId = contact.hashId;
	const token = yield call(getSessionId);
	try {
		const psychoEducations: PsychoEducationItem[] = yield apiCallSaga(
			getPsychoEducationItems,
			token,
			occurrenceTime,
			occurrenceTime,
			contactHashId,
		);
		const result = psychoEducations.find((data) => data.id === parseInt(id, 10));
		yield put(psychoEducationActions.fetchPsychoEducation.success({psychoEducationData: result}));
	} catch (err) {
		log.captureException(err);
		yield put(psychoEducationActions.fetchPsychoEducation.failure(err));
	}
}
