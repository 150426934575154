import {StringMap} from "services/utils/Maps";
import {LoadingState} from "constants/redux";
import {TIME} from "constants/time";
import {GetUserFeedResponse} from "@sense-os/goalie-js";
import {EventViewType} from "redux/tracking/TrackingTypes";
import {MenuItemProps} from "@material-ui/core/MenuItem/MenuItem";

export interface UserFeed extends GetUserFeedResponse {
	/**
	 * Unique id for feed
	 *
	 * This unique id is created by portal
	 */
	id?: string;
	/**
	 * Datetime of when an feed is completed
	 */
	completedAt: Date;
	/**
	 * Datetime of when an feed is started
	 */
	occurrenceStartTime: Date;
	/**
	 * Datetime of when an feed is ended
	 */
	occurrenceEndTime: Date;
	/**
	 * Datetime of when an feed is created
	 */
	createdAt: Date;
	/**
	 * Datetime of when an feed is updated
	 */
	updatedAt: Date;
	/**
	 * Link to user profile of the owner who owns the feed
	 */
	ownerLink?: string;
	/**
	 * Link to user profile of the creator who creates the feed
	 */
	creatorLink?: string;
	/**
	 * Link to where the user will be redirected to when user click the feed
	 */
	sourceLink?: string;
}

/**
 * Available feed types enum
 */
export enum FeedType {
	DIARY_ENTRY = "diary_entry",
	PLANNED_EVENT = "planned_event_entry",
	RECURRING_PLANNED_EVENT = "planned_event_log",
	MEETING_NOTE = "meeting_note",
	THOUGHT_RECORD = "gscheme",
	THERAPY_SESSION = "therapy_session",
	SMQ = "smq",
	OMQ = "omq",
	PSYCHO_EDUCATION = "homework",
	BEHAVIOR_EXPERIMENT = "behavior_experiment",
	CHECK_IN_FEELING = "check_in",
}

/**
 * Map of matching `EventViewType` based on `FeedType`.
 * This is important for the portal to know which `EventViewType` the feed belongs to.
 */
export const feedEventViewTypeMap = {
	[FeedType.DIARY_ENTRY]: EventViewType.DIARY_ENTRY_SENSOR,
	[FeedType.MEETING_NOTE]: EventViewType.MEETING_NOTE_SENSOR,
	[FeedType.THOUGHT_RECORD]: EventViewType.THOUGHT_RECORDS_SENSOR,
	[FeedType.PLANNED_EVENT]: EventViewType.PLANNED_EVENT_SENSOR,
	[FeedType.SMQ]: EventViewType.SMQ_SENSOR,
	[FeedType.THERAPY_SESSION]: EventViewType.THERAPY_SESSION_SENSOR,
	[FeedType.OMQ]: EventViewType.OMQ_SENSOR,
	[FeedType.RECURRING_PLANNED_EVENT]: EventViewType.RECURRING_PLANNED_EVENT,
	[FeedType.PSYCHO_EDUCATION]: EventViewType.PSYCHO_EDUCATION,
	[FeedType.BEHAVIOR_EXPERIMENT]: EventViewType.BEHAVIOR_EXPERIMENT,
	[FeedType.CHECK_IN_FEELING]: EventViewType.CHECK_IN_FEELING,
};

/**
 * Available Client feed enum
 */
export enum ClientsFeedLabel {
	PAST_ACTIVITY = "past_activity",
	UPCOMING_ACTIVITY = "upcoming_activity",
}

/** First client feed page number value  */
export const FIRST_PAGE_NUMBER: number = 1;
/** Maximum client feed items per page value  */
export const MAX_ITEM_PER_PAGE: number = 8;

/**
 * Clients Feed State interface
 */
export interface ClientsFeedState {
	/** Current active ClientFeedLabel */
	activeLabel: ClientsFeedLabel;
	/** ClientFeed mapped by its id */
	feedsMap: StringMap<UserFeed>;
	/** loadingFeeds state */
	loadingFeeds: LoadingState;
	/** Current page value */
	currentPage: number;
	/** max item per page value */
	maxItemPerPage: number;
	/** time range in hour */
	hourRange: number;
}

export const HourRange = {
	ONE_DAY: TIME.HOURS_IN_DAY,
	TWO_DAYS: TIME.HOURS_IN_DAY * 2,
	THREE_DAYS: TIME.HOURS_IN_DAY * 3,
	ONE_WEEK: TIME.HOURS_IN_DAY * TIME.DAYS_IN_WEEK,
	ONE_MONTH: TIME.HOURS_IN_DAY * TIME.DAYS_IN_MONTH_30,
};

export type ClientFeedDropDownItem = Pick<
	MenuItemProps,
	"id" | "disabled" | "value" | "children" | "selected" | "style"
>;
