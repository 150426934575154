import {RegisterResponse} from "@sense-os/goalie-js/dist/authentication/types";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {AuthUser} from "../authTypes";

export const authActions = {
	login: createAsyncAction("auth_actions/login", "auth_actions/login_succeed", "auth_actions/login_failed")<
		{email: string; password: string},
		{user: AuthUser},
		{error: Error}
	>(),
	register: createAsyncAction(
		"auth_actions/register",
		"auth_actions/register_succeed",
		"auth_actions/register_failed",
	)<
		{email: string; password: string; firstName: string; lastName: string},
		{response: RegisterResponse},
		{error: Error}
	>(),
	resetPassword: createAsyncAction(
		"auth_actions/reset_password",
		"auth_actions/reset_password_succeed",
		"auth_actions/reset_password_failed",
	)<{email: string}, {}, {error: Error}>(),
	changePassword: createAsyncAction(
		"auth_actions/change_password",
		"auth_actions/change_password_succeed",
		"auth_actions/change_password_failed",
	)<{uid: string; token: string; password: string}, {}, {error: Error}>(),

	resetError: createAction("auth_actions/reset_error")(),
	updateAuthUser: createAction("auth_actions/update_auth_user", (user: Partial<AuthUser>) => user)(),

	logout: createAsyncAction("auth_actions/logout", "auth_actions/logout_succeed", "auth_actions/logout_failed")<
		{isSessionExpired?: boolean},
		{},
		{}
	>(),
	resetState: createAction("auth_actions/reset_state")(),

	login2FACode: createAsyncAction(
		"auth_actions/login_2FAcode",
		"auth_actions/login_2FAcode_succeed",
		"auth_actions/login_2FAcode_failed",
	)<{code: string}, {user: AuthUser}, {error: Error}>(),

	login2FARecoveryCode: createAsyncAction(
		"auth_actions/login_2FA_recoveryCode",
		"auth_actions/login_2FA_recoveryCode_succeed",
		"auth_actions/login_2FA_recoveryCode_failed",
	)<{code: string}, {user: AuthUser}, {error: Error}>(),

	resetPassword2FACode: createAsyncAction(
		"auth_actions/reset_password_2FACode",
		"auth_actions/reset_password_2FACode_succeed",
		"auth_actions/reset_password_2FACode_failed",
	)<{code: string; uid: string; token: string; password: string}, void, {error: Error}>(),

	resetPassword2FARecoveryCode: createAsyncAction(
		"auth_actions/reset_password_2FA_recoveryCode",
		"auth_actions/reset_password_2FA_recoveryCode_succeed",
		"auth_actions/reset_password_2FA_recoveryCode_failed",
	)<{code: string; uid: string; token: string; password: string}, void, {error: Error}>(),

	blockLogin: createAction("auth_actions/block_login")(),
	unblockLogin: createAction("auth_actions/unblock_login")(),
	resetLoginLoadingState: createAction("auth_actions/reset_login_loading_state")(),
	initUser: createAction("auth_actions/initUser", (user: AuthUser) => {
		return {user};
	})(),
};

export type AuthActionType = ActionType<typeof authActions>;
