import {ActionType, getType} from "typesafe-actions";
import {select, put, call, takeEvery, race, take} from "redux-saga/effects";
import {callActions} from "../redux/callActions";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {toastActions} from "../../toaster/redux";
import localization from "../../localization/Localization";
import twilioSDK from "../../twilio/twilioSDK";
import {getAuthUser} from "../../auth/redux";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {CallSDK, CallType} from "@sense-os/goalie-js";
import {AuthUser} from "../../auth/authTypes";
import chatSDK from "../../chat/sdk";
import strTranslation from "../../assets/lang/strings";

function* acceptCall({payload: {isVideo}}: ActionType<typeof callActions.acceptCall>) {
	yield put(callActions.openCallWindow.request());

	const result = yield race({
		success: take(getType(callActions.openCallWindow.success)),
		failure: take(getType(callActions.openCallWindow.failure)),
	});

	if (result.failure) {
		// When call window is not opened, then do nothing here.
		return;
	}

	const activeCall: ActiveCall = yield select(getActiveCall);
	const authUser: AuthUser = yield select(getAuthUser);
	try {
		const accessToken = yield call(getTwilioToken, activeCall);
		yield call(chatSDK.acceptCall, activeCall.roomId, CallSDK.Twilio, {});
		yield put(
			callActions.createActiveCall({...activeCall, accessToken, type: isVideo ? CallType.Video : CallType.Audio}),
		);
		yield put(callActions.userJoinCall(authUser.id, Date.now()));
	} catch (error) {
		yield put(callActions.closeCallWindow());
		yield put(
			toastActions.addToast({
				message:
					localization.formatMessage(strTranslation.CHAT.video.call_failed.toast) +
					" " +
					JSON.stringify(error),
				type: "error",
			}),
		);
	}
}

function* getTwilioToken(activeCall: ActiveCall) {
	const authUser = yield select(getAuthUser);
	const identity = activeCall.participantMap[authUser.id].publicId;
	const token = yield call(twilioSDK.createVideoAccessToken, authUser.token, activeCall.roomId, {identity});
	return token;
}

export function* acceptCallSaga() {
	yield takeEvery(getType(callActions.acceptCall), acceptCall);
}
