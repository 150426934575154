import {TrackerStatusRequestBody} from "@sense-os/goalie-js";
import {call, put} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import trackersSDK from "../../../customTracker/helpers/trackersSDK";
import {removeTrackerReminders} from "../../../customTracker/sagas/manageTrackersSaga";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";
import {interventionCustomTrackerActions} from "../redux/interventionCustomTrackerActions";

const log = createLogger("enableOrDisableTrackers", SentryTags.Intervention);

export function* enableOrDisableTrackers(
	action: ActionType<typeof interventionCustomTrackerActions.enableOrDisableTrackers.request>,
) {
	const {customTrackers, userId, isEnabled, trackerId} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		// Remove disabled tracker reminders
		const disabledCustomTrackerScheduleTypes: string[] = customTrackers
			.filter((tracker) => {
				return !tracker.isEnabled;
			})
			.map((tracker) => {
				return tracker.sensorName;
			});
		yield call(removeTrackerReminders, disabledCustomTrackerScheduleTypes);

		const updateTrackerStatusPayload: TrackerStatusRequestBody = {
			isEnabled,
			tracker: trackerId,
			patient: userId,
		};
		yield call(trackersSDK.updateTrackerStatus, token, updateTrackerStatusPayload);

		yield put(interventionCustomTrackerActions.enableOrDisableTrackers.success({customTrackers}));
	} catch (error) {
		log.captureException(error);
		yield put(interventionCustomTrackerActions.enableOrDisableTrackers.failure({error}));
	}
}
