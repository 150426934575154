import moment from "moment";
import {
	SingleLineChartConfig,
	ChartType,
	SingleLineChartDatum,
	ChartConfigOptions,
	ChartConfigAggregationStrategy,
} from "../../customTrackerChartTypes";
import {RangeOption} from "../../customTrackerTypes";
import {
	intensityTextFn,
	intensityAverageTextFn,
	timeTextFn,
	intensityTooltipTextFn,
	totalRegistrationTextFn,
	intensityAverageTooltipTextFn,
} from "../trackerLocalizations";
import {numberToString} from "../customTrackerHelpers";

/**
 * Returns chart configuration for counting average intensity of a tracker.
 */
export default function createIntensityChartConfig(
	measureName: string,
	options: ChartConfigOptions = {aggregateStrategy: ChartConfigAggregationStrategy.AVERAGE, skipEmptyDomain: true},
): SingleLineChartConfig {
	return {
		type: ChartType.SingleLineChart,
		titleFn: (rangeOption) => (rangeOption === RangeOption.RAW_DATA ? intensityTextFn() : intensityAverageTextFn()),
		trackerDatumToChartDatumFn: (datum) => {
			return {
				source: datum,
				x: datum.date.valueOf(),
				y: datum.values[measureName].intensity,
			};
		},
		customAggregatorFn: (date, selectedData) => {
			let result = 0;
			const filteredData = selectedData.filter((datum) => datum.y !== null && datum.y !== undefined);
			// Return null so that the chart won't draw the data point
			if (options.skipEmptyDomain && filteredData.length === 0) {
				return null;
			}
			const total = filteredData.reduce((acc, data) => acc + data.y, 0);
			if (total > 0 && options.aggregateStrategy === ChartConfigAggregationStrategy.AVERAGE) {
				result = total / filteredData.length;
			}
			return {
				source: selectedData,
				x: date.valueOf(),
				y: result,
			};
		},
		tooltipContent: (datum, rangeOption) => {
			let results: string[] = [];
			if (rangeOption === RangeOption.RAW_DATA) {
				results.push(timeTextFn(moment(datum.x).format("HH:mm")));
				results.push(intensityTooltipTextFn(numberToString(datum.y)));
			} else {
				let source: SingleLineChartDatum[] = datum.source;
				results.push(totalRegistrationTextFn(source.length));
				results.push(intensityAverageTooltipTextFn(numberToString(datum.y)));
			}

			return results.join("\n");
		},
	};
}
