import {createSelector} from "reselect";
import moment from "moment";

import {AppState} from "redux/AppState";
import {LoadingState} from "constants/redux";
import {OMQEventView, SMQEventView, TherapySessionEventView} from "redux/tracking/TrackingTypes";
import {
	getReminderDurationInHour,
	getReminderDurationInMinutes,
} from "../../psychoEducation/helpers/psychoEducationHelpers";

import {ReminderTimeUnit, TherapySessionFormValues} from "../types";

const getTherapySessionState = (state: AppState) => state.therapySession;

export const getUserId = (state: AppState) => {
	return getTherapySessionState(state).userId;
};

export const getTherapySessionForm = (state: AppState): TherapySessionFormValues => {
	const therapySession: TherapySessionEventView = getTherapySessionState(state).therapySession;
	if (!therapySession) {
		return;
	}

	const therapySessionValue = therapySession.source.value;

	// Set reminder duration and time unit
	let reminderDuration = getReminderDurationInMinutes(
		therapySession.source.startTime,
		therapySessionValue.plannedFor,
	);
	let reminderTimeUnit = ReminderTimeUnit.MINUTES;

	if (reminderDuration >= 60) {
		// IF duration >= 60 minutes, set duration and timeUnit in hour
		reminderDuration = getReminderDurationInHour(therapySession.source.startTime, therapySessionValue.plannedFor);
		reminderTimeUnit = ReminderTimeUnit.HOURS;
	}

	return {
		title: therapySessionValue.title,
		description: therapySessionValue.description,
		startTime: therapySession.source.startTime,
		endTime: therapySession.source.endTime,
		smqToogle: !!therapySessionValue.plannedSmq,
		omqToogle: !!therapySessionValue.plannedOmq,
		reminderToggle: therapySessionValue.shouldSendNotification,
		reminderDuration: reminderDuration,
		reminderTimeUnit: reminderTimeUnit,
		sessionDuration: moment(therapySession.source.endTime).diff(therapySession.source.startTime, "minutes"),
	};
};

export const isFormOpened = (state: AppState) => {
	return getTherapySessionState(state).isFormOpened;
};

export const isSavingSession = (state: AppState) => {
	return getTherapySessionState(state).savingState === LoadingState.LOADING;
};

export const sessionSavingState = (state: AppState) => {
	return getTherapySessionState(state).savingState;
};

export const sessionDeleteState = (state: AppState) => getTherapySessionState(state).deleteState;

export const getSavedFormValuesMap = (state: AppState) => {
	return getTherapySessionState(state).savedFormValues;
};

export const getSavedFormValues = createSelector(
	getUserId,
	getSavedFormValuesMap,
	(userId, savedFormValuesMap) => savedFormValuesMap[userId],
);

export const getTherapySessionData = (state: AppState) => {
	return getTherapySessionState(state).therapySession;
};

export const getTherapySessionFetchState = (state: AppState): LoadingState => {
	return getTherapySessionState(state).fetchState;
};

export const getTherapySessionEventViewId = (state: AppState): string => {
	return getTherapySessionState(state).eventViewId;
};

export const getOMQData = (state: AppState): OMQEventView => {
	return getTherapySessionState(state).omqData;
};

export const getOMQFetchState = (state: AppState): LoadingState => {
	return getTherapySessionState(state).omqFetchState;
};

export const getSMQData = (state: AppState): SMQEventView => {
	return getTherapySessionState(state).smqData;
};

export const getSMQFetchState = (state: AppState): LoadingState => {
	return getTherapySessionState(state).smqFetchState;
};
