import {call, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {interventionTreatmentGoalActions} from "../redux/interventionTreatmentGoalActions";
import treatmentStatusSdk from "../../../treatmentStatus/treatmentStatusSdk";
import {Treatment, TreatmentPersonnelRole} from "@sense-os/goalie-js";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import {TreatmentFromForm, TreatmentProgress} from "../../../treatmentStatus/treatmentStatusTypes";
import {TreatmentGoalPayload} from "../interventionTreatmentGoalType";
import {setTreatmentDetail} from "../../../treatmentStatus/sagas/createOrUpdateTreatmentSaga";
import {getClientStatus} from "../../../treatmentStatus/redux/treatmentStatusSelectors";
import {getAuthUser} from "../../../auth/redux";

type UpdateTreatmentPayload = Omit<Treatment, "terminatingReason"> & {terminatingReason: number};

const log = createLogger("putTreatmentStatus", SentryTags.Intervention);

function* putTreatmentStatus(action: ActionType<typeof interventionTreatmentGoalActions.putTreatmentStatus.request>) {
	const {treatmentGoal, treatmentStatus} = action.payload;

	const currentTreatment = yield select(getClientStatus(treatmentGoal.patient));
	const authUser = yield select(getAuthUser);

	try {
		const token: string = yield call(getSessionId);

		if (treatmentStatus === TreatmentProgress.NOT_IN_TREATMENT_YET) {
			const startTime = new Date();
			const sdkTreatment = yield apiCallSaga(treatmentStatusSdk.createTreatment, token, {
				patient: treatmentGoal.patient,
				startTime: startTime,
			});

			const treatmentGoalPayload: TreatmentGoalPayload = {
				id: sdkTreatment.id,
				goal: treatmentGoal.goal,
				patient: treatmentGoal.patient,
			};

			const treatmentForm: TreatmentFromForm = {
				clientComplaints: [],
				mainComplaint: "",
				startTime: startTime,
				treatmentTypes: [],
				personnels: [
					{
						therapistId: authUser.id,
						role: TreatmentPersonnelRole.THERAPIST,
					},
				],
			};
			yield call(setTreatmentDetail, treatmentGoal.patient, sdkTreatment, treatmentForm, currentTreatment);

			const result: Treatment = yield apiCallSaga(
				treatmentStatusSdk.updateTreatment,
				token,
				treatmentGoalPayload as UpdateTreatmentPayload,
			);

			yield put(interventionTreatmentGoalActions.putTreatmentStatus.success({treatment: result}));
		} else {
			const result: Treatment = yield apiCallSaga(
				treatmentStatusSdk.updateTreatment,
				token,
				treatmentGoal as UpdateTreatmentPayload,
			);
			yield put(interventionTreatmentGoalActions.putTreatmentStatus.success({treatment: result}));
		}
		yield put(interventionTreatmentGoalActions.openModal({isOpen: false}));
		yield put(toastActions.addToast({message: strTranslation.INTERVENTION.treatment.goal.saved, type: "success"}));
	} catch (error) {
		log.error(error);
		yield put(interventionTreatmentGoalActions.putTreatmentStatus.failure({err: error}));
		yield put(
			toastActions.addToast({message: strTranslation.INTERVENTION.treatment.goal.failed_to_save, type: "error"}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(interventionTreatmentGoalActions.putTreatmentStatus.request), putTreatmentStatus);
}
