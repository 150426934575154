import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {getDailyPlannerItems} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {OMQEventView, SMQEventView} from "redux/tracking/TrackingTypes";
import {extractEventViewId} from "redux/tracking/TrackingHelper";
import {transformDailyPlannerIntoEventViewData} from "../../clientActivity/helpers/dailyPlannerActivityHelpers";
import {therapySessionActions} from "../redux/therapySessionActions";
import {SentryTags} from "../../errorHandler/createSentryReport";

const logOmq = createLogger("fetchOmq", SentryTags.TherapySession);

export function* fetchOmq(action: ActionType<typeof therapySessionActions.fetchOmqSession.request>) {
	const {userId, eventViewId} = action.payload;
	const {occurrenceTime} = extractEventViewId(eventViewId);
	const token = yield call(getSessionId);
	try {
		const dailyPlanners = yield apiCallSaga(getDailyPlannerItems, token, userId, occurrenceTime, occurrenceTime);
		const dailyPlannerActivities = dailyPlanners.map(transformDailyPlannerIntoEventViewData).filter(Boolean);
		const result: OMQEventView = dailyPlannerActivities.find((data: OMQEventView) => data.id === eventViewId);
		yield put(therapySessionActions.fetchOmqSession.success({data: result}));
	} catch (err) {
		logOmq.captureException(err);
		yield put(therapySessionActions.fetchOmqSession.failure(err));
	}
}

const logSmq = createLogger("fetchSmq", SentryTags.TherapySession);

export function* fetchSmq(action: ActionType<typeof therapySessionActions.fetchSmqSession.request>) {
	const {userId, eventViewId} = action.payload;
	const {occurrenceTime} = extractEventViewId(eventViewId);
	const token = yield call(getSessionId);
	try {
		const dailyPlanners = yield apiCallSaga(getDailyPlannerItems, token, userId, occurrenceTime, occurrenceTime);
		const dailyPlannerActivities = dailyPlanners.map(transformDailyPlannerIntoEventViewData).filter(Boolean);
		const result: SMQEventView = dailyPlannerActivities.find((data: SMQEventView) => data.id === eventViewId);
		yield put(therapySessionActions.fetchSmqSession.success({data: result}));
	} catch (err) {
		logSmq.captureException(err);
		yield put(therapySessionActions.fetchSmqSession.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(therapySessionActions.fetchOmqSession.request), fetchOmq);
	yield takeEvery(getType(therapySessionActions.fetchSmqSession.request), fetchSmq);
}
