import {ClientsFeedState, ClientsFeedLabel, FIRST_PAGE_NUMBER, MAX_ITEM_PER_PAGE} from "../clientsFeedTypes";
import {ClientsFeedActionType, clientsFeedActions} from "./ClientsFeedActions";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {TIME} from "constants/time";

const initialState: ClientsFeedState = {
	activeLabel: ClientsFeedLabel.PAST_ACTIVITY,
	currentPage: FIRST_PAGE_NUMBER,
	maxItemPerPage: MAX_ITEM_PER_PAGE,
	loadingFeeds: LoadingState.EMPTY,
	feedsMap: {},
	hourRange: TIME.HOURS_IN_DAY as number,
};

export function clientsFeedReducer(
	state: ClientsFeedState = initialState,
	action: ClientsFeedActionType,
): ClientsFeedState {
	switch (action.type) {
		//
		// LOAD FEEDS
		//
		/** Show loading indicator, reset `feedsMap`, and reset `currentPage` */
		case getType(clientsFeedActions.loadFeeds.request): {
			return {...state, feedsMap: {}, loadingFeeds: LoadingState.LOADING, currentPage: FIRST_PAGE_NUMBER};
		}
		/** Stop loading indicator and store `feedsMap` */
		case getType(clientsFeedActions.loadFeeds.success): {
			const newFeeds = action.payload.feeds.reduce((feedsMap, feed) => {
				return {
					...feedsMap,
					[feed.id]: feed,
				};
			}, state.feedsMap);
			return {
				...state,
				feedsMap: newFeeds,
				loadingFeeds: LoadingState.LOADED,
			};
		}
		/** Stop loading indicator */
		case getType(clientsFeedActions.loadFeeds.failure): {
			return {
				...state,
				loadingFeeds: LoadingState.ERROR,
			};
		}

		/** Set active feed label */
		case getType(clientsFeedActions.setActiveLabel): {
			return {...state, activeLabel: action.payload};
		}
		/** Add page number by 1 */
		case getType(clientsFeedActions.incrementPage): {
			return {...state, currentPage: state.currentPage + 1};
		}
		/** Subtract page number by 1 */
		case getType(clientsFeedActions.decrementPage): {
			return {...state, currentPage: state.currentPage - 1};
		}

		case getType(clientsFeedActions.setHourRange): {
			return {...state, hourRange: action.payload.hours};
		}

		default:
			return state;
	}
}
