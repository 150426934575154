import {call, put} from "redux-saga/effects";
import {SensorDatum, Sensors} from "redux/tracking/TrackingTypes";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getSensorResolved} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {CustomTrackerSensor} from "../../../customTracker/customTrackerTypes";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {
	mergeCustomTrackerAndDiaryEntry,
	sortCustomTrackerSensorByLatestDate,
} from "../helpers/interventionCustomTrackerHelper";
import {TrackerDatumWithEventViewData} from "../interventionCustomTrackerType";
import {interventionCustomTrackerActions} from "../redux/interventionCustomTrackerActions";

const log = createLogger("fetchRegisteredCustomTrackers", SentryTags.Intervention);

export function* fetchRegisteredCustomTrackers(
	action: ActionType<typeof interventionCustomTrackerActions.fetchRegisteredCustomTrackers.request>,
) {
	const {userId, sensorName} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		const response: SensorDatum<CustomTrackerSensor>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			[sensorName, Sensors.DIARY],
			undefined,
			undefined,
		);

		const mergedCustomTrackerDiaryEntry: TrackerDatumWithEventViewData[] = mergeCustomTrackerAndDiaryEntry({
			sensorList: response,
			selectedSensor: sensorName as Sensors,
			userId,
		});

		const registeredCustomTrackers: TrackerDatumWithEventViewData[] =
			sortCustomTrackerSensorByLatestDate(mergedCustomTrackerDiaryEntry);

		yield put(
			interventionCustomTrackerActions.fetchRegisteredCustomTrackers.success({
				registeredCustomTrackers,
				totalRegisteredCustomTrackers: registeredCustomTrackers.length,
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(interventionCustomTrackerActions.fetchRegisteredCustomTrackers.failure({error}));
	}
}
