import {SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {TrackerCategory, Tracker, UserTracker, TrackerDataMap, CustomTrackerSensor} from "../customTrackerTypes";

export const customTrackerActions = {
	openSetupTrackerDialog: createAction("customTrackerActions/openSetupTrackerDialog")(),
	closeSetupTrackerDialog: createAction("customTrackerActions/closeSetupTrackerDialog")(),
	loadTrackerCategories: createAsyncAction(
		"customTrackerActions/loadTrackerCategies",
		"customTrackerActions/loadTrackerCategiesSuccess",
		"customTrackerActions/loadTrackerCategiesFail",
	)<void, TrackerCategory[], Error>(),
	addTracker: createAsyncAction(
		"customTrackerActions/addTracker",
		"customTrackerActions/addTrackerSuccess",
		"customTrackerActions/addTrackerFail",
	)<{trackerId: number}, {userId: number; tracker: Tracker}, Error>(),
	showTrackerAddedDialog: createAction("customTrackerActions/showTrackerAddedDialog", (newTracker: Tracker) => ({
		newTracker,
	}))(),
	hideTrackerAddedDialog: createAction("customTrackerActions/hideTrackerAddedDialog")(),

	loadUserTrackers: createAsyncAction(
		"customTrackerActions/loadUserTrackers",
		"customTrackerActions/loadUserTrackersSuccess",
		"customTrackerActions/loadUserTrackersFail",
	)<
		{userId: number},
		{userId: number; enabledTrackers: Tracker[]; disabledTrackers: Tracker[]; trackers: UserTracker[]},
		{userId: number; error: Error}
	>(),

	loadTrackerData: createAsyncAction(
		"customTrackerActions/loadTrackerData",
		"customTrackerActions/loadTrackerDataSuccess",
		"customTrackerActions/loadTrackerDataFail",
	)<
		{userId: number; sensorNames: string[]},
		{userId: number; trackerDataMap: TrackerDataMap},
		{userId: number; error: Error}
	>(),
	openManageTrackersDialog: createAction("customTrackerActions/openManageTrackersDialog")(),
	closeManageTrackersDialog: createAction("customTrackerActions/closeManageTrackersDialog")(),

	saveManageTrackers: createAsyncAction(
		"customTrackerActions/saveManageTrackers",
		"customTrackerActions/saveManageTrackersSuccess",
		"customTrackerActions/saveManageTrackersFail",
	)<{trackers: UserTracker[]}, {userId: number; trackers: Tracker[]}, Error>(),

	//
	// Fetch custom tracker dialog actions
	//
	fetchCustomTrackerDialog: createAsyncAction(
		"customTrackerActions/fetchCustomTrackerDialog",
		"customTrackerActions/fetchCustomTrackerDialogSuccess",
		"customTrackerActions/fetchCustomTrackerDialogFail",
	)<{userId: number; eventViewId: string}, {customTrackerData: SensorDatum<CustomTrackerSensor>}, Error>(),
};

export type CustomTrackerActionType = ActionType<typeof customTrackerActions>;
