import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {ClientsFeedLabel, UserFeed} from "../clientsFeedTypes";

export const clientsFeedActions = {
	/** load feeds */
	loadFeeds: createAsyncAction(
		"clientsFeedActions/loadFeeds",
		"clientsFeedActions/loadFeedsSuccess",
		"clientsFeedActions/loadFeedsFail",
	)<void, {label: ClientsFeedLabel; feeds: UserFeed[]}, Error>(),

	/** Set feed active label. Set page to first page and clear feedsMap data */
	setActiveLabel: createAction("clientsFeedActions/setActiveLabel", (label: ClientsFeedLabel) => label)(),

	/** Set hour range */
	setHourRange: createAction("clientsFeedActions/setHourRange", (hours: number) => ({hours}))(),

	/** Increment feed page */
	incrementPage: createAction("clientsFeedActions/incrementPage")(),

	/** Decrement feed page */
	decrementPage: createAction("clientsFeedActions/decrement")(),
};

export type ClientsFeedActionType = ActionType<typeof clientsFeedActions>;
