import {ActionType, createAsyncAction} from "typesafe-actions";
import {Interval, Mood, SensorDatum} from "redux/tracking/TrackingTypes";

export const moodChartActions = {
	//
	// Load mood chart actions
	//
	loadMoodChart: createAsyncAction(
		"moodChart/loadMoodChart",
		"moodChart/loadMoodChartSuccess",
		"moodChart/loadMoodChartFail",
	)<
		{userId: number; interval: Interval},
		{userId: number; data: SensorDatum<Mood>[]},
		{userId: number; err: Error}
	>(),
};

export type MoodChartActionType = ActionType<typeof moodChartActions>;
