import {ArticleRequestCreate, PsychoEducationItem} from "@sense-os/goalie-js";
import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {PsychoEducationArticle, PsychoEducationFormValues} from "../psychoEducationTypes";

export const psychoEducationActions = {
	//
	// Form dialog actions
	//
	openForm: createAction("psychoEducationActions/openForm", (userId: number, eventViewId?: string) => ({
		userId,
		eventViewId,
	}))(),
	closeForm: createAction("psychoEducationActions/closeForm")(),

	//
	// Save form values actions
	//
	saveFormValues: createAction(
		"psychoEducationActions/saveFormValues",
		(userId: number, formValues?: PsychoEducationFormValues) => ({userId, formValues}),
	)(),

	//
	// Save psycho education actions
	//
	savePsychoEducation: createAsyncAction(
		"psychoEducationActions/savePsychoEducation",
		"psychoEducationActions/savePsychoEducationSuccess",
		"psychoEducationActions/savePsychoEducationFail",
	)<{userId?: number; formValues: PsychoEducationFormValues}, {userId?: number}, Error>(),

	//
	// Save psycho education articles actions
	//
	saveArticles: createAsyncAction(
		"psychoEducationActions/saveArticles",
		"psychoEducationActions/saveArticlesSuccess",
		"psychoEducationActions/saveArticlesFail",
	)<{psychoEducationId: number; articlesBody: ArticleRequestCreate[]}, void, Error>(),

	//
	// Update psycho education articles actions
	//
	updateArticles: createAsyncAction(
		"psychoEducationActions/updateArticles",
		"psychoEducationActions/updateArticlesSuccess",
		"psychoEducationActions/updateArticlesFail",
	)<{psychoEducationId: number; articles: PsychoEducationArticle[]}, void, Error>(),

	//
	// Remove psycho education articles actions
	//
	removeArticles: createAsyncAction(
		"psychoEducationActions/removeArticles",
		"psychoEducationActions/removeArticlesSuccess",
		"psychoEducationActions/removeArticlesFail",
	)<{psychoEducationId: number; articleIds: number[]}, void, Error>(),

	//
	// Set article id to be removed action
	//
	setRemovedArticleId: createAction("psychoEducationActions/setRemovedArticleId", (articleIds: number[]) => ({
		articleIds,
	}))(),

	//
	// fetch psycho education actions
	//
	fetchPsychoEducation: createAsyncAction(
		"psychoEducationActions/fetchPsychoEducation",
		"psychoEducationActions/fetchPsychoEducationSuccess",
		"psychoEducationActions/fetchPsychoEducationFail",
	)<{userId: number; eventViewId: string}, {psychoEducationData: PsychoEducationItem}, Error>(),

	//
	// delete psycho education actions
	//
	deletePsychoEducation: createAsyncAction(
		"psychoEducationActions/deletePsychoEducation",
		"psychoEducationActions/deletePsychoEducationSuccess",
		"psychoEducationActions/deletePsychoEducationFail",
	)<{eventViewId: string}, void, Error>(),
};

export type PsychoEducationActionType = ActionType<typeof psychoEducationActions>;
