import {UserRole, NotFoundError, ContactType, ConnectedContact, RequestedContact} from "@sense-os/goalie-js/dist";
import contactSdk from "./contactSdk";
import {Contact, IncomingInvitation} from "./contactTypes";
import {Therapist} from "@sense-os/goalie-js/dist/organization/Therapist";
import {fetchAllTherapists} from "../organizations/helpers/organizationSdkHelpers";
import {AppConfig} from "app/AppConfig";
import {AuthUser} from "../auth/authTypes";

/**
 * Fetch contacts by role. Returns contacts + its role.
 */
export async function fetchContactsByRole(token: string, userId: number, role: UserRole): Promise<Contact[]> {
	const contacts = await contactSdk.getConnectedContacts(token, userId, {
		role,
	});
	return contacts.map(convertConnectedContactToContact);
}

/**
 * Returns therapists contact list,
 * but limited to their own organization.
 *
 * TODO: The return value is to support the temporary
 * hack of allowing treatment to register therapists
 * from different orgs.
 */
export async function fetchAllTherapistsFromOrganizations(
	token: string,
	authUser?: AuthUser,
	all?: boolean,
): Promise<{therapists: Contact[]; allTherapists: Contact[]}> {
	// Fetch therapist list, only get therapists that already accepted into orgs
	const therapists: Therapist[] = await fetchAllTherapists(token, authUser);

	// Converts therapists object into Contacts object
	const therapistsAsContacts = therapists.map((therapist) => {
		const contact: Contact = {
			id: therapist.id,
			role: UserRole.THERAPIST,
			bio: therapist.bio,
			firstName: therapist.firstName,
			gender: therapist.gender,
			hashId: therapist.hashId,
			image: therapist.image,
			lastName: therapist.lastName,
			location: therapist.location,
			type: ContactType.ConnectedContact,
			fullName: therapist.firstName + " " + therapist.lastName,
			birthDate: null,
			connectedSince: null,
			registerDate: therapist.organizations[0].dateJoined,
			email: null,
			organization: {
				id: therapist.organizations[0].id,
				name: therapist.organizations[0].name,
				logo: therapist.organizations[0].logo,
			},
		};
		return contact;
	});

	/**
	 * Filter out therapists from International-Solo organisations.
	 *
	 * TODO: Follow up to the backend team about filtering it in
	 * the endpoint level. In the meantime, the filtering is done
	 * on the front-end level.
	 */
	const therapistsAsContactsOfSameOrg = therapistsAsContacts.filter(
		(therapist) => therapist.organization.id !== AppConfig.SOLO_THERAPIST_ORG,
	);

	return {
		therapists: all ? therapistsAsContacts : therapistsAsContactsOfSameOrg,
		allTherapists: therapistsAsContacts,
	};
}

/**
 * Returns contact by userId
 *
 * The contact is fetched by checking if the contact exist in the localuser's network.
 * If it doesn't, portal will try to fetch it from User Organization API
 * More details: https://github.com/senseobservationsystems/goalie-backend/issues/1271
 */
export async function fetchContactById(token: string, authUser: AuthUser, contactId: number): Promise<Contact> {
	try {
		// Try to find contact from localUser's network
		const contact = await contactSdk.getContactById(token, authUser.id, contactId);
		if (!contact) {
			throw new NotFoundError();
		}

		return convertConnectedContactToContact(contact);
	} catch (err) {
		const notFound: boolean = err instanceof NotFoundError;
		// IF the error is not a 404 not found error, means that there's something wrong with the BE.
		// Thus we just need to throw the error.
		if (!notFound) {
			throw err;
		}
	}
	// It's currently not possible to get contact with role `Therapist`
	// More details: https://github.com/senseobservationsystems/goalie-backend/issues/1271
	//
	// TL;DR : We need to fetch therapist from UserOrganization and find one by userId for now.
	const {therapists} = await fetchAllTherapistsFromOrganizations(token, authUser);
	const contact = therapists.find((therapist) => therapist.id === contactId);

	// Since BE couldn't find contact from both network members and user organization, we throw this error to report to sentry.
	if (!contact) {
		throw new NotFoundError("Contact not found!");
	}

	return contact;
}

/**
 * Retrieve incoming invitations of logged in user
 *
 * @param token
 * @returns
 */
export async function fetchIncomingInvitations(token: string): Promise<IncomingInvitation[]> {
	const rawInvitations = await contactSdk.getRequestedContacts(token);
	return rawInvitations.map(convertRequestedContactToIncomingInvitation);
}

function convertConnectedContactToContact(connectedContact: ConnectedContact): Contact {
	return {
		...connectedContact,
		fullName: connectedContact.firstName + " " + connectedContact.lastName,
	};
}

function convertRequestedContactToIncomingInvitation(requestedContact: RequestedContact): IncomingInvitation {
	return {
		...requestedContact,
		fullName: requestedContact.firstName + " " + requestedContact.lastName,
	};
}
