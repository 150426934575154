import {call, select, take, race, delay} from "redux-saga/effects";
import {OnlineUsersType} from "redux/onlineUsers/OnlineUsersAction";
import {getOnlineUsersState} from "redux/onlineUsers/OnlineUsersSelectors";
import {VideoWindowData} from "services/chat/video/VideoWindowUserlistUpdateData";
import {getType} from "typesafe-actions";
import {UserlistUtils} from "utils/UserlistUtils";
import {authActions, getAuthUser} from "../../../auth/redux";
import {chatPresenceActions} from "../../../chat/redux/ChatPresenceAction";
import {contactActions} from "../../../contacts/redux/contactAction";
import {createSendMessage} from "../../../IWC/IWC";
import {WinMsgTypes} from "../../../IWC/WinMsg";

/**
 * List of action types that can change data needed for updating `VideoWindowData` from redux state
 */
const callWindowDataActions = [
	getType(contactActions.addContacts),
	getType(contactActions.addContact),
	getType(authActions.updateAuthUser),
	getType(chatPresenceActions.updateOwnPresence),
	OnlineUsersType,
];

/**
 * Sync latest `VideoWindowData` data between main window and call window
 */
export function* updateCallWindowData(callWindow: Window) {
	yield call(sendCallWindowData, callWindow);
	while (true) {
		// Only take actions that can change `VideoWindowData` data
		yield take(callWindowDataActions);

		// Wait for 2 seconds before sending the data to call window to prevent spamming
		const {latestAction} = yield race({
			debounced: delay(2000),
			latestAction: take(callWindowDataActions),
		});

		if (latestAction) {
			continue;
		}

		// Send contactlist, authUser, online presence
		yield call(sendCallWindowData, callWindow);
	}
}

function* sendCallWindowData(callWindow: Window) {
	const sendIWCMessage = yield call(createSendMessage, callWindow);
	const contacts = yield select(UserlistUtils.userlistSorting);
	const authUser = yield select(getAuthUser);
	const onlineUsers = yield select(getOnlineUsersState);

	const data: VideoWindowData = {
		contacts,
		onlineUsers,
		authUser,
	};
	yield call(sendIWCMessage, {type: WinMsgTypes.USERLIST_UPDATE, data});
}
