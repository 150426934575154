import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";

export function getInvalidAttemptErrorMessage(attemptLeft: number) {
	return localization.formatMessage(strTranslation.TWO_FA.error.incorrect_code_attempt_count.message, {
		count: attemptLeft,
	});
}
export function getIncorrectCodeErrorMessage() {
	return localization.formatMessage(strTranslation.TWO_FA.error.incorrect_code.message);
}
