import {Action} from "redux";

import {ThunkAction} from "redux-thunk";
import {AppState} from "../AppState";
import {CallStatus, UserRole, TerminationReason} from "@sense-os/goalie-js/dist";
import {meetingNotesActions} from "../../../meetingNotes/redux/meetingNotesActions";
import {getContactById} from "../../../contacts/redux/contactSelectors";
import {Contact} from "../../../contacts/contactTypes";
import {ActiveCall} from "services/chat/video/ActiveCall";

import {DeviceInformation} from "@sense-os/goalie-js/dist/chat/call/type";
import {callActions} from "../../../call/redux/callActions";

export enum VideoCallActions {
	ACCEPT = "VC/lu_accept", // lu -- local user
	REJECT = "VC/lu_reject", // lu -- local user
	INCOMING_CALL = "VC/incCall",
}

/** Action from VideoCallService to the pick-up dialog */
export interface VideoCallAction extends Action {
	callerUserIds?: number[];
	userId?: number;
	activeCall?: ActiveCall;
	time?: number;
	status?: CallStatus;
	deviceInformation?: DeviceInformation;
}

/** Action from the pick-up dialog to VideoCallService */
export interface VideoCallReAction extends Action {
	isVideo: boolean;
	accepted: boolean;
}

type VideoCallThunkAction = ThunkAction<void, AppState, any, VideoCallAction | VideoCallReAction>;

export class VideoCallActionCreators {
	public static incomingCall(callerUserIds: number[]): VideoCallAction {
		return {
			type: VideoCallActions.INCOMING_CALL,
			callerUserIds,
		};
	}

	/**
	 * Called after video call ends.
	 * Decides whether to open meeting notes or not.
	 *
	 * @param {number} counterpartId
	 * @param {TerminationReason} reason
	 */
	public static onCallSummarySent(counterpartId: number, reason: TerminationReason): VideoCallThunkAction {
		return (dispatch, getState) => {
			const openMeetingNotesForm: boolean =
				reason === TerminationReason.NormalHangUp ||
				reason === TerminationReason.NormalHangUpByInitiator ||
				reason === TerminationReason.NormalHangUpByRecipient ||
				reason === TerminationReason.AbnormalHangUp ||
				reason === TerminationReason.UnexpectedError;

			const counterpartContact: Contact = getContactById(getState(), counterpartId);
			const isCounterpartPatient: boolean =
				counterpartContact && counterpartContact.role && counterpartContact.role === UserRole.PATIENT;
			if (
				isCounterpartPatient &&
				// summary.duration >= AppConfig.MIN_MEETING_NOTES_DURATION_SECONDS &&
				openMeetingNotesForm
			) {
				dispatch(meetingNotesActions.openConfirmationDialog(counterpartId));
			}
		};
	}

	/**
	 * Create or update activeCall state
	 *
	 * @deprecated use callActions.createActiveCall instead!
	 *
	 * FIXME: Remove this once VideoCallService is refactored
	 */
	public static createActiveCall(activeCall: ActiveCall): VideoCallAction {
		return callActions.createActiveCall(activeCall);
	}

	/**
	 * user is invited to a call.
	 * Add user to participantMap
	 *
	 * @deprecated use callActions.userInitiateCall instead!
	 *
	 * FIXME: Remove this once VideoCallService is refactored
	 */
	public static userInitiateCall(
		userId: number,
		time: number,
		deviceInformation?: DeviceInformation,
	): VideoCallAction {
		return callActions.userInitiateCall(userId, time, deviceInformation);
	}

	/**
	 * @deprecated
	 */
	public static userJoinCall(userId: number, time: number, deviceInformation?: DeviceInformation): VideoCallAction {
		return callActions.userJoinCall(userId, time, deviceInformation);
	}

	/**
	 * @deprecated
	 */
	public static userLeaveCall(userId: number, time: number): VideoCallAction {
		return callActions.userLeaveCall(userId, time);
	}
}
