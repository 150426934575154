import moment from "moment";
import {useSelector} from "react-redux";

import {AppState} from "redux/AppState";
import {getUserLastLogin} from "../auth/helpers/authStorage";
import useAuthUser from "../auth/hooks/useAuthUser";
import {getDemoClientHashId} from "../contacts/redux/contactSelectors";
import {useCheckAuthUserAccess} from "../featureFlags/useAuthUserAccess";
import {PortalFeatures} from "../featureFlags/userFeatureAccess";

export const useProductFruits = () => {
	const authUser = useAuthUser();
	const userLastLogin = getUserLastLogin();
	const checkUserAccess = useCheckAuthUserAccess();
	const isProductFruitsAccessible = checkUserAccess(PortalFeatures.productFruits);
	const demoClientHashId = useSelector((state: AppState) => getDemoClientHashId(state, authUser?.account?.email));

	const userInfo = {
		username: authUser?.externalId,
		role: authUser?.role,
		signUpAt: moment(authUser?.account?.dateJoined).toISOString(),
		props: {
			organizationName: authUser?.organization?.name,
			// `isAcceptedToOrg` are using string because custom props doesn't support boolean data type
			isAcceptedToOrg: authUser?.organization?.isAccepted ? "true" : "false",
			lastSeenAt: userLastLogin,
			demoClientPublicId: demoClientHashId || "none",
		},
	};

	return {isProductFruitsAccessible, userInfo};
};
