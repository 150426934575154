import {OnlineUsersAction, OnlineUsersType, ResetUsersPresenceType} from "./OnlineUsersAction";
import {NumberMap} from "../../services/utils/Maps";
import {AvailabilityStatus} from "@sense-os/goalie-js/dist";
import produce from "immer";

/** A map: [user ID] => online availability */
const initialState: NumberMap<AvailabilityStatus> = {};

export const OnlineUsersReducer = produce(
	(state: NumberMap<AvailabilityStatus>, action: OnlineUsersAction): NumberMap<AvailabilityStatus> => {
		switch (action.type) {
			case OnlineUsersType: {
				if (state[action.userId]) {
					state[action.userId].availability = action.statusDescriptor.availability;
					state[action.userId].lastAvailable = action.statusDescriptor.lastAvailable;
					state[action.userId].status = action.statusDescriptor.status;
				} else {
					state[action.userId] = action.statusDescriptor;
				}
				break;
			}

			case ResetUsersPresenceType:
				return initialState;
		}
	},
	initialState,
);
