import {TrackerCategory as SDKTrackerCategory, Tracker as SDKTracker, Measure as SDKMeasure} from "@sense-os/goalie-js";
import {
	TrackerCategory,
	Tracker,
	TrackerMeasure,
	CustomTrackerSensor,
	TrackerDatum,
	MeasureValuesMap,
} from "../customTrackerTypes";
import localization from "../../localization/Localization";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import featureFlags from "../../featureFlags/FeatureFlags";

export const localiseTextFromBE = (txt: string) => {
	return localization.formatMessage(`ZZ_CUSTOM_TRACKER.${txt}`) || "";
};

export function transformSDKTrackerCategory(category: SDKTrackerCategory): TrackerCategory {
	return {
		category: localiseTextFromBE(category.category),
		id: category.category,
		trackers: category.trackers.map(transformSDKTracker),
	};
}
export function transformSDKTracker(tracker: SDKTracker): Tracker {
	return {
		category: localiseTextFromBE(tracker.category),
		description: localiseTextFromBE(tracker.description),
		id: tracker.id,
		measures: tracker.measures.map(transformSDKMeasure),
		tags: tracker.tags.map((tag) => localiseTextFromBE(tag)),
		trackerName: localiseTextFromBE(tracker.trackerName),
		sensorName: tracker.sensorName,
		scheduleType: tracker.sensorName,
		iconName: tracker.sensorName,
	};
}
export function transformSDKMeasure(measure: SDKMeasure): TrackerMeasure {
	return {
		description: localiseTextFromBE(measure.description),
		iconName: measure.iconName,
		name: localiseTextFromBE(measure.name),
	};
}

/**
 * Returns `value` as string if `value` is an integer.
 * otherwise rounds to 1 decimal.
 */
export function numberToString(value: number, decimal: number = 1): string {
	if (Number.isInteger(value)) {
		return value.toString();
	}
	// Cast to number to prevent "1.0" to be returned.
	// e.g (1.0005).toFixed(1) will return "1.0",
	// by using `Number(..)`, "1.0" will be converted to "1"
	return Number(value.toFixed(decimal)).toString();
}

export function convertCustomTrackerSensorToTrackerData(customTracker: SensorDatum<CustomTrackerSensor>): TrackerDatum {
	if (!customTracker.value.measures) {
		return null;
	}
	return {
		sensorId: customTracker.id,
		date: customTracker.startTime,
		trackerName: customTracker.sensorName,
		values: getMeasureValuesFromCustomTrackerSensor(customTracker),
	};
}

function getMeasureValuesFromCustomTrackerSensor(customTracker: SensorDatum<CustomTrackerSensor>): MeasureValuesMap {
	if (!customTracker.value.measures) {
		return {};
	}

	return Object.entries(customTracker.value.measures).reduce((trackerValues: MeasureValuesMap, [key, value]) => {
		trackerValues[key] = value.sensorData.value;
		return trackerValues;
	}, {});
}

/**
 * Returns duration value in minute (divided by 60)
 */
export const getDurationInMinute = (duration: number) => {
	if (duration === null || duration === undefined) {
		return null;
	}
	return duration / 60;
};

/**
 * Get maximum and minimum values of given `yValues` + additional buffer.
 * These values will be used as yDomain on custom tracker chart
 *
 * @param yValues
 * @param buffer
 */
export function getYDomainWithBuffer(yValues: number[], buffer: number): [number, number] {
	const maxYValue = Math.max(...yValues) + buffer;
	const minYValue = Math.min(...yValues) - buffer;
	const max = Math.floor(maxYValue / buffer) * buffer;
	const min = Math.ceil(minYValue / buffer) * buffer;
	return [max, min < 0 ? 0 : min];
}

/**
 * Merge tracker order (list of trackerId in order) with UserTrackers
 *
 * @param trackerOrder
 * @param userTrackerIds
 */
export function mergeTrackerOrderWithUserTrackers(trackerOrder: number[], userTrackerIds: number[]): number[] {
	return [...trackerOrder, ...userTrackerIds].reduce((distinct, id) => {
		if (distinct.indexOf(id) === -1) {
			distinct.push(id);
		}
		return distinct;
	}, []);
}

/**
 * Returns `tracker_version` to be fetched from the backend.
 *
 * @see https://github.com/senseobservationsystems/goalie-js/issues/724
 */
export function getCustomTrackerVersion() {
	return featureFlags.customTrackerPart2 ? 2 : 1;
}
