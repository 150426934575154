import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {meetingNotesActions} from "../redux/meetingNotesActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {getMeetingNoteSensors} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {MeetingNote, SensorDatum} from "redux/tracking/TrackingTypes";
import {extractEventViewId} from "redux/tracking/TrackingHelper";

const log = createLogger("fetchMeetingNoteSaga");

export function* fetchMeetingNote(action: ActionType<typeof meetingNotesActions.fetchMeetingNote.request>) {
	const {userId, eventViewId} = action.payload;
	const {id} = extractEventViewId(eventViewId);
	const token = yield call(getSessionId);
	try {
		const meetingNotes: SensorDatum<MeetingNote>[] = yield apiCallSaga(
			getMeetingNoteSensors,
			token,
			userId,
			undefined,
			undefined,
			[id],
		);
		yield put(meetingNotesActions.fetchMeetingNote.success({meetingNoteData: meetingNotes?.[0], userId}));
	} catch (err) {
		log.captureException(err);
		yield put(meetingNotesActions.fetchMeetingNote.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(meetingNotesActions.fetchMeetingNote.request), fetchMeetingNote);
}
