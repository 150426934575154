import {takeEvery, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {toastActions} from "../../toaster/redux/toastAction";

import {therapySessionActions} from "../redux/therapySessionActions";
import {sessionScheduleMap} from "./../types";

function* handleUndoAction(action: ActionType<typeof therapySessionActions.undoEventAction>) {
	// Remove the toast with undo button
	yield put(toastActions.removeToast(action.payload.undoId));

	// Cancel creation task to prevent portal to send creation API request to BE
	const sessionTask = sessionScheduleMap.get(action.payload.undoId);
	if (sessionTask) {
		sessionTask.cancel();
		sessionScheduleMap.delete(action.payload.undoId);
	}
}

export default function* () {
	yield takeEvery(getType(therapySessionActions.undoEventAction), handleUndoAction);
}
