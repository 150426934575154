import {ForbiddenError, NotFoundError, UserRole} from "@sense-os/goalie-js";
import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {getSessionId} from "../../auth/helpers/authStorage";
import {getAuthUser} from "../../auth/redux";
import {whenLoggedIn} from "../../auth/sagas/helper";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {Path} from "../../ts/app/Path";
import {ChatAction} from "../../chat/redux/ChatAction";
import {chatPresenceActions} from "../../chat/redux/ChatPresenceAction";
import {whenOnline} from "../../connectionStatus/sagas/OnlineStatusSagaHelper";
import {history} from "../../ts/visual/App";
import Localization from "../../localization/Localization";

import contactSdk from "../contactSdk";
import {fetchContactById, fetchAllTherapistsFromOrganizations, fetchContactsByRole} from "../contactSdkHelper";
import {Contact} from "../contactTypes";
import {contactActions} from "../redux/contactAction";
import {getContactById, getAllContacts} from "../redux/contactSelectors";
import {toastActions} from "../../toaster/redux/toastAction";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {isUnauthorizedError} from "../../errorHandler/errorHandlerUtils";
import {AuthUser, AuthUserRole} from "../../auth/authTypes";
import {checkAuthUserAccess, PortalFeatures} from "../../featureFlags/userFeatureAccess";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("ContactSaga", SentryTags.Contact);

/**
 * Remove contact by userId
 *
 * @param {number} userId
 */
function* removeContact(action: ActionType<typeof contactActions.removeContact.request>) {
	const token: string = getSessionId();
	const authUser = yield select(getAuthUser);

	// Try to get contact data in `contacts` state
	const {userId} = action.payload;
	const contact: Contact = yield select(getContactById, userId);

	// Abort the removing process if contact data is not exist
	if (!contact) {
		const msg: string = Localization.formatMessage(
			strTranslation.USER_PROFILE.client_profile.client_management.cant_disconnect.toast,
			{
				contactId: userId,
			},
		);
		yield put(toastActions.addToast({message: msg, type: "error"}));
		yield put(contactActions.removeContact.failure({userId}));
		return;
	}

	const name: string = contact.fullName;

	try {
		yield call(contactSdk.removeContactFromUserContact, token, authUser.id, userId);

		const successMessage: string = Localization.formatMessage(strTranslation.DASHBOARD.disconnect.toast.info, {
			name,
		});
		yield put(toastActions.addToast({message: successMessage, type: "info"}));

		yield put(contactActions.removeContact.success({userId}));

		// Redirect user to dashboard
		history.push(Path.APP);
	} catch (error) {
		yield put(contactActions.removeContact.failure({userId}));

		// Show error toast
		const errorMessage = Localization.formatMessage(strTranslation.DASHBOARD.disconnect.toast.error, {name});
		yield put(toastActions.addToast({message: errorMessage, type: "error"}));

		if (error instanceof NotFoundError) {
			// Client is not found, probably already disconnected in another browser or browser tab
			yield put(contactActions.removeContact.success({userId}));
			history.push(Path.APP);
			return;
		}

		// Must be an internal server error. Log
		log.captureException(error, {message: "Failed to remove contact", hashId: contact.hashId});
	}
}

/**
 * Fetches one contact from the BE by a given contact ID
 * The contact is then injected into the userlist
 * @param contactId
 */
function* loadContactById(action: ActionType<typeof contactActions.loadContactById>) {
	const token: string = getSessionId();
	const authUser: AuthUser = yield select(getAuthUser);
	const {userId} = action.payload;

	try {
		// fetch the contact with the given ID from the BE
		const contact: Contact = yield apiCallSaga(fetchContactById, token, authUser, userId);
		log.debug("loadContactById(),  contact:", contact);

		yield put(chatPresenceActions.queryOtherUserPresence(contact.id));
		yield put(ChatAction.loadChat(contact.id));

		yield put(contactActions.addContact(contact));
	} catch (error) {
		if (isUnauthorizedError(error)) {
			return;
		}

		if (error instanceof NotFoundError || error instanceof ForbiddenError) {
			// Remove the contact from redux state just in case there is one.
			yield put(contactActions.removeContact.success({userId}));
			return;
		}
		yield put(
			toastActions.addToast({message: strTranslation.INVITATIONS.fetch_contact.toast.error, type: "error"}),
		);
		log.captureException(error, {message: "Unable to load contact by id"});
	}
}

/**
 * This will load all user contacts (Therapists and Patients) and store them in
 * `contacts.contactsMap` state
 */
export function* loadContacts() {
	try {
		const authUser: AuthUser = yield select(getAuthUser);

		if (authUser.role === AuthUserRole.CLIENT) {
			// Clients don't need to see other clients in the app, because there is no use case for that.
			// So here we only fetch therapists that are connected to the client's network
			const fetchTherapistsFn = (token: string) => fetchContactsByRole(token, authUser.id, UserRole.THERAPIST);
			yield call(loadContactsAndLoadChat, fetchTherapistsFn);
		} else {
			// Ending up in the `else` block means that the user is pending or was accepted into organization.

			const hasPermissionToGetOtherTherapistsContact = checkAuthUserAccess(authUser)(
				PortalFeatures.fetchingOtherTherapistsContact,
			);

			const hasPermissionToGetTherapistsDiffOrg = checkAuthUserAccess(authUser)(
				PortalFeatures.fetchingTherapistDiffOrgs,
			);

			const fetchClientsFn = (token: string) => fetchContactsByRole(token, authUser.id, UserRole.PATIENT);
			const fetchTherapistsFn = (token: string) =>
				fetchAllTherapistsFromOrganizations(token, authUser, hasPermissionToGetTherapistsDiffOrg);

			yield all(
				[
					call(loadContactsAndLoadChat, fetchClientsFn),
					hasPermissionToGetOtherTherapistsContact &&
						call(loadTherapistsContactsAndLoadChat, fetchTherapistsFn),
				].filter(Boolean),
			);
		}

		yield put(contactActions.loadContacts.success());
	} catch (err) {
		yield put(contactActions.loadContacts.failure({error: err}));
		log.captureException(err, {message: "Unable to load contacts"});
	}
}

/**
 * This function will execute `fetchFn` to get `Contact[]` data from the function.
 * If the execution succeeded, the contact data will be used to load chat data.
 */
function* loadContactsAndLoadChat(fetchFn: (token: string) => Promise<Contact[]>) {
	try {
		const token: string = yield call(getSessionId);
		const currentAllContacts: Contact[] = yield select(getAllContacts);
		const contacts: Contact[] = yield apiCallSaga(fetchFn, token);

		yield put(contactActions.addContacts(contacts));

		const newContactIds = contacts
			.filter((newContact) => !currentAllContacts.some((currContact) => currContact.id === newContact.id))
			.map((contact) => contact.id);

		yield put(ChatAction.bulkLoadChatHistoriesFromBE(newContactIds));

		return contacts;
	} catch (err) {
		log.captureException(err);
		return [];
	}
}

/**
 * This function will execute `fetchFn` to get `Contact[]` data from the function.
 * If the execution succeeded, the contact data will be used to load chat data.
 */
function* loadTherapistsContactsAndLoadChat(
	fetchFn: (token: string) => Promise<{therapists: Contact[]; allTherapists: Contact[]}>,
) {
	try {
		const token: string = yield call(getSessionId);
		const currentAllContacts: Contact[] = yield select(getAllContacts);
		const {therapists, allTherapists}: {therapists: Contact[]; allTherapists: Contact[]} = yield apiCallSaga(
			fetchFn,
			token,
		);

		yield put(contactActions.addContacts(therapists));
		yield put(contactActions.addTherapistsContacts(allTherapists));

		const newContactIds = therapists
			.filter((newContact) => !currentAllContacts.some((currContact) => currContact.id === newContact.id))
			.map((contact) => contact.id);

		yield put(ChatAction.bulkLoadChatHistoriesFromBE(newContactIds));

		return therapists;
	} catch (err) {
		log.captureException(err);
		return [];
	}
}

function* contactSaga() {
	yield takeEvery(getType(contactActions.removeContact.request), removeContact);
	yield takeEvery(getType(contactActions.loadContactById), loadContactById);
	yield takeEvery(getType(contactActions.loadContacts.request), loadContacts);

	yield fork(
		whenOnline(function* () {
			yield put(contactActions.loadContacts.request());
		}),
	);
}

export default function* () {
	yield fork(whenLoggedIn(contactSaga));
}
