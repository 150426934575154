import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {getDailyPlannerItems} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {TherapySessionEventView} from "redux/tracking/TrackingTypes";
import {extractEventViewId} from "redux/tracking/TrackingHelper";
import {transformDailyPlannerIntoEventViewData} from "../../clientActivity/helpers/dailyPlannerActivityHelpers";
import {therapySessionActions} from "../redux/therapySessionActions";
import {SentryTags} from "../../errorHandler/createSentryReport";

const log = createLogger("fetchTherapySession", SentryTags.TherapySession);

export function* fetchTherapySession(action: ActionType<typeof therapySessionActions.fetchTherapySession.request>) {
	const {userId, eventViewId} = action.payload;
	const {occurrenceTime} = extractEventViewId(eventViewId);
	const token = yield call(getSessionId);
	try {
		const dailyPlanners = yield apiCallSaga(getDailyPlannerItems, token, userId, occurrenceTime, occurrenceTime);
		const dailyPlannerActivities = dailyPlanners.map(transformDailyPlannerIntoEventViewData).filter(Boolean);
		const result: TherapySessionEventView = dailyPlannerActivities.find(
			(data: TherapySessionEventView) => data.id === eventViewId,
		);
		yield put(therapySessionActions.fetchTherapySession.success({data: result}));
	} catch (err) {
		log.captureException(err);
		yield put(therapySessionActions.fetchTherapySession.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(therapySessionActions.fetchTherapySession.request), fetchTherapySession);
}
