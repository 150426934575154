import {
	TimeEntriesOwnerOfTreatment,
	Treatment as SdkTreatment,
	TreatmentType,
	TreatmentComplaint,
	TreatmentPersonnel,
	TreatmentPersonnelRole,
	TreatmentTerminatingReason,
} from "@sense-os/goalie-js";
import {LoadingState} from "../ts/constants/redux";

export {
	Treatment as SdkTreatment,
	TreatmentComplaint,
	TreatmentPersonnel,
	TreatmentType,
	TreatmentTerminatingReason,
} from "@sense-os/goalie-js";

export type Treatment = Omit<SdkTreatment, "patient"> & {
	patient: number;
	patientName?: string;
	treatmentTypes: TreatmentType[];
	clientComplaints: TreatmentComplaint[];
	personnels: TreatmentPersonnel[];
	clientData?: {
		value: Partial<ClientDetailsInTreatment>;
	};
};

export interface ClientDetailsInTreatment {
	firstName?: string;
	lastName?: string;
	birthDate?: string;
	area?: string;
	email?: string;
	gender?: string;
	psyqClientNumber?: string;
	solClientNumber?: string;
	solGdriveUrl?: string;
	solDSM5Category?: string;
	solZorgvraagtypering?: string;
	solBsnNumber?: string;
	solZorgtrajectNumber?: string;
	solBasicGGZProfile?: string;
	synaedaClientNumber?: string;
	ndCenterFirstName?: string;
	ndCenterLastName?: string;
	ndCenterBirthDate?: string;
	ndCenterEmployer?: string;
	ndCenterClientType?: string;
	ndCenterGdriveUrl?: string;
	ndCenterPhoneNumber?: string;
	ndCenterReferrer?: string;
	ndCenterResultaat?: string;
}

export type TreatmentPersonnelFromForm = {
	therapistId: number;
	role: TreatmentPersonnelRole;
};

// This is the treatment data that portal got from TreatmentForm
export type TreatmentFromForm = Omit<
	Treatment,
	"id" | "patient" | "treatmentTypes" | "clientComplaints" | "personnels"
> & {
	id?: number;
	treatmentTypes: string[];
	clientComplaints: string[];
	mainComplaint?: string;
	personnels: TreatmentPersonnelFromForm[];
};

export interface TreatmentStatusReduxState {
	treatmentTypesOptions: TreatmentType[];
	clientComplaintsOptions: TreatmentComplaint[];
	endTreatmentReasons: TreatmentTerminatingReason[];

	clientTreatmentsMap: {
		[clientId: number]: Treatment;
	};

	// This is a flag whether the form to create/edit treatment is opened or not.
	isTreatmentFormOpen: boolean;

	// This is a flag whether the form to end treatment is opened or not.
	isEndTreatmentFormOpen: boolean;

	// This is a flag whether the form to edit client personal details is opened or not.
	isClientDetailsFormOpen: boolean;

	// This contain the treatment object that will serve as
	// the initial value of the `TreatmentForm` when it's opened.
	editedTreatment?: Treatment;

	// Portal have two loadings state when fetching Treatment data.
	// One to mark the state when portal fetch all treatments, and the other one
	// to mark the state when portal fetch individual treatment.
	fetchingTreatmentMap: {
		[clientId: number]: LoadingState;
	};
	fetchingAllTreatmentsState: LoadingState;

	// Loading state for whenever portal create, edit, or ends a treatment.
	// This is so the form modal isn't closed before we successfully mutate the treatment.
	mutatingTreatmentLoadingState: LoadingState;

	// Loading state for whenever portal fetch any end-treatment-blocking unconfirmed time entries,
	// that's triggered whenever end treatment dialog is opened.
	fetchingEndTreatmentBlocker: LoadingState;

	// Contain the details of all owners of relevant time entries of any treatments.
	timeEntriesOwnerMap: {
		[treatmentId: number]: TimeEntriesOwnerOfTreatment[];
	};

	/**
	 * Loading state of when portal loads all treatment personnel.
	 * TODO: This should be a temporary state to support this issue: https://github.com/senseobservationsystems/web-getgoalie/issues/4258
	 */
	fetchingAllTreatmentPersonnelLoadingState: LoadingState;
}

// Note that the value of this enum is used also as translation keys.
export enum TreatmentProgress {
	NOT_IN_TREATMENT_YET = "not_in_treatment",
	PLANNED = "planned",
	IN_PROGRESS = "in_progress",
	COMPLETED = "completed",
	DROPOUT = "dropout",
	INSUFFICIENT = "insufficient",
	STOPPED_ON_CLIENT = "stopped_on_client",
	STOPPED_ON_THERAPIST = "stopped_on_therapist",
}

// Here're the list of treatment progress that signify that
// a treatment is already ends.
export const treatmentAlreadyEndsProgress = [
	TreatmentProgress.COMPLETED,
	TreatmentProgress.DROPOUT,
	TreatmentProgress.INSUFFICIENT,
	TreatmentProgress.STOPPED_ON_CLIENT,
	TreatmentProgress.STOPPED_ON_THERAPIST,
];

// Note that the value of this enum is used also as translation keys.
export enum EndTreatmentReason {
	COMPLETED = "completed",
	DROPOUT = "dropout",
	INSUFFICIENT = "insufficient",
	STOPPED_ON_CLIENT = "stopped_on_client",
	STOPPED_ON_THERAPIST = "stopped_on_therapist",
}

export interface EndTreatmentFormValue {
	endDate: Date;
	endTreatmentReason: TreatmentTerminatingReason;
	shouldSetReminder: boolean;
	reminderDate: Date;
}
