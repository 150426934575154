import {takeEvery, call, select, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {SensorData} from "@sense-os/goalie-js/dist";
import {MeetingNote} from "@sense-os/sensor-schema/goalie-2-ts/meeting_note";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {DISC} from "../../ts/IoC/DISC";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getAuthUserId} from "../../auth/helpers/authStorage";
import {transformFormValuesIntoSensorData} from "../helpers/meetingNotesHelpers";
import {toastActions} from "../../toaster/redux/toastAction";
import {meetingNotesActions} from "../redux/meetingNotesActions";
import {getFetchMeetingNoteData, getMeetingNoteEventId} from "../redux/meetingNotesSelector";
import {SensorDatum} from "../../ts/redux/tracking/TrackingTypes";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("meetingNotesSaga", SentryTags.MeetingNotes);

function* saveMeetingNotes(action: ActionType<typeof meetingNotesActions.saveMeetingNotes.request>) {
	const {userId, formValues} = action.payload;
	const authUserId: number = yield call(getAuthUserId);

	const eventViewId: string = yield select(getMeetingNoteEventId);
	const isEditing: boolean = !!eventViewId;

	const locKeyPrefixSuccess: string = isEditing
		? strTranslation.MEETING_NOTES.edit_meeting_note.alert.success
		: strTranslation.MEETING_NOTES.add_meeting_note.alert.success;

	const locKeyPrefixFail: string = isEditing
		? strTranslation.MEETING_NOTES.edit_meeting_note.alert.fail
		: strTranslation.MEETING_NOTES.add_meeting_note.alert.fail;

	try {
		const sensorData: SensorData<MeetingNote> = yield call(
			transformFormValuesIntoSensorData,
			authUserId,
			formValues,
		);
		let eventData: SensorDatum<MeetingNote>;

		if (isEditing) {
			eventData = yield select((state) => getFetchMeetingNoteData(state));

			// Restore event creator
			sensorData.value.creatorId = eventData.value.creatorId;
		}

		yield apiCallSaga(DISC.getTrackingService().saveSensorData, sensorData, userId, eventData && eventData.id);

		yield put(toastActions.addToast({message: locKeyPrefixSuccess, type: "info"}));
		yield put(meetingNotesActions.saveMeetingNotes.success({userId: userId}));
		yield put(meetingNotesActions.closeForm());
	} catch (err) {
		log.captureException(err);
		yield put(toastActions.addToast({message: locKeyPrefixFail, type: "error"}));
		yield put(meetingNotesActions.saveMeetingNotes.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(meetingNotesActions.saveMeetingNotes.request), saveMeetingNotes);
}
