import {Call, CallContact, CallSDK, CallType} from "@sense-os/goalie-js";
import {call, put, race, select, takeEvery, take} from "redux-saga/effects";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {CallDirection} from "services/chat/video/CallDirection";
import {ActionType, getType} from "typesafe-actions";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import chatSDK from "../../chat/sdk";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import twilioSDK from "../../twilio/twilioSDK";
import {getFullName} from "../../userProfile/helpers/profileHelpers";
import {callActions} from "../redux/callActions";
import {canStartCall, getCallContactInfoByUserId} from "./callSagaHelpers";
import {OutgoingCallTypes} from "../callTypes";
import {getCallInfo} from "../../ts/redux/videoCall/VideoCallSelectors";
import {getConditionalEmdrSettings} from "../../userSettings/redux/userSettingsSelectors";
import featureFlags from "../../featureFlags/FeatureFlags";

const logger = createLogger("startCallSaga");

/**
 * Handler to start a new call
 *
 * @param action
 */
export function* handleOpenCallWindow(action: ActionType<typeof callActions.startCall>) {
	const {userId, isConferenceCall, isVideo} = action.payload;
	const authUser: AuthUser = yield select(getAuthUser);
	const callType = isVideo ? CallType.Video : CallType.Audio;

	const senderInfo: CallContact = yield call(getCallContactInfoByUserId, authUser.id);
	const recipientInfo: CallContact = yield call(getCallContactInfoByUserId, userId);

	// Check whether user is able to start a new call
	const ableToStartCall = yield call(canStartCall);
	if (!ableToStartCall) {
		yield put(
			toastActions.addToast({
				message: "CHAT.video.already_in_call.toast",
				type: "warning",
			}),
		);
		return;
	}

	yield put(callActions.setCallInfo({userId, callType, senderInfo, recipientInfo, isConferenceCall}));

	try {
		yield put(callActions.openCallWindow.request());

		const result = yield race({
			success: take(getType(callActions.openCallWindow.success)),
			failure: take(getType(callActions.openCallWindow.failure)),
		});

		if (result.failure) {
			logger.info("Call window not opened");
			// When call window is not opened, then do nothing here.
			return;
		}

		logger.info("Call window opened");
	} catch (err) {
		logger.captureException(err, {isConferenceCall, isVideo});
		yield put(callActions.closeCallWindow());
		yield put(callActions.resetCallInfo());
	}
}

export function* handleOutgoingCall(action: ActionType<typeof callActions.startOutgoingCallByType>) {
	const authUser: AuthUser = yield select(getAuthUser);
	const isConditionalEmdrEnabled: boolean = yield select(getConditionalEmdrSettings);
	const {userId, callType, senderInfo, recipientInfo, isConferenceCall} = yield select(getCallInfo);

	const selectedCallType = action.payload.callType;
	// Set it as mdo call if selected call type is `mdo` or `isConferenceCall` is true
	const isMdoCall = selectedCallType === OutgoingCallTypes.MDO || isConferenceCall;
	// Only enable EMDR call if selected call type is `emdr` or conditional EMDR is enabled (but `select outgoing call` type is off)
	const isEmdrEnabled =
		selectedCallType === OutgoingCallTypes.EMDR || (!featureFlags.outgoingCallType && isConditionalEmdrEnabled);

	try {
		let callResponse: Call;
		// Initiate call
		if (isMdoCall) {
			callResponse = yield call(
				chatSDK.initiateTwilioConferenceCall,
				userId,
				callType,
				senderInfo,
				recipientInfo,
			);
		} else {
			callResponse = yield call(
				chatSDK.initiateCall,
				userId,
				callType,
				senderInfo,
				recipientInfo,
				[CallSDK.Twilio],
				undefined,
				undefined,
				isEmdrEnabled,
			);
		}

		// Get twilio access token from backend
		// so that user can enter twilio call room
		const accessToken = yield call(twilioSDK.createVideoAccessToken, authUser.token, callResponse.roomId, {
			identity: senderInfo.publicId,
		});

		const initiatedTime = Date.now();

		const activeCall: ActiveCall = {
			roomId: callResponse.roomId,
			accessToken,
			initiatorUserId: authUser.id,
			ongoingCounterPartId: userId,
			isConferenceCall: isMdoCall,
			isEmdrEnabled,
			type: callType,
			direction: CallDirection.OUTGOING,
			participantMap: {
				[userId]: {
					...recipientInfo,
					initiatedTime,
				},
				[authUser.id]: {
					...senderInfo,
					initiatedTime,
					joinedTime: initiatedTime,
				},
			},
		};

		yield put(callActions.createActiveCall(activeCall));

		// yield fork(DISC.getVideoCallService().initCallWindow);
		yield put(callActions.startTimeout(userId));

		yield put(
			toastActions.addToast({
				message: "CHAT.video.calling.toast",
				type: "info",
				localizationPayload: {name: getFullName(recipientInfo)},
			}),
		);
	} catch (err) {
		logger.captureException(err);

		yield put(
			toastActions.addToast({
				message: "CHAT.video.call_cant_be_started.toast",
				type: "error",
			}),
		);

		// Close and reset call window when things went wrong
		// yield fork(DISC.getVideoCallService().closeVideoWindow);
		yield put(callActions.closeCallWindow());
	}
}

export default function* () {
	yield takeEvery(getType(callActions.startCall), handleOpenCallWindow);
	yield takeEvery(getType(callActions.startOutgoingCallByType), handleOutgoingCall);
}
