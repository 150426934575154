import {takeEvery} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {behaviorExprActions} from "../redux/behaviorExprActions";
import {deleteBehaviorExperimentById} from "./deleteBehaviorExperimentById";
import {getBehaviorExperimentById} from "./getBehaviorExperimentById";
import {saveBehaviorExperiment} from "./saveBehaviorExperiment";

export default function* behaviorExprSaga() {
	yield takeEvery(getType(behaviorExprActions.saveBehaviorExpr.request), saveBehaviorExperiment);
	yield takeEvery(getType(behaviorExprActions.getBehaviorExprById.request), getBehaviorExperimentById);
	yield takeEvery(getType(behaviorExprActions.deleteBehaviorExperiment.request), deleteBehaviorExperimentById);
}
