import {Action} from "redux";
import {AvailabilityStatus} from "@sense-os/goalie-js/dist";
import {AppState} from "../AppState";
import {ThunkAction} from "redux-thunk";
import {Dispatch} from "redux";

export const OnlineUsersType = "OnlineUsers/AvailabilityStatusUpdate";

export const ResetUsersPresenceType = "OnlineUsers/ResetUsersPresenceType";

export interface OnlineUsersAction extends Action {
	userId?: number;
	statusDescriptor?: AvailabilityStatus;
}

type OnlineUsersThunkAction = ThunkAction<void, AppState, any, OnlineUsersAction>;

/**
 * Actions, related to user's online status.
 */
export class OnlineUsersActionCreator {
	/**
	 * Reset all users availability, since SDK never returns the status of offline user
	 * @returns {OnlineUsersAction}
	 */
	public static ResetUsersPresence(): OnlineUsersThunkAction {
		return (dispatch: Dispatch<OnlineUsersAction>) => {
			const action: OnlineUsersAction = {
				type: ResetUsersPresenceType,
			};
			dispatch(action);
		};
	}
}
