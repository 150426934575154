import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {planActivityActions} from "../redux/planActivityActions";
import createLogger from "../../../logger/createLogger";
import {DailyPlannerItem, EventViewData} from "redux/tracking/TrackingTypes";
import {extractEventViewId} from "redux/tracking/TrackingHelper";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getDailyPlannerItems} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {transformDailyPlannerIntoEventViewData} from "../../../clientActivity/helpers/dailyPlannerActivityHelpers";
import {DISC} from "IoC/DISC";
import {UpdateEventOption} from "visual/feature/clientProfile/trackingData/events/UpdateEventOptionDialog";
import strTranslation from "../../../assets/lang/strings";
import {toastActions} from "../../../toaster/redux";

const log = createLogger("planActivityDialogSaga", SentryTags.PlanActivity);

export function* fetchPlannedActivity(action: ActionType<typeof planActivityActions.fetchPlannedActivity.request>) {
	const {userId, eventViewId} = action.payload;
	const {occurrenceTime} = extractEventViewId(eventViewId);
	const token: string = yield call(getSessionId);
	try {
		const response: DailyPlannerItem[] = yield apiCallSaga(
			getDailyPlannerItems,
			token,
			userId,
			occurrenceTime,
			occurrenceTime,
		);
		const dpActivities: EventViewData[] = response.map(transformDailyPlannerIntoEventViewData).filter(Boolean);
		const result: EventViewData = dpActivities.find((data: EventViewData) => data.id === eventViewId);
		yield put(planActivityActions.fetchPlannedActivity.success({userId, data: result}));
	} catch (err) {
		log.captureException(err);
		yield put(planActivityActions.fetchPlannedActivity.failure(err));
	}
}

export function* deletePlannedActivity(action: ActionType<typeof planActivityActions.deletePlannedActivity.request>) {
	const {eventViewId, deleteOption} = action.payload;
	const {occurrenceTime, id} = extractEventViewId(eventViewId);

	const plannedEventService = DISC.getPlannedEventService();
	const deleteFollowingEvents: boolean = deleteOption === UpdateEventOption.THIS_AND_FOLLOWING_EVENTS;

	yield put(toastActions.addToast({message: strTranslation.GRAPHS.delete_event.in_progress.toast, type: "info"}));

	try {
		yield apiCallSaga(plannedEventService.deleteRecurringPlannedEvent, id, occurrenceTime, deleteFollowingEvents);
		yield put(planActivityActions.deletePlannedActivity.success());
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.delete_event.success.toast, type: "success"}));
	} catch (err) {
		log.captureException(err);
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.delete_event.fail.toast, type: "error"}));

		yield put(planActivityActions.deletePlannedActivity.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(planActivityActions.fetchPlannedActivity.request), fetchPlannedActivity);
	yield takeEvery(getType(planActivityActions.deletePlannedActivity.request), deletePlannedActivity);
}
