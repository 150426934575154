import * as React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import classNames from "classnames";
import {Dialog, DialogContent, DialogTitle, makeStyles} from "@material-ui/core";

import {AppState} from "../../ts/redux/AppState";
import InternetConnectionIcon from "../../assets/icons/internet-connection-alert.svg";

interface InternetConnectionAlertProps {
	/** Is the alert open? */
	isOpen: boolean;
}

const useStyles = makeStyles({
	dialogTitle: {
		padding: "0 24px",
		"& h2": {fontSize: "20px", margin: "35px 0", color: "#000", fontWeight: "normal"},
	},
	dialogContent: {
		padding: "0 24px",
	},
	dialogContentText: {
		fontSize: "14px",
		color: "#000",
	},
	dialogContentIcon: {
		margin: "48px auto",
		display: "block",
	},
});

/**
 * A pop-up that tells the user that the internet connection is gone.
 */
const InternetConnectionAlert: React.FC<InternetConnectionAlertProps> = ({isOpen}) => {
	const classes = useStyles({});
	return (
		<Dialog open={isOpen} maxWidth="sm" disableEscapeKeyDown={false}>
			<DialogTitle className={classNames(classes.dialogTitle)}>
				<FormattedMessage id="BROWSER.offline.popup.header" />
			</DialogTitle>
			<DialogContent className={classNames(classes.dialogContent)}>
				<span className={classNames(classes.dialogContentText)}>
					<FormattedMessage id="BROWSER.offline.popup.text" />
				</span>
				<InternetConnectionIcon className={classNames(classes.dialogContentIcon)} />
			</DialogContent>
		</Dialog>
	);
};

const mapStateToProps = (appState: AppState) => ({
	isOpen: !appState.connectionStatus.isOnline, // open the offline alert when the status is not online ;-P
});

export default connect(mapStateToProps)(InternetConnectionAlert);
