import moment from "moment";
import {AnalyticsEvent} from "../../analytics/AnalyticsEventTypes";
import analyticsService from "../../analytics/AnalyticsService";
import {getSessionId} from "../../auth/helpers/authStorage";
import {FileItem} from "../fileSharingTypes";
import fileSharingSDK from "../sagas/fileSharingSDK";
import {fileChatToFileItemParser, isDocumentFile} from "./fileSharingHelper";

/**
 * Helper function to check whether a given url link is still available or not.
 */
const isUrlExpired = (fileUrl: string) => {
	const url = new URL(fileUrl);
	const date = url.searchParams.get("X-Goog-Date");
	const expires = url.searchParams.get("X-Goog-Expires");
	const dateExpired = moment(date).add(expires, "seconds");
	const now = moment();
	return dateExpired < now;
};

/**
 * Helper to get a file object with refreshed url if needed.
 */
export const refreshFileIfNeeded = async (userId: number, file: FileItem): Promise<FileItem> => {
	if (!isUrlExpired(file.url) && !isUrlExpired(file.thumbnailUrl)) return file;

	const {results} = await fileSharingSDK.getFilesChat(getSessionId(), userId, {
		ids: [file.id],
		type: [],
		page: 1,
		pageSize: 15,
	});

	file = fileChatToFileItemParser(results[0]);
	return file;
};

/**
 * Helper to get blob url of any given file object.
 */
const fetchBlob = async (userId: number, file: FileItem) => {
	if (!!file.blobUrl) return file.blobUrl;

	if (file.url) {
		const guaranteedValidFile = await refreshFileIfNeeded(userId, file);
		return await fileSharingSDK.getBlobUrl(getSessionId(), guaranteedValidFile?.url);
	}
};

/**
 * Helper to open an uploaded file in new tab.
 */
export const downloadFileInNewTab = async (userId: number, file: FileItem) => {
	analyticsService.trackEvent(
		isDocumentFile(file) ? AnalyticsEvent.OPEN_PDF_IN_NEW_TAB : AnalyticsEvent.OPEN_IMAGE_IN_NEW_TAB,
	);

	const blobUrl = await fetchBlob(userId, file);
	if (!!blobUrl) window.open(blobUrl);
	return;
};

/**
 * Helper function to trigger downloading the file by way of a DOM hack.
 */
export const downloadFileViaDOM = async (userId: number, file: FileItem) => {
	analyticsService.trackEvent(isDocumentFile(file) ? AnalyticsEvent.DOWNLOAD_PDF : AnalyticsEvent.DOWNLOAD_IMAGE);

	const blobUrl = await fetchBlob(userId, file);
	if (!!blobUrl) {
		const tag = document.createElement("a");
		tag.href = blobUrl;
		tag.download = file.fileName;
		document.body.appendChild(tag);
		tag.click();
		document.body.removeChild(tag);
	}

	return;
};
