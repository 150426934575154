import {isMobile} from "react-device-detect";
import {warningBarActions} from "../redux";
import InfoIcon from "@material-ui/icons/Info";
import CalendarIcon from "@material-ui/icons/DateRange";
import {fork, put, takeEvery, select} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {whenBootstrapped} from "../../bootstrap/sagas/whenBootstrappedSaga";
import featureFlags from "../../featureFlags/FeatureFlags";
import {isEmailVerified} from "../../dashboard/redux/DashboardSelectors";
import {authActions} from "../../auth/redux/authActions";
import loc from "../../localization/Localization";
import {history} from "visual/App";
import {Path} from "../../ts/app/Path";
import {isAcceptedToOrganization} from "../../auth/redux/authSelectors";
import featureFlag from "../../featureFlags/FeatureFlags";
import strTranslation from "../../assets/lang/strings";

function* validateBrowserSaga() {
	if (isMobile && !featureFlags.portalForTablet) {
		yield put(
			warningBarActions.addWarningBar({
				icon: InfoIcon,
				key: "unsupported-device",
				message: "BROWSER.warn.unsupported_device",
			}),
		);
	}
}

function* emailVerificationChecker() {
	const isVerified = yield select(isEmailVerified);
	const isTherapistAccepted = yield select(isAcceptedToOrganization);

	// Only show add the bar when therapist is Not verified and Accepted to an organization
	if (!isVerified && isTherapistAccepted && featureFlag.therapistEmailVerification) {
		yield put(
			warningBarActions.addWarningBar({
				icon: CalendarIcon,
				key: "email-verification",
				message: strTranslation.DASHBOARD.email_verification.warning_bar.text,
				action: (
					<button
						style={{padding: "5px 10px", cursor: "pointer", border: "2px solid white", borderRadius: "4px"}}
						onClick={() => history.push(Path.APP_THERAPIST_EMAIL_VERIFICATION)}
					>
						{loc.formatMessage(strTranslation.DASHBOARD.email_verification.warning_bar.text_button)}
					</button>
				),
			}),
		);
	}
}

export default function* warningBarSaga() {
	yield fork(whenBootstrapped(validateBrowserSaga));
	yield takeEvery(getType(authActions.login.success), emailVerificationChecker);
}
