import {AppState} from "redux/AppState";
import {FIRST_PAGE_NUMBER, UserFeed, FeedType} from "../clientsFeedTypes";
import {getFirstItemNumber, getLastItemNumber} from "../helpers/clientsFeedHelpers";
import {EventViewType} from "redux/tracking/TrackingTypes";
import {createEventViewId, createEventViewDataLink} from "redux/tracking/TrackingHelper";
import {Contact} from "../../contacts/contactTypes";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {PureUserUtils} from "utils/PureUserUtils";

/** Extract `clientsFeed` state from AppState */
const getClientsFeedState = (state: AppState) => state.clientsFeed;

/** Get feedsMap object from `clientsFeed` state */
const getFeedsMap = (state: AppState) => getClientsFeedState(state).feedsMap;

/** get `loadingFeeds` state from `clientsFeed` state */
export const getLoadingFeeds = (state: AppState) => getClientsFeedState(state).loadingFeeds;

/** get `activeLabel` state from `clientsFeed` state */
export const getActiveLabel = (state: AppState) => getClientsFeedState(state).activeLabel;

/** get `currentPage` state from `clientsFeed` state */
export const getCurrentPage = (state: AppState) => getClientsFeedState(state).currentPage;

/** get `maxItemPerPage` state from `clientsFeed` state */
export const getMaxItemPerPage = (state: AppState) => getClientsFeedState(state).maxItemPerPage;

/** get `hourRange` state from `clientsFeed` state */
export const getHourRange = (state: AppState) => getClientsFeedState(state).hourRange;

/** returns all feeds from `clientsFeed` state as array */
export const getAllFeeds = (state: AppState): UserFeed[] => {
	const feedsMap = getFeedsMap(state);
	return Object.keys(feedsMap).map((k: string) => {
		const feed = feedsMap[k];
		// FIXME: See if backend able to provides profile image
		const ownerContactData: Contact = feed.patient ? getContactById(state, feed.patient.id) : null;
		const creatorContactData: Contact = feed.createdBy ? getContactById(state, feed.createdBy.id) : null;
		return {
			...feed,
			patient: {
				...feed.patient,
				image: (ownerContactData && ownerContactData.image) || feed.patient.image,
			},
			ownerLink: ownerContactData ? getProfileLink(ownerContactData) : null,
			creatorLink: creatorContactData ? getProfileLink(creatorContactData) : null,
			sourceLink: ownerContactData ? getSourceLink(feed, ownerContactData) : null,
		};
	});
};

/**
 * Return client profile link
 *
 * @param {Contact} contactData
 */
function getProfileLink(contactData: Contact): string {
	if (!contactData) {
		return null;
	}
	return PureUserUtils.getProfileLink(contactData.hashId, contactData.role);
}

/**
 * Return event view source link.
 *
 * @param {UserFeed} source
 */
function getSourceLink(feed: UserFeed, clientData: Contact): string {
	let eventId: string = "";
	switch (feed.feedType) {
		case FeedType.PLANNED_EVENT: {
			eventId = createEventViewId(EventViewType.PLANNED_EVENT_SENSOR, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.DIARY_ENTRY: {
			eventId = createEventViewId(EventViewType.DIARY_ENTRY_SENSOR, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.MEETING_NOTE: {
			eventId = createEventViewId(EventViewType.MEETING_NOTE_SENSOR, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.THOUGHT_RECORD: {
			eventId = createEventViewId(EventViewType.THOUGHT_RECORDS_SENSOR, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.RECURRING_PLANNED_EVENT: {
			eventId = createEventViewId(EventViewType.RECURRING_PLANNED_EVENT, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.PSYCHO_EDUCATION: {
			eventId = createEventViewId(EventViewType.PSYCHO_EDUCATION, feed.sourceId, feed.orderDate);
			break;
		}
		case FeedType.BEHAVIOR_EXPERIMENT: {
			eventId = createEventViewId(EventViewType.BEHAVIOR_EXPERIMENT, feed.sourceId, feed.createdAt);
			break;
		}
		case FeedType.THERAPY_SESSION: {
			eventId = createEventViewId(EventViewType.THERAPY_SESSION_SENSOR, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.OMQ: {
			eventId = createEventViewId(EventViewType.OMQ_SENSOR, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.SMQ: {
			eventId = createEventViewId(EventViewType.SMQ_SENSOR, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		case FeedType.CHECK_IN_FEELING: {
			eventId = createEventViewId(EventViewType.CHECK_IN_FEELING, feed.sourceId, feed.occurrenceStartTime);
			break;
		}
		default:
			return null;
	}

	return createEventViewDataLink(clientData.hashId, eventId);
}

/** returns total filtered feeds */
export const getTotalFilteredFeeds = (state: AppState) => {
	const allFeeds = getAllFeeds(state);
	return allFeeds.length;
};

/** Returns all feeds from `clientsFeed` state as array, sliced by page settings, and sorted by date */
export const getFeeds = (state: AppState): UserFeed[] => {
	const allFeeds = getAllFeeds(state),
		currentPage = getCurrentPage(state),
		maxItemPerPage = getMaxItemPerPage(state);

	const firstItemNumber: number = getFirstItemNumber(currentPage, maxItemPerPage),
		lastItemNumber: number = getLastItemNumber(currentPage, maxItemPerPage);

	// minus `1` because `firstItemNumber` always start with `1`.
	// `slice` starts with `0`
	return allFeeds.slice(firstItemNumber - FIRST_PAGE_NUMBER, lastItemNumber);
};
