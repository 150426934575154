import {MeetingNote, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {MeetingNoteFormValues} from "../meetingNotesTypes";

export const meetingNotesActions = {
	//
	// Meeting note form dialog
	//
	openForm: createAction("meetingNotesActions/openForm", (userId: number, eventViewId?: string) => ({
		userId,
		eventViewId,
	}))(),
	closeForm: createAction("meetingNotesActions/closeForm")(),
	resetForm: createAction("meetingNotesActions/resetForm")(),

	//
	// Confirmation dialog
	//
	openConfirmationDialog: createAction("meetingNotesActions/openConfirmationDialog", (userId: number) => ({
		userId,
	}))(),
	closeConfirmationDialog: createAction("meetingNotesActions/closeConfirmationDialog")(),

	//
	// Save form values actions
	//
	saveFormValues: createAction(
		"meetingNotesActions/saveFormValues",
		(userId: number, formValues?: MeetingNoteFormValues) => ({userId, formValues}),
	)(),

	//
	// Save meeting note actions
	//
	saveMeetingNotes: createAsyncAction(
		"meetingNotesActions/saveMeetingNotes",
		"meetingNotesActions/saveMeetingNotesSuccess",
		"meetingNotesActions/saveMeetingNotesFail",
	)<{userId: number; formValues: MeetingNoteFormValues}, {userId: number}, Error>(),
	//
	// Fetch meeting note actions
	//
	fetchMeetingNote: createAsyncAction(
		"meetingNotesActions/fetchMeetingNote",
		"meetingNotesActions/fetchMeetingNoteSuccess",
		"meetingNotesActions/fetchMeetingNoteFail",
	)<{userId: number; eventViewId: string}, {userId: number; meetingNoteData: SensorDatum<MeetingNote>}, Error>(),
};

export type MeetingNotesActionType = ActionType<typeof meetingNotesActions>;
