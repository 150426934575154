import {createEventViewId} from "redux/tracking/TrackingHelper";
import {
	CustomTrackerEventView,
	DiaryEntrySensorEventView,
	EventViewData,
	EventViewType,
} from "redux/tracking/TrackingTypes";
import {TrackerDatum} from "../../customTracker/customTrackerTypes";

export const transformTrackerDatumToTrackerEventView = (
	trackerDatum: TrackerDatum,
	contactId: number,
): CustomTrackerEventView => {
	return {
		/**  event Id */
		id: createEventViewId(EventViewType.CUSTOM_TRACKER, trackerDatum.sensorId, trackerDatum.date),
		/** Owner user id */
		ownerId: contactId,
		/** tracker name */
		title: trackerDatum.trackerName,
		/** StartTime of EventViewData */
		startTime: trackerDatum.date,
		/** EndTime of EventViewData */
		endTime: trackerDatum.date,
		/** type to identify `source` */
		type: EventViewType.CUSTOM_TRACKER,
		/** Raw data of `EventViewData` */
		source: {
			id: trackerDatum.sensorId,
			trackers: trackerDatum,
		},
		/** Event created at */
		createdAt: trackerDatum.date,
		/** Event updated at */
		updatedAt: trackerDatum.date,
	};
};

/* Merge event view data with custom tracker data
 * this func will filter custom tracker that already mentioned by Diary
 */
export const mergeEventViewDataWithCustomTracker = (
	trackerEventView: CustomTrackerEventView[],
	eventViewData: EventViewData[],
): EventViewData[] => {
	// filter EventViewData type === DIARY_ENTRY_SENSOR, and has tracker
	// and map each tracker id
	const eventTrackerIdOnDiary: string[] = eventViewData
		.filter(
			(eventData: EventViewData) =>
				eventData.type === EventViewType.DIARY_ENTRY_SENSOR && eventData.source.value.trackers,
		)
		.map((eventData: DiaryEntrySensorEventView) => eventData.source.value.trackers[0].sensorData.id);

	// filter trackerEventData that doesn't belong to diary
	// we need to filter trackerEventData to prevent redundancy data
	const filteredTrackerEventData = trackerEventView.filter((trackerEventData: CustomTrackerEventView) => {
		return !eventTrackerIdOnDiary.includes(trackerEventData.source.id);
	});

	const mergedEventData = [...filteredTrackerEventData, ...eventViewData];

	/*
	 * https://github.com/senseobservationsystems/web-getgoalie/issues/5563
	 * sort tracker ascending by startTime
	 */
	const sortedEventData = mergedEventData.sort((a, b) => {
		if (a.startTime < b.startTime) {
			return -1;
		}
		return 1;
	});

	return sortedEventData;
};
