import moment from "moment";
import {
	ChartType,
	SingleLineChartConfig,
	ChartConfigOptions,
	ChartConfigAggregationStrategy,
} from "../../customTrackerChartTypes";
import {RangeOption} from "../../customTrackerTypes";
import {
	quantityTooltipTextFn,
	timeTextFn,
	totalCountTextFn,
	weightTitleFn,
	weightTooltipTextFn,
	weightAverageTooltipTextFn,
} from "../trackerLocalizations";
import {numberToString, getYDomainWithBuffer} from "../customTrackerHelpers";

/**
 * Returns chart configuration for counting total quantity of a tracker.
 */
export function createQuantityChartConfig(
	measureName: string,
	options: ChartConfigOptions = {aggregateStrategy: ChartConfigAggregationStrategy.SUM, skipEmptyDomain: false},
): SingleLineChartConfig {
	return {
		type: ChartType.SingleLineChart,
		titleFn: totalCountTextFn,
		trackerDatumToChartDatumFn: (datum) => {
			return {
				source: datum,
				x: datum.date.valueOf(),
				y: datum.values[measureName].quantity,
			};
		},
		customAggregatorFn: (date, selectedData) => {
			const yData = selectedData.filter((datum) => Boolean(datum.y));

			// Return null so that the chart won't draw the data point
			if (options.skipEmptyDomain && yData.length === 0) {
				return null;
			}

			const total = yData.reduce((result, datum) => {
				return result + datum.y;
			}, 0);
			return {
				x: date.valueOf(),
				y: options.aggregateStrategy === ChartConfigAggregationStrategy.AVERAGE ? total / yData.length : total,
				source: selectedData,
			};
		},
		tooltipContent: (datum, rangeOption) => {
			let results: string[] = [];
			if (rangeOption === RangeOption.RAW_DATA) {
				results.push(timeTextFn(moment(datum.x).format("HH:mm")));
				results.push(quantityTooltipTextFn(numberToString(datum.y)));
			} else {
				results.push(quantityTooltipTextFn(numberToString(datum.y)));
			}

			return results.join("\n");
		},
	};
}

export function createCocaineCannabisChartConfig(measureName: string): SingleLineChartConfig {
	return {
		...createQuantityChartConfig(measureName, {
			aggregateStrategy: ChartConfigAggregationStrategy.SUM,
			skipEmptyDomain: false,
		}),
		yDomainFn: (data) => {
			if (data.length === 0) {
				return [1, 0];
			}
			return [Math.max(...data.map((d) => d.y), 1), 0];
		},
	};
}

export function createWeightChartConfig(measureName: string): SingleLineChartConfig {
	return {
		...createQuantityChartConfig(measureName, {
			aggregateStrategy: ChartConfigAggregationStrategy.AVERAGE,
			skipEmptyDomain: true,
		}),
		titleFn: () => weightTitleFn(),
		yDomainFn: (data) => {
			if (data.length === 0) {
				return [100, 0];
			}
			return getYDomainWithBuffer(
				data.map((d) => d.y),
				10,
			);
		},
		tooltipContent: (datum, rangeOption) => {
			let results: string[] = [];
			const value = numberToString(datum.y);

			if (rangeOption === RangeOption.RAW_DATA) {
				results.push(timeTextFn(moment(datum.x).format("HH:mm")));
				results.push(weightTooltipTextFn(value));
			} else {
				results.push(weightAverageTooltipTextFn(value));
			}

			return results.join("\n");
		},
	};
}
