import {all, call, put, select} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {
	DiaryEntrySensorEventView,
	EventViewData,
	MeetingNoteSensorEventView,
	ThoughtRecordsSensorEventView,
	PsychoEducationEventView,
	EventViewType,
	BehaviorExprEventView,
	CheckInFeelingSensorEventView,
} from "../../ts/redux/tracking/TrackingTypes";
import {getTrackingSDK} from "../helpers/clientActivitySDK";
import {transformDailyPlannerIntoEventViewData} from "../helpers/dailyPlannerActivityHelpers";
import {
	getCheckInFeelingSensors,
	getDailyPlannerItems,
	getDiaryEntrySensors,
	getMeetingNoteSensors,
	getPsychoEducationItems,
} from "../helpers/clientActivitySDKHelpers";
import {
	getFilteredDiaryEntries,
	getFilteredThoughtRecords,
	transformSensorDatumIntoClientActivity,
} from "../helpers/sensorDataActivityHelpers";
import {clientActivityActions} from "../redux/clientActivitiyActions";
import {extractEventViewId} from "redux/tracking/TrackingHelper";
import {transformPsychoEducationIntoEventViewData} from "../helpers/psychoEducationActivityHelpers";
import {getSelectedContactHashId} from "../../contacts/redux/contactSelectors";
import {
	getBehaviorExprs,
	transformBehaviorExprIntoEventViewData,
} from "../../trackingData/behaviorExpr/helpers/behaviorExprHelpers";
import behaviorExprSDK from "../../trackingData/behaviorExpr/helpers/behaviorExprSDK";

const log = createLogger("clientActivity");

/**
 * Loads client's activities data:
 * - Recurring planned events
 * - Planned event sensors
 * - Therapy sessions
 * - SMQ questionnaires
 * - OMQ questionnaires
 * - Meeting notes
 * - Diary entries
 * - Thought records
 * - Psycho education
 * - Behavior Experiments
 * - Check-in Feeling
 *
 * @param action
 */
export function* loadClientActivities(action: ActionType<typeof clientActivityActions.loadActivities.request>) {
	const {userId, start, end} = action.payload;
	const token = yield call(getSessionId);
	const contactHashId = yield select(getSelectedContactHashId);

	try {
		// Fetch all data
		const {
			dailyPlannerItems,
			meetingNotes,
			diaryEntries,
			thoughtRecords,
			psychoEducations,
			behaviorExprItems,
			checkInFeeling,
		} = yield all({
			dailyPlannerItems: apiCallSaga(getDailyPlannerItems, token, userId, start, end),
			meetingNotes: apiCallSaga(getMeetingNoteSensors, token, userId, start, end),
			diaryEntries: apiCallSaga(getDiaryEntrySensors, token, userId, start, end),
			thoughtRecords: apiCallSaga(getTrackingSDK().getThoughtRecords, {
				userId,
				startTime: start,
				endTime: end,
			}),
			psychoEducations: apiCallSaga(getPsychoEducationItems, token, start, end, contactHashId),
			behaviorExprItems: apiCallSaga(getBehaviorExprs, token, start, end, contactHashId),
			checkInFeeling: apiCallSaga(getCheckInFeelingSensors, token, userId, start, end),
		});

		//
		// Transform raw data from backend into `EventViewData` (a universal form of data that we use in the component)
		//
		const dpActivities: EventViewData[] = dailyPlannerItems
			.map(transformDailyPlannerIntoEventViewData)
			.filter(Boolean);
		const meetingNoteActivities: MeetingNoteSensorEventView[] = meetingNotes
			.map(transformSensorDatumIntoClientActivity)
			.filter(Boolean);
		const diaryEntryActivities: DiaryEntrySensorEventView[] = diaryEntries
			.map(transformSensorDatumIntoClientActivity)
			.filter(Boolean);
		const thoughtRecordActivities: ThoughtRecordsSensorEventView[] = thoughtRecords
			.map(transformSensorDatumIntoClientActivity)
			.filter(Boolean);
		const psychoEducationActivities: PsychoEducationEventView[] = psychoEducations
			.map(transformPsychoEducationIntoEventViewData)
			.filter(Boolean);
		const behaviorExprs: BehaviorExprEventView[] = behaviorExprItems
			.map((behaviorExprItem) => {
				// passing `userId` due to doesn't get the user id from api response
				return transformBehaviorExprIntoEventViewData(behaviorExprItem, userId);
			})
			.filter(Boolean);
		const checkInFeelingActivities: CheckInFeelingSensorEventView[] = checkInFeeling
			.map(transformSensorDatumIntoClientActivity)
			.filter(Boolean);

		// Filter out Diary entries which are part of dailyplanner reflections
		const filteredDiaryEntries = getFilteredDiaryEntries(diaryEntryActivities, dpActivities);

		// Filter out Thought records which are part of diary entries
		const filteredThoughtRecords = getFilteredThoughtRecords(thoughtRecordActivities, filteredDiaryEntries);

		// Combine all data into one
		const activities = [
			...dpActivities,
			...meetingNoteActivities,
			...filteredDiaryEntries,
			...filteredThoughtRecords,
			...psychoEducationActivities,
			...behaviorExprs,
			...checkInFeelingActivities,
		];

		yield put(
			clientActivityActions.loadActivities.success({
				userId,
				data: activities,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(clientActivityActions.loadActivities.failure({err, userId}));
	}
}

/**
 * Loads single client activity
 */
export function* loadClientActivity(action: ActionType<typeof clientActivityActions.loadActivity.request>) {
	const {userId, eventViewId} = action.payload;
	const token = yield call(getSessionId);
	const contactHashId = yield select(getSelectedContactHashId);
	const {occurrenceTime, eventViewType, id} = extractEventViewId(eventViewId);

	let result: EventViewData = null;

	try {
		if (eventViewType === EventViewType.DIARY_ENTRY_SENSOR) {
			const diaryEntries = yield apiCallSaga(getDiaryEntrySensors, token, userId, occurrenceTime, occurrenceTime);
			const diaryEntryActivities = diaryEntries.map(transformSensorDatumIntoClientActivity).filter(Boolean);
			result = diaryEntryActivities.find((data: EventViewData) => data.id === eventViewId);
		} else if (eventViewType === EventViewType.MEETING_NOTE_SENSOR) {
			const meetingNotes = yield apiCallSaga(
				getMeetingNoteSensors,
				token,
				userId,
				occurrenceTime,
				occurrenceTime,
			);
			const meetingNoteActivities = meetingNotes.map(transformSensorDatumIntoClientActivity).filter(Boolean);
			result = meetingNoteActivities.find((data: EventViewData) => data.id === eventViewId);
		} else if (eventViewType === EventViewType.THOUGHT_RECORDS_SENSOR) {
			const thoughtRecords = yield apiCallSaga(getTrackingSDK().getThoughtRecords, {
				userId,
				startTime: occurrenceTime,
				endTime: occurrenceTime,
			});
			const thoughtRecordActivities = thoughtRecords.map(transformSensorDatumIntoClientActivity).filter(Boolean);
			result = thoughtRecordActivities.find((data: EventViewData) => data.id === eventViewId);
		} else if (eventViewType === EventViewType.PSYCHO_EDUCATION) {
			const psychoEducations = yield apiCallSaga(
				getPsychoEducationItems,
				token,
				occurrenceTime,
				occurrenceTime,
				contactHashId,
			);
			const psychoEducationActivities = psychoEducations
				.map(transformPsychoEducationIntoEventViewData)
				.filter(Boolean);
			result = psychoEducationActivities.find((data: EventViewData) => data.id === eventViewId);
		} else if (eventViewType === EventViewType.BEHAVIOR_EXPERIMENT) {
			const behaviorExperimentItem = yield apiCallSaga(behaviorExprSDK.getBehaviorExperimentDetail, token, id);
			const behaviorExperimentActivity = transformBehaviorExprIntoEventViewData(behaviorExperimentItem, userId);
			result = behaviorExperimentActivity.id === eventViewId && behaviorExperimentActivity;
		} else if (eventViewType === EventViewType.CHECK_IN_FEELING) {
			const checkInFeeling = yield apiCallSaga(
				getCheckInFeelingSensors,
				token,
				userId,
				occurrenceTime,
				occurrenceTime,
			);
			const checkInFeelingActivities = checkInFeeling.map(transformSensorDatumIntoClientActivity).filter(Boolean);
			result = checkInFeelingActivities.find((data: EventViewData) => data.id === eventViewId);
		} else {
			const dailyPlanners = yield apiCallSaga(
				getDailyPlannerItems,
				token,
				userId,
				occurrenceTime,
				occurrenceTime,
			);
			const dailyPlannerActivities = dailyPlanners.map(transformDailyPlannerIntoEventViewData).filter(Boolean);
			result = dailyPlannerActivities.find((data: EventViewData) => data.id === eventViewId);
		}

		if (result) {
			yield put(clientActivityActions.loadActivity.success({userId, data: result}));
		}
	} catch (err) {
		yield put(clientActivityActions.loadActivity.failure({userId, err}));
		log.captureException(err);
	}
}
