import {SettingTypes} from "@sense-os/goalie-js";
import {fork, put, takeEvery, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {getSessionId, getAuthUserId} from "../auth/helpers/authStorage";
import {whenLoggedIn} from "../auth/sagas/helper";
import {SentryTags} from "../errorHandler/createSentryReport";
import {apiCallSaga} from "../helpers/apiCall/apiCall";
import createLogger from "../logger/createLogger";
import profileSDK from "../userProfile/helpers/profileSDK";

import {userSettingsAction} from "./redux/userSettingsActions";
import {getSettings, getTrackerAddedSettings} from "./redux/userSettingsSelectors";
import {reconcileUserSettings} from "./userSettingsHelper";
import {UserSettings} from "./userSettingsTypes";

const log = createLogger("ContactSaga", SentryTags.Contact);

function* loadSettings() {
	const token: string = getSessionId();
	const userId: number = getAuthUserId();

	try {
		const settings: UserSettings = yield apiCallSaga(
			profileSDK.getUserSettings,
			token,
			userId,
			SettingTypes.PORTAL,
		);
		yield put(userSettingsAction.loadSettings.success({settings}));
	} catch (e) {
		yield put(userSettingsAction.loadSettings.failure());
		log.captureException(e, {message: "Failed to load user settings"});
	}
}

function* saveSettings(action: ActionType<typeof userSettingsAction.saveSettings.request>) {
	const {settings} = action.payload;
	const token: string = getSessionId();
	const userId: number = getAuthUserId();

	try {
		const settingsInBE: UserSettings = yield apiCallSaga(
			profileSDK.getUserSettings,
			token,
			userId,
			SettingTypes.PORTAL,
		);

		const localSettings: UserSettings = yield select(getSettings);
		const curSettings = reconcileUserSettings(settingsInBE, localSettings);
		const nextSettings: Partial<UserSettings> = {...curSettings, ...settings};
		yield apiCallSaga(profileSDK.updateUserSettings, token, userId, SettingTypes.PORTAL, nextSettings);
		yield put(userSettingsAction.saveSettings.success({settings: nextSettings}));
	} catch (e) {
		log.captureException(e, {message: "Failed to save user settings"});
	}
}

function* saveCustomTrackerSettings(action: ActionType<typeof userSettingsAction.saveCustomTrackerSettings.request>) {
	const {isTrackerAddedDialogChecked} = action.payload;
	const isAddedDialogCheckedState = yield select(getTrackerAddedSettings);
	try {
		yield put(
			userSettingsAction.saveSettings.request({
				settings: {
					customTracker: {
						isTrackerAddedDialogChecked:
							isTrackerAddedDialogChecked === undefined
								? isAddedDialogCheckedState
								: isTrackerAddedDialogChecked,
					},
				},
			}),
		);
	} catch (e) {
		log.captureException(e, {message: "Failed to save custom tracker settings"});
	}
}

function* saveTrackerOrderMapSettings(action: ActionType<typeof userSettingsAction.saveTrackerOrderSettings.request>) {
	const {trackerOrder, userId} = action.payload;
	const prevSettings: UserSettings = yield select(getSettings);

	try {
		yield put(
			userSettingsAction.saveSettings.request({
				settings: {
					trackerOrderMap: {
						...prevSettings.trackerOrderMap,
						[userId]: trackerOrder,
					},
				},
			}),
		);
	} catch (e) {
		log.captureException(e, {message: "Failed to save tracker order settings"});
	}
}

function* userSettingsSaga() {
	yield takeEvery(getType(userSettingsAction.loadSettings.request), loadSettings);
	yield takeEvery(getType(userSettingsAction.saveSettings.request), saveSettings);
	yield takeEvery(getType(userSettingsAction.saveCustomTrackerSettings.request), saveCustomTrackerSettings);
	yield takeEvery(getType(userSettingsAction.saveTrackerOrderSettings.request), saveTrackerOrderMapSettings);

	// Fetch user settings once when logged in
	yield put(userSettingsAction.loadSettings.request());
}

export default function* () {
	yield fork(whenLoggedIn(userSettingsSaga));
}
