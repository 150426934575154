import {fork} from "redux-saga/effects";
import loginSaga from "./loginSaga";
import registrationSaga from "./registrationSaga";
import passwordResetSaga from "./passwordResetSaga";
import logoutSaga from "./logoutSaga";

export default function* () {
	yield fork(loginSaga);
	yield fork(registrationSaga);
	yield fork(passwordResetSaga);
	yield fork(logoutSaga);
}
