import Button from "@material-ui/core/Button";
import {NDColors} from "../../material-ui/NDColors";
import {useDispatch} from "react-redux";
import {therapySessionActions} from "../redux/therapySessionActions";
import {FormattedMessage} from "react-intl";
import strTranslation from "../../assets/lang/strings";

interface Props {
	undoId: string;
	userId?: number;
}

const UndoButton = ({undoId, userId}: Props) => {
	const dispatch = useDispatch();

	return (
		<>
			<Button
				variant="text"
				style={{backgroundColor: NDColors.DEFAULT}}
				onClick={() => {
					dispatch(therapySessionActions.undoEventAction(undoId, userId));
				}}
			>
				<FormattedMessage id={strTranslation.TIME_TRACKING.deletion_toast.button.undo} />
			</Button>
		</>
	);
};

export const getUndoButton = (undoId: string, userId?: number) => {
	return <UndoButton undoId={undoId} userId={userId} />;
};
