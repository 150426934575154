import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

import {OMQEventView, SMQEventView, TherapySessionEventView} from "redux/tracking/TrackingTypes";

import {TherapySessionFormValues} from "../types";

export const therapySessionActions = {
	/**
	 * Form dialog open / close actions
	 */
	openForm: createAction("therapySessionActions/openForm", (userId?: number, eventViewId?: string) => ({
		userId,
		eventViewId,
	}))(),
	closeForm: createAction("therapySessionActions/closeForm")(),

	/**
	 * Clear fetched therapy session
	 */
	clearFetchedSession: createAction("therapySessionActions/clearFetchedSession")(),

	/**
	 * Form values draft save action
	 */
	saveFormValues: createAction(
		"therapySessionActions/saveFormValues",
		(userId: number, formValues?: TherapySessionFormValues) => ({userId, formValues}),
	)(),

	/**
	 * Submit therapy session form values action
	 */
	saveTherapySession: createAsyncAction(
		"therapySessionActions/saveTherapySession",
		"therapySessionActions/saveTherapySessionSuccess",
		"therapySessionActions/saveTherapySessionFail",
	)<{userId?: number; formValues: TherapySessionFormValues}, {userId: number}, Error>(),

	/**
	 * Remove created therapy session
	 */
	deleteTherapySession: createAsyncAction(
		"therapySessionActions/deleteTherapySession",
		"therapySessionActions/deleteTherapySessionSuccess",
		"therapySessionActions/deleteTherapySessionFail",
	)<{eventViewId: string}, void, Error>(),

	/**
	 * Fetch therapy session
	 */
	fetchTherapySession: createAsyncAction(
		"therapySessionActions/fetchTherapySession",
		"therapySessionActions/fetchTherapySessionSuccess",
		"therapySessionActions/fetchTherapySessionFail",
	)<{userId: number; eventViewId: string}, {data: TherapySessionEventView}, Error>(),

	/**
	 * Fetch Omq session
	 */
	fetchOmqSession: createAsyncAction(
		"therapySessionActions/fetchOmqSession",
		"therapySessionActions/fetchOmqSessionSuccess",
		"therapySessionActions/fetchOmqSessionFail",
	)<{userId: number; eventViewId: string}, {data: OMQEventView}, Error>(),

	/**
	 * Fetch Omq session
	 */
	fetchSmqSession: createAsyncAction(
		"therapySessionActions/fetchSmqSession",
		"therapySessionActions/fetchSmqSessionSuccess",
		"therapySessionActions/fetchSmqSessionFail",
	)<{userId: number; eventViewId: string}, {data: SMQEventView}, Error>(),

	/**
	 * Undo event creation, update, and delete action
	 */
	undoEventAction: createAction("therapySessionActions/undoAction", (undoId: string, userId?: number) => ({
		undoId,
		userId,
	}))(),
};

export type TherapySessionActionType = ActionType<typeof therapySessionActions>;
