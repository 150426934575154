import {takeEvery, put} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {clientsFeedActions} from "../redux/ClientsFeedActions";

/**
 * Reload feed after change active label and hour range
 */
function* reloadFeeds() {
	yield put(clientsFeedActions.loadFeeds.request());
}

export default function* () {
	yield takeEvery(getType(clientsFeedActions.setActiveLabel), reloadFeeds);
	yield takeEvery(getType(clientsFeedActions.setHourRange), reloadFeeds);
}
