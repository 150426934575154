import {takeEvery, put, select, delay, fork, call} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {DISC} from "IoC/DISC";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {SESSION_EVENT_ID_PREFIX} from "../../calendar/calendarTypes";
import {getCalendarEventById} from "../../calendar/redux/calendarSelectors";
import {deleteSessionEventTask as deleteCalendarSessionTask} from "../../calendar/sagas/sessionEvent/deleteSessionEventSaga";

import {getUndoButton} from "../views/UndoActionButton";
import {therapySessionActions} from "../redux/therapySessionActions";
import {sessionScheduleMap} from "../types";

const log = createLogger("deleteTherapySessionSaga", SentryTags.TherapySession);
const UNDO_KEY_PREFIX = "SESSION_delete_";
const UNDO_ACTION_TIMEOUT_MS = 4000;

function* deleteSessionTask(action: ActionType<typeof therapySessionActions.deleteTherapySession.request>) {
	const {eventViewId} = action.payload;
	let sensorId = eventViewId;
	let calendarEventId = eventViewId;

	if (sensorId.includes(SESSION_EVENT_ID_PREFIX)) {
		// Remove `session#` prefix if `sensorId` have it.
		sensorId = eventViewId.replace(SESSION_EVENT_ID_PREFIX, "");
	}

	if (!calendarEventId.includes(SESSION_EVENT_ID_PREFIX)) {
		// Add `session#` as prefix if `calendarEventId` does not have it.
		calendarEventId = [SESSION_EVENT_ID_PREFIX, eventViewId].join("");
	}

	const calendarEvent = yield select(getCalendarEventById, calendarEventId);

	if (calendarEvent) {
		// There is a need if deleted session have attached calendar event
		// user should be able to undo the action.
		const undoId = UNDO_KEY_PREFIX + eventViewId;

		yield put(
			toastActions.addToast({
				type: "warning",
				message: loc.formatMessage(strTranslation.CALENDAR.session.deleted.toast.text),
				persist: false,
				key: undoId,
				action: getUndoButton(undoId),
			}),
		);

		const deleteSessionSagaTask = yield fork(
			deleteSessionEventTask,
			calendarEvent.calendarId,
			calendarEventId,
			sensorId,
		);
		sessionScheduleMap.set(undoId, deleteSessionSagaTask);
	} else {
		yield call(handleDeleteSession, sensorId);
	}
}

function* handleDeleteSession(sensorId: string) {
	const trackingService = DISC.getTrackingService();
	const trackingSDK = trackingService.sdk;
	try {
		yield apiCallSaga([trackingSDK, trackingSDK.deleteSensorData], sensorId);
		yield put(therapySessionActions.deleteTherapySession.success());
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.delete_event.success.toast, type: "success"}));
	} catch (err) {
		log.captureException(err);
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.delete_event.fail.toast, type: "error"}));
	}
}

function* deleteSessionEventTask(calendarId: string, eventId: string, sensorId: string) {
	yield delay(UNDO_ACTION_TIMEOUT_MS);
	yield call(deleteCalendarSessionTask, calendarId, eventId);
	yield call(handleDeleteSession, sensorId);
}

export default function* () {
	yield takeEvery(getType(therapySessionActions.deleteTherapySession.request), deleteSessionTask);
}
