import * as React from "react";
import {Dialog, DialogContent, DialogActions, Radio, RadioGroup, FormControlLabel} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import autobind from "autobind-decorator";

import Localization, {ILocalization} from "../../../../../../localization/Localization";
import {Button} from "../../../../../../material-ui/buttons";
import strTranslation from "../../../../../../assets/lang/strings";

export enum UpdateEventOption {
	THIS_EVENT_ONLY = "this_event_only",
	THIS_AND_FOLLOWING_EVENTS = "this_and_following_events",
}

interface Props {
	/** Title of the dialog */
	title: string;
	/** True if you want to show the dialog */
	open?: boolean;
	/** on close callback */
	onClose: () => void;
	/** on save callback with selected option value */
	onSave: (options: UpdateEventOption) => void;
	/** True if you want to show `UpdateEventOption.THIS_EVENT_ONLY` option */
	allowUpdateThisEventOnly?: boolean;
}

interface State {
	selectedOption: UpdateEventOption;
}

@autobind
class UpdateEventOptionDialog extends React.Component<Props, State> {
	private loc: ILocalization = Localization;

	public state: State = {
		selectedOption: UpdateEventOption.THIS_EVENT_ONLY,
	};

	public static defaultProps: Partial<Props> = {
		allowUpdateThisEventOnly: true,
	};

	private CHECKBOX_OPTION_TEXTS: Record<string, string> = {
		[UpdateEventOption.THIS_EVENT_ONLY]: this.loc.formatMessage(
			strTranslation.GRAPHS.edit_event.event_option.this_event_only,
		),
		[UpdateEventOption.THIS_AND_FOLLOWING_EVENTS]: this.loc.formatMessage(
			strTranslation.GRAPHS.edit_event.event_option.this_and_following_events,
		),
	};

	public componentWillReceiveProps(nextProps: Props, nextState: State): void {
		if (!nextProps.allowUpdateThisEventOnly && this.state.selectedOption !== nextState.selectedOption) {
			this.setState({selectedOption: UpdateEventOption.THIS_AND_FOLLOWING_EVENTS});
		}
	}

	public render(): JSX.Element {
		const {open, onClose, title, onSave, allowUpdateThisEventOnly} = this.props;
		const {selectedOption} = this.state;

		const renderRadio = (eventOptionKey: string) => {
			const value: UpdateEventOption = UpdateEventOption[eventOptionKey];
			const label: string = this.CHECKBOX_OPTION_TEXTS[value];
			const disabled = value === UpdateEventOption.THIS_EVENT_ONLY && !allowUpdateThisEventOnly;

			return (
				<FormControlLabel
					key={eventOptionKey}
					value={value}
					control={<Radio color="default" />}
					label={label}
					disabled={disabled}
					checked={selectedOption === value}
					onChange={this.setSelectedOption}
				/>
			);
		};
		return (
			<Dialog
				open={open}
				onClose={onClose}
				className="UpdateEventOptionDialog"
				maxWidth="sm"
				fullWidth
				scroll="body"
			>
				<DialogContent>
					<h2 className="UpdateEventOptionDialog__header">{title}</h2>
					<div className="UpdateEventOptionDialog__options">
						<RadioGroup aria-label="update-event-option">
							{Object.keys(UpdateEventOption).map(renderRadio)}
						</RadioGroup>
					</div>
				</DialogContent>
				<DialogActions>
					<Button id="close_button" variant="text" color="primary" onClick={onClose}>
						<FormattedMessage id={strTranslation.COMMON.alert.cancel} />
					</Button>
					<Button id="reset_button" variant="text" color="secondary" onClick={() => onSave(selectedOption)}>
						<FormattedMessage id={strTranslation.COMMON.alert.confirm} />
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	private setSelectedOption(e: React.ChangeEvent<HTMLInputElement>): void {
		this.setState({selectedOption: e.target.value as UpdateEventOption});
	}
}

export default UpdateEventOptionDialog;
