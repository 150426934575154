import {AppState} from "../AppState";
import {createSelector} from "reselect";
import {LoadingState} from "constants/redux";
import {ScheduleType} from "../tracking/TrackingTypes";

/** Get `loggerConfig` from redux state */
const getLoggerConfigState = (state: AppState) => state.loggerConfig;

/** Get `loadingCreateReminder` from `loggerConfig` */
const getLoadingCreateReminder = createSelector([getLoggerConfigState], (state) => state.loadingCreateReminder);

/** Get `loadingGetReminder` from `loggerConfig` */
const getLoadingFetchReminder = createSelector([getLoggerConfigState], (state) => state.loadingFetchReminder);

/** Returns true if `loadingGetReminder` === LoadingState.Loading */
export const getIsLoadingFetchReminder = createSelector(
	[getLoadingFetchReminder],
	(loadingState) => loadingState === LoadingState.LOADING,
);

/** Get `loadingRemoveReminder` from `loggerConfig` state */
export const getLoadingRemoveReminder = createSelector([getLoggerConfigState], (state) => state.loadingRemoveReminder);
export const isReminderBeingRemoved = (state: AppState, id: string) =>
	getLoadingRemoveReminder(state)[id] === LoadingState.LOADING;

/** Returns true if `loadingCreate` === LoadingState.Loading */
export const getIsCreatingReminder = createSelector(
	[getLoadingCreateReminder],
	(loadingState) => loadingState === LoadingState.LOADING,
);

/** Get `loggerConfigs` from `loggerConfig` state */
export const getLoggerConfigs = createSelector([getLoggerConfigState], (state) => state.loggerConfigs);

/** Get `scheduleType` from `loggerConfig` state */
export const getScheduleType = createSelector([getLoggerConfigState], (state) => state.scheduleType);

/**
 * Get `loggerConfigs` by `scheduleType`
 *
 * @param {ScheduleType} scheduleType
 */
export const getLoggerConfigsByScheduleType = (scheduleType: ScheduleType) =>
	createSelector([getLoggerConfigs], (loggerConfigs) => {
		return Object.keys(loggerConfigs)
			.map((k) => loggerConfigs[k])
			.filter((loggerConfig) => loggerConfig.scheduleType === scheduleType);
	});

export const isLoggerConfigModalOpen = (state: AppState) => state.loggerConfig.isOpenModal;
export const isLoggerConfigEventModalOpen = (state: AppState) => state.loggerConfig.isOpenEventModal;
