import moment from "moment";
import {MultiBarChartConfig, ChartType} from "../../customTrackerChartTypes";
import {
	researchEmotionSelectorLabelText,
	researchEmotionsHappyText,
	researchEmotionsEnergyText,
	researchEmotionsInsecureText,
	researchEmotionsSadText,
	researchEmotionsGuiltyText,
	researchEmotionsTensionText,
	researchEmotionsAngryText,
	researchEmotionsAfraidText,
	researchEmotionsCuriosityText,
	researchEmotionsAshamedText,
	timeTextFn,
	averagePrefixTextFn,
} from "../trackerLocalizations";
import {RangeOption} from "../../customTrackerTypes";
import {numberToString} from "../customTrackerHelpers";

export default function createResearchEmotionsChartConfig(): MultiBarChartConfig {
	return {
		type: ChartType.MultiBarChart,
		selectorLabel: researchEmotionSelectorLabelText(),
		selectorOptions: [
			{
				bgColor: "#2576b6",
				text: researchEmotionsHappyText(),
				key: researchEmotionsHappyText(),
				defaultSelected: true,
			},
			{
				bgColor: "#fa7f00",
				text: researchEmotionsEnergyText(),
				key: researchEmotionsEnergyText(),
			},
			{
				bgColor: "#bcbf00",
				text: researchEmotionsInsecureText(),
				key: researchEmotionsInsecureText(),
			},
			{
				bgColor: "#7f7f7f",
				text: researchEmotionsSadText(),
				key: researchEmotionsSadText(),
				defaultSelected: true,
			},
			{
				bgColor: "#e574c3",
				text: researchEmotionsGuiltyText(),
				key: researchEmotionsGuiltyText(),
			},
			{
				bgColor: "#b8a593",
				text: researchEmotionsTensionText(),
				key: researchEmotionsTensionText(),
			},
			{
				bgColor: "#d8241e",
				text: researchEmotionsAngryText(),
				key: researchEmotionsAngryText(),
				defaultSelected: true,
			},
			{
				bgColor: "#9464bf",
				text: researchEmotionsAfraidText(),
				key: researchEmotionsAfraidText(),
			},
			{
				bgColor: "#4DCDFF",
				text: researchEmotionsCuriosityText(),
				key: researchEmotionsCuriosityText(),
			},
			{
				bgColor: "#2AC566",
				text: researchEmotionsAshamedText(),
				key: researchEmotionsAshamedText(),
			},
		],
		trackerDatumToChartDatumFn: (datum) => {
			return {
				source: datum,
				x: datum.date.valueOf(),
				y: [
					{
						// Happy
						key: researchEmotionsHappyText(),
						color: "#2576b6",
						value: datum.values.measureHappy.intensity,
					},
					{
						// Energized
						key: researchEmotionsEnergyText(),
						color: "#fa7f00",
						value: datum.values.measureEnergy.intensity,
					},
					{
						// Uncertain
						key: researchEmotionsInsecureText(),
						color: "#bcbf00",
						value: datum.values.measureInsecure.intensity,
					},
					{
						// Sad
						key: researchEmotionsSadText(),
						color: "#7f7f7f",
						value: datum.values.measureSad.intensity,
					},
					{
						// Guilty
						key: researchEmotionsGuiltyText(),
						color: "#e574c3",
						value: datum.values.measureGuilty.intensity,
					},
					{
						// Tense
						key: researchEmotionsTensionText(),
						color: "#b8a593",
						value: datum.values.measureTension.intensity,
					},
					{
						// Angry
						key: researchEmotionsAngryText(),
						color: "#d8241e",
						value: datum.values.measureAngry.intensity,
					},
					{
						// Afraid
						key: researchEmotionsAfraidText(),
						color: "#9464bf",
						value: datum.values.measureAfraid.intensity,
					},
					{
						// Curiosity
						key: researchEmotionsCuriosityText(),
						color: "#4DCDFF",
						value: datum.values.measureCuriosity.intensity,
					},
					{
						// Ashamed
						key: researchEmotionsAshamedText(),
						color: "#2AC566",
						value: datum.values.measureAshamed.intensity,
					},
				],
			};
		},
		tooltipContent: (datum, rangeOption) => {
			if (rangeOption === RangeOption.RAW_DATA) {
				return [
					timeTextFn(moment(datum.x).format("HH:mm")),
					...datum.y.map((d) => `${d.key}: ${numberToString(d.value)}`),
				].join("\n");
			}
			return datum.y.map((d) => averagePrefixTextFn(`${d.key}: ${numberToString(d.value)}`)).join("\n");
		},
		customAggregatorFn: (date, selectedData) => {
			const average = selectedData
				.reduce((acc, datum) => {
					datum.y.forEach((d, i) => {
						if (!acc[i]) {
							acc[i] = d;
						} else {
							acc[i] = {
								...acc[i],
								value: acc[i].value + d.value,
							};
						}
					});
					return acc;
				}, [])
				.map((aggregatedDatum) => {
					return {
						...aggregatedDatum,
						value: aggregatedDatum.value / selectedData.length,
					};
				});
			return {
				x: date.valueOf(),
				y: average,
				source: selectedData,
			};
		},
	};
}
