import {put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import createLogger from "../../logger/createLogger";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {EventViewData, SensorDatum, ThoughtRecordsSensorEventView} from "redux/tracking/TrackingTypes";
import {extractEventViewId} from "redux/tracking/TrackingHelper";
import {thoughtRecordActions} from "../redux/thoughtRecordActions";
import {transformSensorDatumIntoClientActivity} from "../../clientActivity/helpers/sensorDataActivityHelpers";
import {getTrackingSDK} from "../../clientActivity/helpers/clientActivitySDK";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {ThoughtRecord} from "@sense-os/goalie-js";

const log = createLogger("fetchThoughtRecord", SentryTags.ThoughtRecord);

export function* fetchThoughtRecord(action: ActionType<typeof thoughtRecordActions.fetchThoughtRecord.request>) {
	const {userId, eventViewId} = action.payload;
	const {occurrenceTime} = extractEventViewId(eventViewId);
	try {
		const thoughtRecords: SensorDatum<ThoughtRecord>[] = yield apiCallSaga(getTrackingSDK().getThoughtRecords, {
			userId,
			startTime: occurrenceTime,
			endTime: occurrenceTime,
		});
		const thoughtRecordActivities: EventViewData[] = thoughtRecords
			.map(transformSensorDatumIntoClientActivity)
			.filter(Boolean);

		const result = thoughtRecordActivities.find((data: EventViewData) => data.id === eventViewId);
		yield put(
			thoughtRecordActions.fetchThoughtRecord.success({
				thoughtRecordData: result as ThoughtRecordsSensorEventView,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(thoughtRecordActions.fetchThoughtRecord.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(thoughtRecordActions.fetchThoughtRecord.request), fetchThoughtRecord);
}
