import {CustomTrackerState} from "../customTrackerTypes";
import {combineReducers} from "redux";
import setupTrackerReducer from "./setupTrackerReducer";
import userTrackerMapReducer from "./userTrackerMapReducer";
import manageTrackersReducer from "./manageTrackersReducer";
import customTrackerDialogReducer from "./customTrackerDialogReducer";

export default combineReducers<CustomTrackerState>({
	setupTracker: setupTrackerReducer,
	userTrackerMap: userTrackerMapReducer,
	manageTrackers: manageTrackersReducer,
	trackerDialog: customTrackerDialogReducer,
});
