import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {MeetingNotesState} from "../meetingNotesTypes";
import {MeetingNotesActionType, meetingNotesActions} from "./meetingNotesActions";
import produce from "immer";

const initialState: MeetingNotesState = {
	userId: null,
	eventId: null,
	isFormOpened: false,
	isConfirmationDialogOpened: false,
	savingState: LoadingState.EMPTY,
	savedFormValues: {},
	fetchMeetingNoteDataState: LoadingState.EMPTY,
	meetingNoteData: null,
};

export const meetingNotesReducer = produce(
	(state: MeetingNotesState = initialState, action: MeetingNotesActionType) => {
		switch (action.type) {
			case getType(meetingNotesActions.openForm):
				state.userId = action.payload.userId;
				state.eventId = action.payload.eventViewId || null;
				state.isFormOpened = true;
				return;
			case getType(meetingNotesActions.closeForm):
				state.isFormOpened = false;
				state.savingState = LoadingState.EMPTY;
				return;
			case getType(meetingNotesActions.openConfirmationDialog):
				state.userId = action.payload.userId;
				state.isConfirmationDialogOpened = true;
				return;
			case getType(meetingNotesActions.closeConfirmationDialog):
				state.isConfirmationDialogOpened = false;
				return;
			case getType(meetingNotesActions.saveFormValues): {
				state.savedFormValues = {
					...state.savedFormValues,
					[action.payload.userId]: action.payload.formValues,
				};
				return;
			}
			case getType(meetingNotesActions.saveMeetingNotes.request): {
				state.savingState = LoadingState.LOADING;
				return;
			}
			case getType(meetingNotesActions.saveMeetingNotes.success): {
				const {userId} = action.payload;
				state.userId = null;
				state.savingState = LoadingState.LOADED;
				state.savedFormValues = {
					...state.savedFormValues,
					[userId]: null,
				};
				return;
			}
			case getType(meetingNotesActions.saveMeetingNotes.failure):
				state.savingState = LoadingState.ERROR;
				return;
			case getType(meetingNotesActions.fetchMeetingNote.request): {
				state.fetchMeetingNoteDataState = LoadingState.LOADING;
				state.meetingNoteData = null;
				return;
			}
			case getType(meetingNotesActions.fetchMeetingNote.success): {
				const {meetingNoteData} = action.payload;
				state.fetchMeetingNoteDataState = LoadingState.LOADED;
				state.meetingNoteData = meetingNoteData;
				return;
			}
			case getType(meetingNotesActions.fetchMeetingNote.failure): {
				state.fetchMeetingNoteDataState = LoadingState.ERROR;
				return;
			}
		}
	},
	initialState,
);
