import {call, put, takeEvery, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import customTrackerSDK from "../helpers/customTrackerSDK";
import {getSessionId} from "../../auth/helpers/authStorage";
import {TrackerCategory as SDKTrackerCategory, UserTracker as SDKTracker} from "@sense-os/goalie-js";
import {
	transformSDKTrackerCategory,
	transformSDKTracker,
	getCustomTrackerVersion,
} from "../helpers/customTrackerHelpers";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {getSelectedContactId} from "../../contacts/redux/contactSelectors";

const log = createLogger("setupTrackerSaga", SentryTags.Tracking);

function* handleLoadTrackerCategories() {
	try {
		const token = yield call(getSessionId);
		const trackerVersion = yield call(getCustomTrackerVersion);
		const response: SDKTrackerCategory[] = yield call(customTrackerSDK.getTrackerCategories, token, trackerVersion);
		const trackerCategories = response.map(transformSDKTrackerCategory);
		yield put(customTrackerActions.loadTrackerCategories.success(trackerCategories));
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.loadTrackerCategories.failure(err));
	}
}

function* handleAddTracker(action: ActionType<typeof customTrackerActions.addTracker.request>) {
	try {
		const token = yield call(getSessionId);
		const userId = yield select(getSelectedContactId);
		const trackers: SDKTracker[] = yield call(customTrackerSDK.postUserTrackerStatus, token, userId, [
			{isEnabled: true, trackerId: action.payload.trackerId},
		]);
		const newTracker = transformSDKTracker(trackers[0].tracker);
		yield put(customTrackerActions.addTracker.success({userId, tracker: newTracker}));
		yield put(customTrackerActions.closeSetupTrackerDialog());
		yield put(customTrackerActions.showTrackerAddedDialog(newTracker));

		// Refetch all enabled trackers for this user
		yield put(customTrackerActions.loadUserTrackers.request({userId}));
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.addTracker.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.loadTrackerCategories.request), handleLoadTrackerCategories);
	yield takeEvery(getType(customTrackerActions.addTracker.request), handleAddTracker);
}
