import {ChipSelectorItem} from "./views/ChipSelector";
import {RangeOption, TrackerDatum} from "./customTrackerTypes";

interface ChartDatumPayload {
	/**
	 * Chart datum event url (e.g if a smoking tracker is attached to a diary entry, then `eventUrl` = diary entry url)
	 */
	eventUrl?: string;
}

/**
 * Holds SingeLineChart datapoint information
 */
export interface SingleLineChartDatum {
	payload?: ChartDatumPayload;
	source: any;
	x: number;
	y: number;
}

/**
 * Holds MultiLineChart datapoint information
 */
export interface MultiLineChartDatum {
	payload?: ChartDatumPayload;
	source: any;
	x: number;
	y1: number;
	y2: number;
}

/**
 * MultibarChart `y` value
 */
export interface MultiBarChartYDatum {
	/**
	 * Unique key identifier for each bar
	 */
	key: string;
	value: number;
	/**
	 * Color of the bar
	 */
	color: string;
}
/**
 * Holds MultibarChart datapoint information
 */
export interface MultiBarChartDatum {
	payload?: ChartDatumPayload;
	source: any;
	x: number;
	/**
	 * Each item in the array represents a bar in the chart
	 */
	y: MultiBarChartYDatum[];
}

export type CustomTrackerChartDatum = SingleLineChartDatum | MultiLineChartDatum | MultiBarChartDatum;

export enum ChartType {
	SingleLineChart = "SingleLineChart",
	MultiLineChart = "MultiLineChart",
	MultiBarChart = "MultiBarChart",
	BooleanChart = "BooleanChart",
}

interface ChartConfigType<Datum extends CustomTrackerChartDatum> {
	type: ChartType;
	/**
	 * Given a sensor datum (single sensor data), this function will convert sensor datum into designated data point
	 * This function will be executed later in `useTrackerChartData` hook
	 *
	 * @see useTrackerChartData
	 */
	trackerDatumToChartDatumFn: (sensorDatum: TrackerDatum) => Datum;
	/**
	 * Data point aggregator.
	 *
	 * @param {Date} date x-domain of the chart
	 * @param {Datum[]} dataWithinRange list of data that are selected within x-domain and the next x-domain
	 *
	 * @see useTrackerChartData
	 */
	customAggregatorFn: (date: Date, dataWithinRange: Datum[]) => Datum | null;
	/**
	 * Optional function to generate a stateless component for chart tooltip.
	 * This component will be shown when the user hover a datapoint
	 */
	tooltipContent?: (datum: Datum, rangeOption: RangeOption) => string;
}
export interface SingleLineChartConfig extends ChartConfigType<SingleLineChartDatum> {
	type: ChartType.SingleLineChart;
	/**
	 * callback to decide the title of the chart depends on rangeOption value
	 */
	titleFn: (rangeOption: RangeOption) => string;
	titleColor?: string;
	lineColor?: string;
	/**
	 * Custom function to provide y axis's domain, given all data in the x axis
	 */
	yDomainFn?: (data: SingleLineChartDatum[]) => number[];
}
export interface MultiLineChartConfig extends ChartConfigType<MultiLineChartDatum> {
	type: ChartType.MultiLineChart;
	leftTitleColor?: string;
	/**
	 * Callback to decide the label of the left checkbox on the left of the chart container
	 */
	leftTitleFn: (rangeOption: RangeOption) => string;
	rightTitleColor?: string;
	/**
	 * Callback to decide the label of the right checkbox on the right of the chart container
	 */
	rightTitleFn: (rangeOption: RangeOption) => string;
}
export interface MultiBarChartConfig extends ChartConfigType<MultiBarChartDatum> {
	type: ChartType.MultiBarChart;
	/**
	 * Label to be shown in the chip dropdown
	 */
	selectorLabel: string;
	/**
	 * Chip options
	 */
	selectorOptions: ChipSelectorItem[];
}
export interface BooleanChartConfig extends ChartConfigType<MultiLineChartDatum> {
	type: ChartType.BooleanChart;
	trueLabelFn: (data: MultiLineChartDatum[]) => string;
	trueColor?: string;
	falseLabelFn: (data: MultiLineChartDatum[]) => string;
	falseColor?: string;
}

export type TrackerChartConfig =
	| SingleLineChartConfig
	| MultiLineChartConfig
	| MultiBarChartConfig
	| BooleanChartConfig;

export enum ChartConfigAggregationStrategy {
	AVERAGE = "avg",
	SUM = "sum",
}

export interface ChartConfigOptions {
	/**
	 * True if domain with empty (null | undefined) datapoint wants to be skipped.
	 */
	skipEmptyDomain: boolean;
	/**
	 * 'sum' if datapoints within a range of a domain wants to be calculated by SUM
	 * 'avg' if datapoints within a range of a domain wants to be calculated by AVERAGE
	 */
	aggregateStrategy: ChartConfigAggregationStrategy;
}
