import {fork} from "redux-saga/effects";
import setupTrackerSaga from "./setupTrackerSaga";
import userTrackerSaga from "./userTrackerSaga";
import manageTrackersSaga from "./manageTrackersSaga";
import customTrackerDialogSaga from "./customTrackerDialogSaga";

export default function* () {
	yield fork(setupTrackerSaga);
	yield fork(userTrackerSaga);
	yield fork(manageTrackersSaga);
	yield fork(customTrackerDialogSaga);
}
